import { useEffect, useMemo, useState } from "react";

import { DBTable, DBRow } from "../../shared/vdb/VaultDB";

import { DataGrid, GridColDef, GridEventListener, GridToolbar } from '@mui/x-data-grid';
import { SchemaTable } from "../../shared/vdb/types";
import { useNavigate } from "react-router-dom";
import CustomGridToolbar from "../vdb/mui/CustomGridToolbar";

// DBEquipFermentationTank

import getdb from 'client/hopiqvdb';
const vdb = getdb('EditTest');

// TODO: Add 'default' fields to schema, don't show over X columns, setup sorting, etc etc.
// TODO: Handle Raw instead of DB version, prob need Browse version for Raw
export function generateDataGridColumnsFromSchemaDBRow(schema: SchemaTable): GridColDef[] {
  return Object.entries(schema.fields).map(([name, field]) => {
    return { field: name, headerName: field.$name, description: field.info, width: 150, valueGetter: (p) => { return p.row.get(name) } };
  })
}

export type BROWSE_PROPS<DBROWTYPE extends DBRow<any>> = {
  // DBROWTYPE - DBTable<any, any, DBROWTYPE, any, any> - this becomes unknown when used as a React <Tag
  table: DBTable<any, any, any, any, any>,
  defaultNav?: boolean,
  onRowClick?: (row: DBROWTYPE) => void,
};

export default function Browse<DBROWTYPE extends DBRow<any>,>(props: BROWSE_PROPS<DBROWTYPE>) {
  const [data, setData] = useState<DBROWTYPE[]>([]);
  const [cols, setCols] = useState<GridColDef[]>([]);

  const nav = useNavigate();

  useEffect(() => {
    props.table.query({}).list().then((results) => {
      console.log({ results })
      setData(results);
    })

    let schema = props.table.schema;

    const cols: GridColDef[] = Object.entries(schema.fields).map(([name, field]) => {
      return { field: name, headerName: field.$name, description: field.info, width: 150, valueGetter: (p) => { return p.row.getDisplay(name) } };
    })
    setCols([
      { field: "$id", headerName: "id", minWidth: 50, },
      ...cols
    ]);
  }, [props.table])

  const onRowClickPassthru: GridEventListener<"rowClick"> = useMemo(() =>
    (p, event, details) => {
      if (props.onRowClick) props.onRowClick(p.row)
      if (props.defaultNav && p.row && p.row.$id) nav("./" + p.row.$id);
    },
    [props.onRowClick])

  const onNewClick = useMemo(() => () => {
    nav("./new");
  }, [nav]);

  return (
    <div style={{ display: 'flex', height: '100%', minHeight: '600px' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          getRowId={(row) => { console.log("ROW:", row.$id, row); return row.$id; }}
          rows={data} columns={cols}
          onRowClick={onRowClickPassthru}
          disableSelectionOnClick={true}
          components={{ Toolbar: CustomGridToolbar }}
          componentsProps={{ toolbar: { showQuickFilter: true, onNewClick: onNewClick, onNewClickText: "New " + props.table.name } }}

        />
        {/* <pre>{JSON.stringify(data, null, 2)}</pre>
        {data && data.map((i) => JSON.stringify(i, null, 2))} */}
      </div>
    </div>
  );
}
