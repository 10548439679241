import { Breadcrumbs } from "@mui/material";
import RRMUILink from "./RRLink";

export function Crumbs(props: { crumbs: [name: string, href: string][] }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.crumbs.map(([name, to]) => <RRMUILink underline="hover" color="inherit" href={to} key={name + to}>{name}</RRMUILink>)}
    </Breadcrumbs>
  );
}
