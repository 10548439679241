import { Autocomplete, FormControl, FormHelperText, Input, InputAdornment, InputLabel, OutlinedInput, SelectChangeEvent, TextField } from '@mui/material';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { DBRowEdit } from '../../../shared/vdb/DBRowEdit';
import flexdebounce from '../../../shared/vdb/flexdebounce';
import { GRaw, IFieldEmbed, SchemaField, STR_KEY_MAP } from '../../../shared/vdb/types';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { add, formatISO } from 'date-fns';
import DateFnsAdapter from "@date-io/date-fns";
import * as utils from 'shared/vdb/utils';
import RefID from 'shared/vdb/RefID';
import Reference from 'shared/vdb/Reference';
import { IDBRow } from 'shared/vdb/VaultDB';
import MUIEmbedArrayEdit, { MUIArrayEdit } from './MUIEmbedArrayEdit';
import { generateDataGridColumnsFromSchemaDBRow } from 'client/components/Browse';
import { ElectricScooterRounded } from '@mui/icons-material';

export const dateFns = new DateFnsAdapter();

export type EditFieldProps<FIELDS extends STR_KEY_MAP> = {
  field: keyof GRaw<FIELDS> & string,
  edit: DBRowEdit<FIELDS>,
  fullWidth?: boolean,
  readOnly?: boolean,
  arrayPos?: number,
  showName?: boolean,
  impl?: <FIELDS extends STR_KEY_MAP>(props: EditFieldProps<FIELDS>) => JSX.Element
};

function determineType(fieldSchema: SchemaField, data: any, name?: string): 'dropdown' | 'date' | 'id' | 'text' | 'embed' | undefined {

  if (fieldSchema) {
    if (fieldSchema.values) {
      // console.log(name, fieldSchema.$name, '=', 'DROPDOWN');
      return 'dropdown';
    } else if (fieldSchema.$datatype == "DATE") {
      return 'date';
    } else if (fieldSchema.$datatype == "ID") {
      return 'id';
    } else if (fieldSchema.$datatype == "EMBED_ARRAY") {
      return 'embed';
    }

    return 'text';
  }

  return undefined;
}

/** Purpose is to select the right output Field Edit Type */
export function EditFieldMUI<FIELDS extends STR_KEY_MAP>(props: EditFieldProps<FIELDS>) {

  const [editFieldType, setEditFieldType] = useState<ReturnType<typeof determineType>>(determineType(props.edit.$schema?.fields[props.field], props.edit.get(props.field), props.field));

  // useEffect(() => {
  //   // let fieldSchema = props.edit.dbrow?.$schema?.fields[props.field];
  //   // let name = fieldSchema?.$name || props.field as string;
  //   // props.edit.getData(props.field)
  //   setEditFieldType('text');
  // }, [props.field, props.edit, props.fullWidth]);

  // console.log(props.field, '=', editFieldType);
  if (props.impl !== undefined && props.impl !== null) {
    return props.impl(props);
  }

  // Some validation
  if (props.edit.$schema) {
    let schemaTable = props.edit.$schema;
    let schemaField = schemaTable.fields[props.field];

    if (schemaField.$embed) {
      let embedSuperField = schemaTable.fields[schemaField.$embed];

      if (!embedSuperField) throw new Error("vdb_schema error, field " + schemaTable.key + "/" + props.field + " is $embed type, but points to super field that doesn't exist: " + schemaField.$embed);
      if (embedSuperField.$datatype !== 'EMBED_ARRAY') throw new Error("vdb_schema error, field " + schemaTable.key + "/" + props.field + " has $embed, but points to non-embed super field '" + schemaField.$embed + "' of type " + embedSuperField.$datatype);

      if (!embedSuperField.fields || embedSuperField.fields.indexOf(props.field) < 0) throw new Error("vdb_schema error, field " + schemaTable.key + "/" + props.field + " has $embed, but super field '" + schemaField.$embed + "'.fields does not contain this field: " + props.field);

      console.log("FIELDVALIDATION: ", props.field, schemaField.$embed, (!embedSuperField.fields), (embedSuperField.fields.indexOf(props.field)), embedSuperField.fields);
    }

  }


  let EditField: null | ((props: EditFieldProps<any>) => JSX.Element) = null;

  if (editFieldType === 'text') {
    EditField = EditTextFieldMUI;
  } else if (editFieldType === 'dropdown') {
    EditField = EditSelectFieldMUI;
    // return (<EditSelectFieldMUI {...props} />);
  } else if (editFieldType === 'date') {
    EditField = EditDateFieldMUI;
    // return (<EditDateFieldMUI {...props} />);
  } else if (editFieldType === 'id') {
    EditField = EditIDSelectFieldMUI;
    // return (<EditIDSelectFieldMUI {...props} />);
  } else if (editFieldType === 'embed') {
    EditField = MUIEmbedArrayEdit;
    // return (<EditIDSelectFieldMUI {...props} />);
  } else {
    return <span data-field={props.field} style={{ color: 'red' }}>Error: Unable to resolve field or type</span>;
  }

  const schemaFieldArrayValue = props.edit.$schema?.fields[props.field].$array;
  const needArrayOutput =
    // If arrayPos is set, already handling the array elsewhere
    (props.arrayPos === undefined)
    && (
      // If the data is an array with more than one entry
      props.edit.getLength(props.field) > 1
      // Or if the schema specifies this is an array value
      || schemaFieldArrayValue !== undefined && schemaFieldArrayValue >= 1
    );

  if (needArrayOutput) {
    return (<MUIArrayEdit {...props} />);
  } else {
    return <EditField {...props} />
  }


}


export function EditTextFieldMUI_OLD<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) {
  const id = useId();
  const id2 = useId();

  const refThis = {};
  const [dataValue, setDataValue] = useState<any>(props.edit.get(props.field));
  const [calcValue, setCalcValue] = useState(props.edit.get('_' + props.field));

  let fieldSchema = props.edit.$schema?.fields[props.field];
  let name = fieldSchema?.$name || props.field as string;

  const [infoText, setInfoText] = useState<string | undefined>(fieldSchema?.info);
  const [warnText, setWarnText] = useState<string | undefined>();
  const [errorText, setErrorText] = useState<string | undefined>();

  const requiredText = (fieldSchema.required && !dataValue) ? (typeof fieldSchema.required === 'string') ? fieldSchema.required : "Field is required" : undefined;

  const helperText = errorText || warnText || requiredText || infoText;
  let errorOrWarn = (errorText || requiredText) ? 'error' : (warnText) ? 'warn' : null;

  let endAdornment = undefined;
  if (fieldSchema && fieldSchema.$datatype == "NUM" && fieldSchema.unit) {
    if (typeof fieldSchema.unit) // === 'string'
      endAdornment = (<InputAdornment position="end">{fieldSchema.unit}</InputAdornment>);
    // else if (Array.isArray(fieldSchema.unit) && fieldSchema.unit.length > 0)
    //   endAdornment = (<InputAdornment position="end">{fieldSchema.unit[0]}</InputAdornment>);
  }

  // If another source (caller != refThis) changes this value,
  useEffect(() => {
    console.log("EditField ", props.field, "attaching listeners");
    let passThruData = (value: any) => { console.log("EditField setDataValue passthru ", value); setDataValue(value) }
    const cb1 = props.edit.eventbus.onDataSet(props.field, passThruData);
    const cb2 = props.edit.eventbus.onCalcSet(props.field, setCalcValue);
    const cb3 = props.edit.eventbus.onValidation(props.field, async () => {
      setInfoText(props.edit.info[props.field] || fieldSchema?.info);
      setWarnText(props.edit.warnings[props.field]);
      setErrorText(props.edit.errors[props.field]);
    });
    return () => { console.log("EditField ", props.field, "removing listeners"); if (cb1) cb1(); if (cb2) cb2(); if (cb3) cb3(); }
  }, [props.edit, props.field]);

  const setEditValueDebounced = useMemo(() => {
    return flexdebounce((newValue: any) => {
      props.edit.set(props.field, newValue);
      props.edit.eventbus.data(props.field as string, newValue, refThis);
      props.edit.eventbus.unlock(props.field);
    }, 1000)
  }, [props.edit, props.field]);

  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useMemo(() => (e) => {
    const value = e.target.value;
    setDataValue(value);
    props.edit.eventbus.lock(props.field);
    setEditValueDebounced(value);
  }, [setEditValueDebounced, props.edit, props.field]);

  const onBlur = useMemo(() => () => { setEditValueDebounced.now() }, [setEditValueDebounced]);

  return <>
    {/* <TextField
      label={name} name={props.field as string}
      variant="standard" focused={(calcValue)}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      value={dataValue} placeholder={calcValue}
      // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
      onChange={onChange} onBlur={onBlur}
    /> */}

    <FormControl variant="standard" data-id={props.edit.$gid}
      focused={(dataValue || calcValue)}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      fullWidth={props.fullWidth}
    >
      <InputLabel htmlFor={id}>{name}</InputLabel>
      <Input
        id={id}
        name={props.field as string}
        value={dataValue} placeholder={calcValue}
        onChange={onChange} onBlur={onBlur}
        aria-describedby={id2}
        endAdornment={endAdornment}
      />
      {helperText &&
        <FormHelperText id={id2}>
          {helperText}
        </FormHelperText>
      }
    </FormControl>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}

export function useEditField<FIELDS extends STR_KEY_MAP>(props: { field: keyof GRaw<FIELDS> & string, edit: DBRowEdit<FIELDS>, arrayPos?: number }) {
  const id = useId();
  const id2 = useId();

  const refThis = {};
  const [dataValue, setDataValue] = useState(props.edit.get(props.field, props.arrayPos));
  const [calcValue, setCalcValue] = useState(props.edit.get('_' + props.field, props.arrayPos));
  const [arrayPos, setArrayPos] = useState(props.arrayPos);

  let fieldSchema: SchemaField | undefined = props.edit.$schema?.fields[props.field];
  let name = fieldSchema?.$name || utils.fieldNameToDisplay(props.field);

  const [infoText, setInfoText] = useState<string | undefined>(fieldSchema?.info);
  const [warnText, setWarnText] = useState<string | undefined>();
  const [errorText, setErrorText] = useState<string | undefined>();

  const requiredText = (fieldSchema.required && !dataValue) ? (typeof fieldSchema.required === 'string') ? fieldSchema.required : "Field is required" : undefined;

  const helperText = errorText || warnText || requiredText || infoText;
  let errorOrWarn = (errorText || requiredText) ? 'error' : (warnText) ? 'warn' : null;

  // If another source (caller != refThis) changes this value,
  useEffect(() => {
    // console.log("EditField ", props.field, "attaching listeners");
    let processDataChange = (value: any) => { setDataValue(utils.getArrayPos(value, props.arrayPos)) }
    let processCalcChange = (value: any) => { setCalcValue(utils.getArrayPos(value, props.arrayPos)) }

    const cb1 = props.edit.eventbus.onDataSet(props.field, processDataChange);
    const cb2 = props.edit.eventbus.onCalcSet(props.field, processCalcChange);

    // TODO: Break info/warn/error out into arrays too?
    const cb3 = props.edit.eventbus.onValidation(props.field, async () => {
      setInfoText(props.edit.info[props.field] || fieldSchema?.info);
      setWarnText(props.edit.warnings[props.field]);
      setErrorText(props.edit.errors[props.field]);
    });
    setArrayPos(props.arrayPos);
    return () => {
      // console.log("EditField ", props.field, "removing listeners"); 
      if (cb1) cb1(); if (cb2) cb2(); if (cb3) cb3();
    }
  }, [props.edit, props.field, props.arrayPos]);

  const setEditValueDebounced = useMemo(() => {
    return flexdebounce((newValue: any, optionalCalcValue?: any) => {
      props.edit.set(props.field, newValue, undefined, arrayPos);
      if (optionalCalcValue !== undefined) {
        console.log("xxx - updatecalc", '_' + props.field, optionalCalcValue, props.edit);
        props.edit.setCalc('_' + props.field, optionalCalcValue, undefined); //, arrayPos xxx <- fix API to include arrayPos
      }
      // This is because, so far, the eventbus handles only full data changes, not arrayPos part changes
      const fullValue = props.edit.get(props.field, -1);
      props.edit.eventbus.data(props.field as string, fullValue, refThis);
      if (optionalCalcValue !== undefined) {
        props.edit.eventbus.calc("_" + props.field as string, optionalCalcValue, refThis);
      }
      props.edit.eventbus.unlock(props.field);
    }, 1000)
  }, [props.edit, props.field, props.arrayPos]);

  const onValueChange = useMemo(() => (newValue: string, optionalCalcValue?: string) => {
    const value = newValue;
    setDataValue(value);
    setCalcValue(optionalCalcValue);
    props.edit.eventbus.lock(props.field);
    setEditValueDebounced(value, optionalCalcValue);
  }, [setEditValueDebounced, props.edit, props.field, props.arrayPos]);

  const onChange: React.ChangeEventHandler<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement> = useMemo(() => (e) => {
    const value = e.target.value;
    setDataValue(value);
    props.edit.eventbus.lock(props.field);
    setEditValueDebounced(value);
  }, [setEditValueDebounced, props.edit, props.field, props.arrayPos]);

  const onBlur = useMemo(() => () => { setEditValueDebounced.now() }, [setEditValueDebounced]);

  return {
    name, field: props.field, fieldSchema, dataValue, calcValue,
    id, id2, helperText, errorOrWarn, onChange, onValueChange, onBlur,
    infoText, requiredText, warnText, errorText, arrayPos
  };
}

const isMultiline = (fieldSchema?: SchemaField, data?: any, calc?: any) => {
  if (fieldSchema && fieldSchema.$datatype === 'TEXT')
    return true;
  else if (utils.toStr(data, "").indexOf('\n') != -1)
    return true;
  else if (utils.toStr(calc, "").indexOf('\n') != -1)
    return true;
  else
    return false;
}

export const EditTextFieldMUI = <FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) => {
  const {
    name, field, dataValue, calcValue,
    onChange, onBlur, fieldSchema,
    id, id2, helperText, errorOrWarn,
    // infoText, requiredText, warnText, errorText 
    arrayPos,
  } = useEditField(props);
  const multiline = isMultiline(fieldSchema, dataValue, calcValue);

  const safeFocus = Boolean(dataValue) || Boolean(calcValue);
  const safeDataValue = (dataValue === undefined || dataValue === null) ? "" : dataValue;

  const safeCalcValue = (calcValue === undefined || calcValue === null) ? ""
    : (typeof calcValue === 'string') ? calcValue
      : JSON.stringify(calcValue);

  let endAdornment = useMemo(() => {
    if (fieldSchema && fieldSchema.$datatype == "NUM" && fieldSchema.unit) {
      if (typeof fieldSchema.unit) //=== 'string'
        return (<InputAdornment position="end">{fieldSchema.unit}</InputAdornment>);
      // else if (Array.isArray(fieldSchema.unit) && fieldSchema.unit.length > 0)
      //   return (<InputAdornment position="end">{fieldSchema.unit[0]}</InputAdornment>);
      else
        return undefined;
    }
  }, [fieldSchema])

  const InputComponent = multiline ? OutlinedInput : Input;

  return <>
    <FormControl variant={multiline ? 'outlined' : 'standard'} data-id={props.edit.$gid}
      focused={safeFocus}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      fullWidth={props.fullWidth}
    >
      <InputLabel variant={multiline ? 'outlined' : 'standard'} htmlFor={id}>{(props.showName === undefined || props.showName) && name}</InputLabel>
      <InputComponent
        id={id}
        name={props.field}
        label={(props.showName === undefined || props.showName) && name}
        value={safeDataValue} placeholder={safeCalcValue}
        onChange={onChange} onBlur={onBlur}
        aria-describedby={id2}
        endAdornment={endAdornment}
        readOnly={props.readOnly}
        multiline={multiline}
      />
      {helperText &&
        <FormHelperText variant='outlined' id={id2}>
          {helperText}
        </FormHelperText>
      }
    </FormControl>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}


export function EditSelectFieldMUI<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) {
  const {
    name, field, dataValue, calcValue,
    onValueChange, onBlur, fieldSchema,
    id, id2, helperText, errorOrWarn,
    // infoText, requiredText, warnText, errorText 
  } = useEditField(props);


  const menuValuesRef = useRef(new Set());
  const menuValues = menuValuesRef.current;

  const needDefaultMenuItem = (utils.isEmpty(dataValue) || menuValues.has(dataValue) === false);


  /** Create the select dropdown items */
  const menuItems = useMemo(() => {
    // let foundDataValue = false;
    let menuItems: JSX.Element[] = [];
    if (fieldSchema && fieldSchema.values) {
      menuValues.clear();
      fieldSchema.values.map((v) => {
        if (Array.isArray(v)) {
          let [value, name] = v;
          // if (value == dataValue) foundDataValue = true;
          menuItems.push(<MenuItem key={value ? value : '' + value} value={value}>{name}</MenuItem>);
          menuValues.add(value);
        } else if (typeof v === 'string' || typeof v === 'number') {
          // if (v == dataValue) foundDataValue = true;
          menuItems.push(<MenuItem key={v ? v : '' + v} value={v}>{v}</MenuItem>);
          menuValues.add(v);
        } else {
          console.warn("Unable to add fieldSchema.value as select item: ", v);
        }
      })
    }

    // if (foundDataValue == false) {
    //   const notFoundMenuItem = (<MenuItem key={dataValue ? dataValue : '' + dataValue} value={dataValue}>{dataValue}</MenuItem>);
    //   menuItems = [notFoundMenuItem, ...menuItems];
    // }

    return menuItems;
  }, [props.edit, props.field]);



  const onSelectChange = useMemo(() => {
    return (event: SelectChangeEvent<any>, child: React.ReactNode) => {
      onValueChange(event.target.value)
    }
  }, [onValueChange]);

  return <>
    <FormControl variant="standard" data-id={props.edit.$gid}
      focused={Boolean(dataValue || calcValue)}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      fullWidth={props.fullWidth}
    >
      <InputLabel htmlFor={id}>{(props.showName === undefined || props.showName) && name}</InputLabel>

      <Select
        labelId={id}
        aria-describedby={id2}
        value={dataValue || ""} placeholder={calcValue}
        label={name}
        onChange={onSelectChange} onBlur={onBlur}
        readOnly={props.readOnly}
      >
        {needDefaultMenuItem && generateDefaultMenuItem(dataValue || "", calcValue)}
        {menuItems}
      </Select>

      {helperText &&
        <FormHelperText id={id2}>
          {helperText}
        </FormHelperText>
      }
    </FormControl>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}

function generateDefaultMenuItem(dataValue: any, calcValue: any) {
  // console.log("seleidid key ", dataValue)
  return (<MenuItem key={dataValue ? dataValue : '' + dataValue} value={dataValue}>{(RefID.parse(dataValue) != null && calcValue) ? calcValue : dataValue}</MenuItem>);
}

function generateMenuItem(raw: GRaw<any>, gid: string) {
  const name = utils.$IRowProcessNAME(raw);
  return <MenuItem key={gid} value={gid} data-gid={gid} data-name={name}>{name}</MenuItem>;
}

export function EditIDSelectFieldMUI<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS> & { generateMenuItem?: (raw: GRaw<any>, gid: string) => JSX.Element }) {
  const {
    name, field, dataValue, calcValue,
    onValueChange, onBlur, fieldSchema,
    id, id2, helperText, errorOrWarn,
    // infoText, requiredText, warnText, errorText 
  } = useEditField(props);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [menuItems, setMenuItems] = useState<JSX.Element[] | null>(null);
  // More of a ref?
  const [menuValues] = useState(new Set());
  const [selectedMenuItem, setSelectedMenuItem] = useState(generateDefaultMenuItem(dataValue, calcValue));

  // const needDefaultMenuItem = ((dataValue || "" !== "") && menuValues.has(dataValue || "") === false);
  const needDefaultMenuItem = (utils.isEmpty(dataValue) || menuValues.has(dataValue) === false);

  const internalHelperText =
    (isLoading) ? 'Loading options...' :
      (error) ? error :
        (helperText) ? helperText :
          (needDefaultMenuItem && utils.isEmpty(dataValue) == false) ? "Not linked in database"
            : undefined;

  const localGenerateMenuItem = props.generateMenuItem || generateMenuItem;

  // 


  console.log("EditIDSelectFieldMUI ", name, field, dataValue, calcValue);

  // so when we delete from an array, this 'non-link' value isn't right, b/c it was generated for the shifted value
  // Need to detect when that happens and re-run this generateDataGridColumnsFromSchemaDBRow
  // This we need to useState to store valid values (that aren't offical links) and every render make sure that 
  // the data value matches that off-type entry
  // Or maybe do that every render? Hmm



  /** Create the select dropdown items */
  useEffect(() => {

    if (fieldSchema && "table" in fieldSchema && Reference.parseAnyOrNull(fieldSchema.table) != null) {
      const tableRef = Reference.parseAnyOrNull(fieldSchema.table);
      const tableName = tableRef?.getTable();

      const dbTable = props.edit.branch.table(tableName);
      if (dbTable == null) {
        setError("Unable to resolve linked table: " + (tableName || fieldSchema.table) + ". Likely issue with database schema.")
        return;
      } else {
        setError(null);

        // Raw
        dbTable.query({}).list().then((values: IDBRow<FIELDS>[]) => {
          // let foundDataValue = false;
          let menuItems: JSX.Element[] = [];
          menuValues.clear();

          // console.log("EditIDSelectFieldMUI", 'values', values);
          for (let i = 0; i < values.length; i++) {
            const v = values[i].$raw;
            const gid = utils.$IRowProcessGID(v.$table, v.$id);
            // if (gid == dataValue) foundDataValue = true;

            menuItems.push(localGenerateMenuItem(v, gid));

            // Use this to detect if our current value is out of bounds
            menuValues.add(utils.$IRowProcessGID(v.$table, v.$id));

            if (i > 10000) {
              console.error("EditIDSelectFieldMUI - more than 10k values loaded, ignoring the rest. Change to a component than can handle more items!", (tableName || fieldSchema.table), field);
              break;
            }
          }

          // if (foundDataValue == false) {
          //   const notFoundMenuItem = generateDefaultMenuItem(dataValue, calcValue);
          //   //(<MenuItem key={dataValue ? dataValue : '' + dataValue} value={dataValue}>{dataValue}</MenuItem>);
          //   menuItems = [notFoundMenuItem, ...menuItems];
          // }

          setMenuItems(menuItems);
          setIsLoading(false);
        });

      }

    }
  }, [props.edit, props.field, fieldSchema]);

  const onSelectChange =
    useMemo(() => {
      //child: React.ReactNode
      return (event: SelectChangeEvent<any>, child: any) => {
        let dataName = undefined;
        if (typeof child === 'object' && typeof child.props === 'object') {
          dataName = child.props['data-name'];
        }
        console.log("onValueChange(event.target.value, dataName)", event.target.value, dataName);
        onValueChange(event.target.value, dataName)
      }
    }, [onValueChange]);

  return <>
    <FormControl variant="standard" data-id={props.edit.$gid}
      focused={Boolean(dataValue || calcValue)}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      fullWidth={props.fullWidth}
    >
      <InputLabel htmlFor={id}>{(props.showName === undefined || props.showName) && name}</InputLabel>

      {menuItems &&
        <Select
          // native={true}
          labelId={id}
          aria-describedby={id2}
          value={dataValue || ""} placeholder={calcValue}
          label={name}
          onChange={onSelectChange} onBlur={onBlur}
          readOnly={props.readOnly || isLoading}
        >
          {/* If our select dropdown doesn't have any entry for the current value, insert a 'default' one at the top of the list */}
          {needDefaultMenuItem && generateDefaultMenuItem(dataValue || "", calcValue)}
          {menuItems}
        </Select>
      }

      {internalHelperText &&
        <FormHelperText id={id2}>
          {internalHelperText}
        </FormHelperText>
      }
    </FormControl>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}


export function EditIDAutoCompleteSelectFieldMUI<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) {
  const {
    name, field, dataValue, calcValue,
    onValueChange, onBlur, fieldSchema,
    id, id2, helperText, errorOrWarn,
    // infoText, requiredText, warnText, errorText 
  } = useEditField(props);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const internalHelperText = (error) ? error : (helperText) ? helperText : (isLoading) ? 'Loading options...' : undefined;
  const internalErrorOrWarn = errorOrWarn;

  // 

  const [menuItems, setMenuItems] = useState<JSX.Element[] | null>(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(generateDefaultMenuItem(dataValue, calcValue));


  /** Create the select dropdown items */
  useEffect(() => {
    if (fieldSchema && "table" in fieldSchema && Reference.parseAnyOrNull(fieldSchema.table) != null) {
      const tableRef = Reference.parseAnyOrNull(fieldSchema.table);
      const tableName = tableRef?.getTable();

      const dbTable = props.edit.branch.table(tableName);
      if (dbTable == null) {
        setError("Unable to resolve linked table: " + (tableName || fieldSchema.table) + ". Likely issue with database schema.")
        return;
      } else {
        setError(null);

        // Raw
        dbTable.query({}).list().then((values) => {
          let foundDataValue = false;
          let menuItems: JSX.Element[] = [];

          console.log("EditIDSelectFieldMUI", 'values', values);
          for (let i = 0; i < values.length; i++) {
            const v = values[i].$raw;
            if (v == dataValue) foundDataValue = true;
            const gid = utils.$IRowProcessGID(v.$table, v.$id);
            menuItems.push(<MenuItem key={gid} value={gid} data-gid={gid}>{utils.$IRowProcessNAME(v)} [{v.$table}/{v.$id}]</MenuItem>);

            if (i > 10000) {
              console.error("EditIDSelectFieldMUI - more than 10k values loaded, ignoring the rest. Change to a component than can handle more items!", (tableName || fieldSchema.table), field);
              break;
            }
          }

          if (foundDataValue == false) {
            const notFoundMenuItem = generateDefaultMenuItem(dataValue, calcValue);
            //(<MenuItem key={dataValue ? dataValue : '' + dataValue} value={dataValue}>{dataValue}</MenuItem>);
            menuItems = [notFoundMenuItem, ...menuItems];
          }

          setMenuItems(menuItems);
          setIsLoading(false);
        });

      }

    }
  }, [props.edit, props.field, fieldSchema]);

  const onSelectChange =
    useMemo(() => {
      return (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        onValueChange(event.target.value)
      }
    }, [onValueChange]);

  return <>

    <FormControl variant="standard" data-id={props.edit.$gid}
      focused={Boolean(dataValue || calcValue)}
      error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
      fullWidth={props.fullWidth}
    >
      <InputLabel htmlFor={id}>{(props.showName === undefined || props.showName) && name}</InputLabel>

      {menuItems &&
        <Select
          // native={true}
          labelId={id}
          aria-describedby={id2}
          value={dataValue || ""} placeholder={calcValue}
          label={name}
          onChange={onSelectChange} onBlur={onBlur}
          readOnly={props.readOnly || isLoading}
        >
          {menuItems}
        </Select>
      }

      {internalHelperText &&
        <FormHelperText id={id2}>
          {internalHelperText}
        </FormHelperText>
      }
    </FormControl>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}


export const EditDateFieldMUI = <FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) => {
  let {
    name, field, dataValue, calcValue,
    onValueChange, onBlur, fieldSchema,
    id, id2, helperText, errorOrWarn,
    // infoText, requiredText, warnText, errorText 
  } = useEditField(props);

  if (dataValue === undefined)
    dataValue = null;

  // For this component, it wants us to pass in a Date, otherwise it'll parse string however it wants to.
  // If the input is only the date part, it'll add in UTC 00, then parse to the previous day.
  let dateValue = dataValue;
  if (dateValue) {
    const parsed = utils.toDate(dateValue);
    if (parsed != null) dateValue = parsed;
  }

  let safeCalcValue = (calcValue === undefined || calcValue === null) ? ""
    : (typeof calcValue === 'string') ? calcValue
      : JSON.stringify(calcValue);

  // The trick here is that a date can be invalid, and should be treated as undefined/null in that case.
  if (calcValue instanceof Date) {
    if (!isNaN(calcValue.valueOf())) {
      try { safeCalcValue = dateFns.format(calcValue, "keyboardDate"); } catch (ignore) { }
    } else {
      safeCalcValue = "";
    }
  }
  const safeFocus = Boolean(dataValue) || Boolean(safeCalcValue);

  return <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>

      {/* needed? focused={(dataValue || calcValue)} */}
      {/* DaV: {dataValue}<br />
      DeV: {utils.toDayStr(dateValue)}<br /> */}
      <DatePicker
        data-id={props.edit.$gid}
        label={name}
        openTo="day"
        views={['year', 'month', 'day']}
        value={dateValue}
        onChange={(value: Date | null, keyboardInput) => {

          console.log("OnChange: ", value, keyboardInput);
          // If there is a keyboard input, value must be formatted and parsed from it.
          if (keyboardInput) {
            try {
              let date = dateFns.parse(keyboardInput, dateFns.formats.keyboardDate);
              console.log("OnChange - KB Parse: ", date);
              if (!isNaN(date.valueOf())) {
                console.log("OnChange - OUT: ", date.toISOString(), formatISO(date, { representation: 'date' }));
                onValueChange(date.toISOString());
                // onValueChange(formatISO(date, { representation: 'date' }));
              }
            } catch (ignoreError) { }
          } else if (value instanceof Date && !isNaN(value.valueOf())) {
            try {
              let result = value.toISOString();// formatISO(value, { representation: 'date' });
              console.log("OnChange - OUT: ", result);
              onValueChange(result)
            } catch (ignoreError) { }
          } else {
            onValueChange("");
          }

        }}
        renderInput={({ inputProps, ...restParams }) =>
          <TextField focused={safeFocus || restParams?.focused}
            variant="standard" fullWidth={props.fullWidth}
            helperText={helperText} // - alternate due to placeholder setting not working
            // helperText={(safeCalcValue) ? "Calc Value: " + safeCalcValue : helperText}
            error={(errorOrWarn == 'error')} color={(errorOrWarn == 'warn') ? 'warning' : undefined}
            onBlur={onBlur}
            {...restParams}
            inputProps={{
              ...inputProps,
              placeholder: safeCalcValue || inputProps?.placeholder,
            }}
          // placeholder={safeCalcValue}
          />
        }
        readOnly={props.readOnly}
      />
    </LocalizationProvider>

    {/* <br />
    <pre>{JSON.stringify({ errorOrWarn, infoText, warnText, errorText, dataValue, calcValue }, null, 2)}</pre> */}
  </>;
}
