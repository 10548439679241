import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";
import Root from './routes/root';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<Root />}>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();







// ---------------------------------------------------------------------------------------------------

// The following is an example of how to have async processing w/ passed in field name, fully typed.
// No idea about recursive, following links, using the @./ ref fieldname, etc.

// export type Brew_Fields = {
//   $table: "Brew",
//   recipe?: string,
//   start_brew?: Date,
//   end_brew?: Date,
//   duration?: number,
//   brewduration?: number,
//   tank?: string,
//   fermentduration?: number,
//   amount?: number,
//   status?: string,
//   brewer?: string,
//   asst_brewer?: string,
//   notes?: string,
//   ingredients?: string,
//   ingredient_item?: string[],
//   ingredient_type?: string[],
//   ingredient_amount?: number[],
//   ingredient_unit?: string[],
// }

// function test2<KEY extends keyof Brew_Fields>(fieldname: KEY): Brew_Fields[KEY] {
//   const data: Brew_Fields = {} as Brew_Fields;
//   return data[fieldname];
// }


// function test<KEY extends keyof Brew_Fields>(...fieldnames: KEY[]): Brew_Fields[KEY][] {
//   const data: Brew_Fields = {} as Brew_Fields;
//   const output = fieldnames.map((fieldname) => test2(fieldname));
//   return output;
// }

// function test4<KEY extends keyof Brew_Fields>(...fieldnames: KEY[]): { [key in KEY]: Brew_Fields[key] } {
//   const data: Brew_Fields = {} as Brew_Fields;
//   // This asserts output is already the right return type. 
//   // Probably ok since we will be populating in the next line.
//   const output = <{ [key in KEY]: Brew_Fields[key] }>{};
//   for (const field of fieldnames) {
//     output[field] = data[field];
//   }

//   // fieldnames.map((fieldname) => test2(fieldname)).;
//   return output;
// }

// function test3() {

//   const rtn = test("duration", "tank");
//   const rtnObj = test4("duration", "tank");

// }
