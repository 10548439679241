import { Route, Routes } from "react-router-dom";

import Browse, { BROWSE_PROPS } from "./Browse";
import { DBRow } from "../../shared/vdb/VaultDB";
import ConfigGenericEdit from "./ConfigEdits";
import { STR_KEY_MAP } from "shared/vdb/types";
import getdb from "client/hopiqvdb";

const vdb = getdb('EditTest');

export default function GenericBrowseEdit<FIELDS extends STR_KEY_MAP, DBROWTYPE extends DBRow<FIELDS>>(props: BROWSE_PROPS<DBROWTYPE>) {
  return (
    <Routes>
      <Route path=":id" element={<ConfigGenericEdit table={props.table} vdb={vdb} />} />
      <Route index={true} element={<Browse table={props.table} defaultNav={true} />} />
    </Routes>
  );
}
