import { DBRowEdit } from "../DBRowEdit";
import { AsyncFormatter, newCalcFormat, ChildTable, SchemaTable } from "../types";
import { fnn, toDateOr, toNum } from "../utils";
import DateFnsAdapter from "@date-io/date-fns";
import { createInvResource, createInvUse } from "../MetaInv";

const dateFns = new DateFnsAdapter();

export const GLOBAL = {
  name: "HopIQ"
}

export const Ingredient: SchemaTable = {
  type: "Table",
  name: "Brew Ingredient",
  key: "Ingredient",
  fields: {
    name: { $datatype: 'STR' },
    type: { $datatype: 'STR', values: ['Grain', 'Hop'] },
    unit: { $datatype: 'STR', values: ['lbs', 'oz'] },
  },
}

// $key,$name,brew,label,brewduration,fermentduration,color,notes
export const Recipe: SchemaTable = {
  type: "Table",
  name: "Recipe",
  key: "Recipe",
  fields: {
    name: { $name: "Name", $datatype: 'STR', index: true },
    brew: { $name: "Brew", $datatype: 'STR', index: true },
    label: { $name: "Label", $datatype: 'STR', index: true },
    brewduration: { $datatype: 'NUM', unit: 'Days' }, // Days
    fermentduration: { $datatype: 'NUM', unit: 'Days', $array: 1 }, // Days
    color: { $datatype: 'STR' },
    notes: { $datatype: 'TEXT' },

    // Ingredient list
    ingredients: { $datatype: 'EMBED_ARRAY', fields: ['ingredient_item', 'ingredient_type', 'ingredient_amount', 'ingredient_unit'] },
    ingredient_item: { $name: "Ingredient", $datatype: 'ID', table: "@/Ingredient", $array: 1 },
    ingredient_type: { $name: "Type", $datatype: 'STR', values: ["Grain", "Hop"], $array: 1 },
    ingredient_amount: { $name: "Amount", $datatype: 'NUM', $array: 1 },
    ingredient_unit: { $name: "Unit", $datatype: 'STR', values: ["lbs", "oz"], $array: 1 },
  },
}

// Removing to move into Recipe
//$key,recipe,type,OrgAmt,amount,unit,ingredient
// export const RecipePart: ChildTable = {
//   type: "Table",
//   name: "RecipePart",
//   key: "RecipePart",
//   parent: "Recipe",
//   // $summtmpl: { $type: TYPES.DSOTMPL, part: "~Kingredient" },
//   fields: {
//     recipe: { $datatype: 'ID', table: "@/Recipe" },
//     ingredient: { $datatype: 'ID', table: "@/Ingredient" },
//     type: { $name: "Type", $datatype: 'STR', values: ["Grain", "Hop"] },
//     amount: { $datatype: 'NUM' },
//     unit: { $name: "Type", $datatype: 'STR', values: ["lbs", "oz"] },
//   },
//   metatypes: {
//     '$name': {
//       deps: ["@./_recipe", "@./recipe", "@./ingredient"],
//       format: ([_recipe, recipe, ingredient]) => { return fnn(_recipe, recipe) + "|" + ingredient; }
//     },
//   }
// }

export const FermentationTank: SchemaTable = {
  type: "Table",
  name: "Fermentation Tank",
  key: "FermentationTank" as "FermentationTank",
  fields: {
    capacity: { $datatype: 'NUM', prec: 1, unit: "BBL" },
    manufacture: { $datatype: 'STR' },
    model: { $datatype: 'STR', info: "Put in manufacture's model exactly for calibration calculations" },
    // color: {
    //   $datatype: 'STR',
    //   required: true,
    //   info: "Make it colorful!",
    //   validation: (v: any, field: string, rowEdit: DBRowEdit<any>) => { if (v && v != "red") return { warn: "Only acceptable value is 'red'" } }
    // }
  },
  metatypes: {
    // Defaults: path:"@$pid", unit: "unit"
    // 'inv_item': { unit: '@unit' }
    // '$invItem-resource': {
    //   itemprefix: 'FermentationTankCount'
    //   item: "@$key",
    //   capacity: "@capacity",
    //   model: "@model",


    //   itemprefix: "BrewTankAmount",
    //     item: "@tank", // gid of tank, or apply to prefix of all tanks
    //     start: "@start_brew",
    //     // end: "@start_brew",
    //     status: "@status",
    //     // adj: '-',
    //     qty: ["@amount", 1],
    //     srcid: "@$gid", // Row this comes from

    //   item?: string | (string | undefined)[] | undefined;
    //   end?: string | Date | (string | Date | undefined)[] | undefined;
    //   status: string | string[];
    //   itemprefix: string | string[];
    //   qty: string | ... 1 more ... | (string | number)[];
    //   srcid: string | string[];
    //   start: string | ... 1 more ... | (string | Date)[];

    // }
  },
}


// name,batch,$key,recipe,status,tank,start_brew,amount,brewduration,fermentduration,duration,end_brew,_end_brew
export const Brew: SchemaTable = {
  type: "Table",
  name: "Brew Session",
  key: "Brew",
  fields: {
    recipe: { $datatype: 'ID', table: "@/Recipe" },
    start_brew: { $datatype: 'DATE', index: true },
    end_brew: {
      $datatype: 'DATE', index: true,
      calc: {
        deps: ["@start_brew", "@?duration"],
        format: ([startBrew, duration]) => {
          const durNum = Number.parseFloat(duration);
          if (typeof startBrew === 'string' && Number.isFinite(durNum)) {
            const startDate = dateFns.parseISO(startBrew);
            const endDate = dateFns.addDays(startDate, durNum);
            return endDate.toISOString();//.split("T")[0];
          }
        },

      }
    },
    duration: {
      $datatype: 'NUM', unit: 'Days', index: true,
      calc: newCalcFormat(
        ["@brewduration", "@fermentduration"],
        ([brewduration, fermentduration]) => {
          let newDuration = toNum(brewduration, 0) + toNum(fermentduration, 1);
          return (Number.isFinite(newDuration)) ? Math.max(1, newDuration) : undefined;
        })
    }, // Days


    status: { $datatype: 'STR', values: ["PLAN", "ACTIVE", "DONE", "CANCEL"], index: true },
    amount: { $datatype: 'NUM', prec: 1, unit: "BBL" },
    batchNum: { $datatype: 'NUM' }, // Need to add an auto-increment on create

    brewer: { $datatype: 'STR' },
    asst_brewer: { $name: 'Asst. Brewer', $datatype: 'STR' },
    notes: { $datatype: 'TEXT' },

    // Brew plan - Over 1 day consumes the entire day
    brewduration: { $datatype: 'NUM', unit: 'Days', $defaultValue: 1 }, // Days
    // Fermentation plan how long each fermentation will take, and in which tank
    fermentation: { $name: "Fermentation", $datatype: 'EMBED_ARRAY', fields: ['fermentduration', 'tank'] },
    fermentduration: { $embed: 'fermentation', $datatype: 'NUM', unit: 'Days', $array: 1 },
    tank: { $embed: 'fermentation', $datatype: 'ID', table: '@/FermentationTank', $array: 1 }, // 

    // Ingredient list
    ingredients: { $name: "Ingredients", $datatype: 'EMBED_ARRAY', fields: ['ingredient_item', 'ingredient_type', 'ingredient_amount', 'ingredient_unit'] },
    ingredient_item: { $embed: 'ingredients', $name: "Ingredient", $datatype: 'ID', table: "@/Ingredient" },
    ingredient_type: { $embed: 'ingredients', $name: "Type", $datatype: 'STR', values: ["Grain", "Hop"] },
    ingredient_amount: { $embed: 'ingredients', $name: "Amount", $datatype: 'NUM' },
    ingredient_unit: { $embed: 'ingredients', $name: "Unit", $datatype: 'STR', values: ["lbs", "oz"] },

    // Packaging Output
    package: { $name: "Package", $datatype: 'EMBED_ARRAY', fields: ['pack_type', 'pack_qty'] },
    pack_type: { $embed: 'package', $name: "Package Type", $datatype: 'STR', $array: 1, values: ['Keg - Sixtel', 'Keg - Half Barrel', 'Aging Barrrel', 'Can - Case', 'Can - Low Pour', 'Can - Damaged', 'Can - Other'] },
    pack_qty: { $embed: 'package', $name: "Package Qty", $datatype: 'NUM', $array: 1 },

  },
  metatypes: {
    '$name': {
      deps: ["@_recipe", "@recipe", "@start_brew"],
      format: ([recipeCalc, recipeData, start]) => {
        let startDate = toDateOr(start, null);
        let startStr = (startDate) ? (startDate.getMonth() + 1) + "/" + startDate.getDate() : "No Start";
        // const startDateStr = dateFns.parseISO(start) || "" + start;
        console.log("Brew metatype $name: ", [recipeCalc, recipeData, start], startDate, startStr, fnn(recipeCalc, recipeData, "N/A") + " on " + startStr);
        return fnn(recipeCalc, recipeData, "N/A") + " @ " + startStr;
      }
    },

    '$inv_delta-ingredient-use':
      createInvUse({
        item: ["@ingredient_item"], // gid of tank, or apply to prefix of all tanks
        when: "@start_brew",
        end: "@end_brew",
        status: "@status",
        qty: ["@ingredient_amount"],
        srcid: "@$gid", // Row this comes from
        // srclabel: "$inv_delta-brewtank", // field|pos|subpart
      }),
    // '$inv_delta-brewtank-amount':
    // createInvResource(true,{
    //   itemprefix: "BrewTankAmount",
    //   item: "@tank", // gid of tank, or apply to prefix of all tanks
    //   start: "@start_brew",
    //   // end: "@start_brew",
    //   status: "@status",
    //   // adj: '-',
    //   qty: ["@amount", 1],
    //   srcid: "@$gid", // Row this comes from
    //   // srclabel: "$inv_delta-brewtank", // field|pos|subpart
    // }),
    '$inv_delta-brewtank-count':
      createInvResource(true, {
        itemprefix: "BrewTankCount",
        item: "@amount", // gid of tank, or apply to prefix of all tanks
        start: "@start_brew",
        // end: "@end_brew", --- temp code to make duration 1 day if no end provided
        status: "PLAN",
        // adj: '-',
        qty: ["@amount", 1],
        brewduration: "@brewduration",
        srcid: "@$gid", // Row this comes from
        // srclabel: "$inv_delta-brewtank", // field|pos|subpart
      }),
    '$inv_delta-fermenttank':
      createInvResource(true, {
        itemprefix: "EquipFermentationTank",
        item: ["@tank"], // gid of tank, or apply to prefix of all tanks
        start: "@start_brew",
        end: "@end_brew",
        status: "@status",
        // adj: '-',
        qty: ["@amount", 1],
        brewduration: "@brewduration",
        srcid: "@$gid", // Row this comes from
        // srclabel: "$inv_delta-brewtank", // field|pos|subpart
      }),
  }
}




// export const EquipBrewTank: SchemaTable = {
//   type: "Table",
//   name: "Brew Tank",
//   key: "EquipBrewTank" as "EquipBrewTank",
//   fields: {
//     name: { $datatype: 'STR', index: true, required: true },
//     capacity: { $datatype: 'NUM', prec: 1, unit: "BBL" },
//     manufacture: { $datatype: 'STR' },
//     model: { $datatype: 'STR' }, //, info: "Put in manufacture's model exactly for calibration calculations" 
//     // color: {
//     //   $datatype: 'STR',
//     //   required: true,
//     //   info: "Make it colorful!",
//     //   validation: (v: any, field: string, rowEdit: DBRowEdit<any>) => { if (v && v != "red") return { warn: "Only acceptable value is 'red'" } }
//     // }
//   },
//   metatypes: {
//     // Defaults: path:"@$pid", unit: "unit"
//     // 'inv_item': { unit: '@unit' }
//     '$invItem-resource': {
//       name: "@name",
//       capacity: "@capacity",
//       model: "@model",
//     }
//   },
// }


export const BeerType: SchemaTable = {
  type: "Table",
  name: "Beer Type",
  key: "BeerType" as const,
  fields: {
    name: { $datatype: 'STR', index: true, required: true },
    style: { $datatype: 'STR' },
    color: {
      $datatype: 'STR',
      // required: true,
      // info: "Make it colorful!",
      // validation: (v: any, field: string, rowEdit: DBRowEdit<any>) => { if (v && v != "red") return { warn: "Only acceptable value is 'red'" } },
    }
  },
}


