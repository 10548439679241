import { Grid } from "@mui/material";
import { useId, useMemo, useState } from "react";
import { DBRowEdit } from "../../../shared/vdb/DBRowEdit";
import { GRaw, GRawSystem, STR_KEY_MAP } from "../../../shared/vdb/types";
import { EditFieldMUI } from "./MUIEditFields";

export const AutoMUIEditLayout = <FIELDS extends STR_KEY_MAP,>(props: { edit: DBRowEdit<FIELDS> }) => {

  const editFields = useMemo(() => {
    let tableSchema = props.edit.$schema;
    let fields = new Map(Object.keys(tableSchema.fields).map((fieldName) => [fieldName, tableSchema.fields[fieldName]]));

    // Find fields to remove
    let embedFields = Array.from(fields.entries()).filter(([k, v]) => { if (v.$datatype === 'EMBED_ARRAY') return [k, v] });
    let fieldsToRemove = Array.from(fields.entries())
      .filter(([k, v]) => { if (v.$datatype === 'EMBED_ARRAY') return true; })
      .flatMap(([k, v]) => { if (v.$datatype === 'EMBED_ARRAY') return v.fields });


    console.log("AutMUIEditLayout: ", { embedFields, fieldsToRemove });

    for (let badfield of fieldsToRemove)
      if (badfield !== undefined)
        fields.delete(badfield);

    return fields;
  }, [props.edit])

  return (
    <Grid container spacing={2} data-id-wrap={props.edit.$gid}>
      {Array.from(editFields).map(([k, v]) => {
        if (v.$datatype === 'EMBED_ARRAY')
          return (
            <Grid key={k} data-id={props.edit.$gid} data-field={k} item xs={12} style={{ marginBottom: 1 }}>
              <EditFieldMUI edit={props.edit} field={k as Exclude<keyof FIELDS, keyof GRawSystem> & string} fullWidth={true} />
            </Grid>
          );
        else
          return (
            <Grid key={k} data-id-wrap={props.edit.$gid} item lg={4} md={6} xs={12}>
              <EditFieldMUI edit={props.edit} field={k as Exclude<keyof FIELDS, keyof GRawSystem> & string} fullWidth={true} />
            </Grid>
          );
      })}
      <Grid key='endspace' item xs={12}>
        <br />
      </Grid>

      {/* {Object.keys(props.edit.$schema.fields).map((k) => {
        return (
          <Grid key={k} data-id-wrap={props.edit.$gid} item lg={4} md={6} xs={12}>
            <EditFieldMUI edit={props.edit} field={k as Exclude<keyof FIELDS, keyof GRawSystem> & string} fullWidth={true} />
          </Grid>
        );
      })} */}
    </Grid>
  );
}
