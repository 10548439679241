import React, { useEffect, useState } from "react";
import { TaskData, GroupData } from "../client/charttasks";
import ChartTasks from "../client/charttasks/ChartTasks";

import { getdb } from 'client/hopiqvdb';
import { BeerTypeGRaw, BrewGRaw, DBBeerType, DBBrew } from "shared/vdb/vdbsharedclass";
import e from "express";
import { BeerType, Brew } from "@prisma/client";
import { dateFns } from "shared/vdb/utils";
const vdb = getdb('ChartTestPage');

const tasks: TaskData[] = [
  { id: "b1", name: "IPA Hazy", groupID: "brew1", start: new Date("2023-04-22"), end: new Date("2023-04-23") },
  { id: "b1.1", name: "IPA Hazy", groupID: "t1", start: new Date("2023-04-22"), end: new Date("2023-05-02"), dependencies: ["b1"] },
  { id: "b1.2", name: "IPA Hazy", groupID: "t2", start: new Date("2023-04-22"), end: new Date("2023-05-02"), dependencies: ["b1"] },

  { id: "b2", name: "IPA Hazy", groupID: "brew1", start: new Date("2023-04-22"), end: new Date("2023-04-23") },
  { id: "b2.1", name: "IPA Hazy", groupID: "t1", start: new Date("2023-04-22"), end: new Date("2023-05-02"), dependencies: ["b1"] },
  { id: "b2.2", name: "IPA Hazy", groupID: "t2", start: new Date("2023-04-22"), end: new Date("2023-05-02"), dependencies: ["b1"] },

  // { id: "b2.1", name: "IPA Conflict", groupID: "f2", start: new Date("2023-04-23"), end: new Date("2023-04-28"), dependencies: ["t1"] },
  // { id: "t3", name: "Yeast Avail", groupID: "yeast", start: new Date("2023-04-24"), end: new Date("2023-04-28"), dependencies: ["t2"], class: 'yeast' },
];

const groups: GroupData[] = [
  // { id: "brew1", name: "Brew AM" },
  // { id: "brew2", name: "Brew PM" },
  // { id: "yeast", name: "Yeast" },
  { id: "t", name: "Unassigned" },
  { id: "t1", name: "T1 7bbl" },
  { id: "t2", name: "T2 7bbl" },
  { id: "t3", name: "T3 15bbl" },
  { id: "t4", name: "T4 7bbl" },
  { id: "t5", name: "T5 15bbl" },
  { id: "t6", name: "T6 15bbl" },
  { id: "t7", name: "T7 7bbl" },
  { id: "t8", name: "T8 15bbl" },
  { id: "t9", name: "T9 15bbl" },
  { id: "t10", name: "T10 15bbl" },
]
type Props = {};



export const TaskChartTest = (props: Props) => {

  const [brewSessions, setBrewSessions] = useState<DBBrew[] | undefined>();
  const [beerTypes, setBeerTypes] = useState<DBBeerType[] | undefined>();

  useEffect(() => {
    async function process() {
      let pBrewSessions = await vdb.Brew.query({}).list();
      setBrewSessions(pBrewSessions);

      let pBeerTypes = await vdb.BeerType.query({}).list();
      setBeerTypes(pBeerTypes);
    }
    process();
  }, []);

  const [tasks, setTasks] = useState<TaskData[]>([]);

  useEffect(() => {
    if (brewSessions === undefined)
      setTasks([]);
    else
      setTasks(
        brewSessions.map((bs) => {
          let endDate = null;

          const startBrew = bs.get("?start_brew");
          const duration = bs.get("?duration");
          const durNum = Number.parseFloat(duration);
          const startDate = dateFns.parseISO(startBrew);
          if (typeof startBrew === 'string' && Number.isFinite(durNum)) {
            endDate = dateFns.addDays(startDate, durNum);
          } else {
            endDate = dateFns.addDays(startDate, 1);
          }

          let task: TaskData = {
            id: bs.$gid,
            name: (bs.$name || bs.$key) + bs.getDisplay('status') ,
            start: startDate,
            end: endDate,
            progress: 0,
            dependencies: [],
            groupID: "t"+(bs.get('tank')||""),
          }

          if (task.start instanceof Date == false || Number.isNaN(task.start.valueOf()))
            return null;
          if (task.end instanceof Date == false || Number.isNaN(task.end.valueOf()))
            return null;

          return task;
        })
          .filter((t) => t) as TaskData[]
      );
  }, [brewSessions]);



  return (
    <React.Fragment>
      <p>Paragraph</p>
      {tasks &&
        <ChartTasks title="ChartTasks v3" tasks={tasks} groups={groups} options={{}} />
      }
      {/* <ReactTaskChart title="test" data={data} /> */}
      {/*<TaskChart data={data} options={{}} /> */}
      <pre>tasks: {JSON.stringify(tasks, null, 2)}</pre>

      <pre>brew: {JSON.stringify(brewSessions?.map((bs)=>bs.$raw), null, 2)}</pre>
      {/* <pre>Data: {JSON.stringify(brewSessions?.map((b) => b.$raw), null, 2)}</pre> */}
    </React.Fragment>
  );
}
export default TaskChartTest;