import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Popover, Select, SelectChangeEvent, SxProps, Tab, Tabs, Theme, Typography, useMediaQuery } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

import GenericBrowseEdit from "../../../client/components/GenericBrowseEdit";
import SimpleBrews from "../setup/OldPages/SimpleBrews";

import getdb from 'client/hopiqvdb';
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { Brew_Fields, DBBrew } from "shared/vdb/vdbsharedclass";
import { Link } from "react-router-dom";
import useVDB from "client/vdb/useVDB";
import ErrorAlert from "client/vdb/ErrorAlert";
import { MouseEvent } from "react";
import useSessState from "client/vdb/useSessState";
import DialogStartBrew from "./DialogStartBrew";
import { useTheme } from "@emotion/react";
import DialogEndBrew from "./DialogEndBrew";
import NewBrewMin from "client/components/NewBrewMin";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { toNum } from "shared/vdb/utils";
import { RowText } from "client/vdb/mui/MUIEditParts";
// const vdb = getdb();
const vdb = getdb("BrewHome", false);

const ACCORD_SX: SxProps<Theme> = { backgroundColor: '#f6fbff', ":hover": { backgroundColor: '#e3f2fd' } };



export default function BrewHome() {
  const theme = useTheme();
  const fullScreen = useMediaQuery('(max-width:600px)');

  const [plannedBrews, plannedBrewsError] = useVDB(vdb.Brew.query({ where: { status: { equals: "PLAN" } }, orderBy: { start_brew: "desc" } }));
  const [activeBrews, activeBrewsError] = useVDB(vdb.Brew.query({ where: { status: { equals: "ACTIVE" } }, orderBy: { start_brew: "desc" } }));
  const [allRecipes, errorAllRecipes] = useVDB(vdb.Recipe.query({}));
  const [fermTanks, errorAllFTanks] = useVDB(vdb.FermentationTank.query({}));


  // const [activeBrews, setActiveBrews] = useState<DBBrew[] | null>(null);
  // const [plannedBrews, setPlannedBrews] = useState<DBBrew[] | null>(null);

  const [currentAccordian, setCurrentAccordian] = useSessState('pp:BrewHome:currentAccordian', "-1");
  const accordianChangeHandler = useMemo(() => (event: React.SyntheticEvent<Element, Event>, open: boolean) => {
    const data = event.currentTarget.getAttribute('data-value');
    if (data != null)
      setCurrentAccordian((prev) => {
        if (prev == data)
          return "-1";
        else
          return data;
      })
  }, [setCurrentAccordian]);

  /*
   * Planned to Active brew 
   */
  const [activeDialogComp, setActiveDialogComp] = useState<null | DBBrew>(null);

  const handlePlannedPopoverOpen: React.MouseEventHandler<HTMLTableRowElement> = (event: React.MouseEvent<HTMLTableRowElement>) => {
    let target: Element = event.currentTarget;
    let rowid = (target) ? target.closest('[vdb-rowid]')?.getAttribute('vdb-rowid') : null;
    console.log("handlePlannedPopoverOpen", rowid);

    let selectedDBBrew = [...(activeBrews || []), ...(plannedBrews || [])].find((r) => "" + r.$id == rowid);
    if (selectedDBBrew != null)
      setActiveDialogComp(selectedDBBrew);
    // setActiveDialogComp(<DialogStartBrew dbbrewid={selectedDBBrew.$id} onDone={() => setActiveDialogComp(null)} />);
    else
      setActiveDialogComp(null);
  };

  const handlePlannedPopoverClose = (gid?: string | undefined) => {
    setActiveDialogComp(null);
    if (gid) {
      // let selectedDBBrew = [...(activeBrews || []), ...(plannedBrews || [])].find((r) => "" + r.$id == rowid);
    }
  };

  /*
   * Add New Brew
   */
  const [newBrew, setNewBrew] = useState<DBRowEdit<Brew_Fields> | undefined>();

  const handleAddBrew = useCallback(() => {
    if (newBrew) {
      let parsedDur = toNum(newBrew.get('?duration'), null);
      if (parsedDur === null) {
        alert("Please provide a valid duration");
        return;
      }
      newBrew.set('$status', "ACTIVE");
      vdb.save("New Brew from BrewHome page").then(() => {
        setNewBrew(undefined);
      });
      // props.addBrew(newBrew);
    }
  }, [newBrew, setNewBrew]);
  const handleCancel = useCallback(() => {
    if (newBrew) {
      newBrew.clearAllEdits();
    }
    setNewBrew(undefined)
  }, [newBrew, setNewBrew]);

  const onNewBrew = useCallback((event: SelectChangeEvent<string>) => {
    let recipeID = event.target.value;
    let recipe = allRecipes?.find((r) => r.$gid == recipeID);

    // Start the brew in draft mode
    const nBrew = vdb.Brew.new().edit();
    nBrew.set('$status', "DRAFT");
    nBrew.set('status', "PLAN");
    nBrew.set('recipe', recipeID);
    nBrew.set('start_brew', new Date());

    console.log("onNewBrew found recipe? ", Boolean(recipe), recipe);
    if (recipe) {
      nBrew.set('_recipe', recipe.$name);
      nBrew.set('brewduration', recipe.getE('brewduration'));
      nBrew.set('fermentduration', recipe.getE('fermentduration'));
    }

    setNewBrew(nBrew);
  }, [allRecipes, vdb]);

  /*
   * End brew 
   */
  const [activeEndBrewRow, setActiveEndBrewRow] = useState<DBBrew | null | undefined>(undefined);
  const activeEndBrewRef = useRef<Element | null>(null);

  const handleEndBrewPopup: React.MouseEventHandler<HTMLTableRowElement> = (event: React.MouseEvent<HTMLTableRowElement>) => {
    let target: Element = event.currentTarget;

    activeEndBrewRef.current = target;//event.target as Element;
    let rowid = (target) ? target.closest('[vdb-rowid]')?.getAttribute('vdb-rowid') : null;
    console.log("handleactivePopoverOpen", rowid);
    setActiveEndBrewRow([...(activeBrews || []), ...(plannedBrews || [])].find((r) => "" + r.$id == rowid));
  };

  const handleActivePopoverClose = () => { //: React.MouseEventHandler<HTMLTableRowElement>
    activeEndBrewRef.current = null;
    setActiveEndBrewRow(undefined);
  };

  // open = { activePopover }
  // anchorEl = { activePopoverEl }
  // onClose = { handleActivePopoverClose }

  // const loadData = useMemo(() => async () => {
  //   let active = await vdb.Brew.query({ where: { status: { equals: "ACTIVE" } }, orderBy: { start_brew: "desc" } }).list();
  //   setActiveBrews(active);

  //   let plan = await vdb.Brew.query({ where: { status: { equals: "PLAN" } }, orderBy: { start_brew: "desc" } }).list();
  //   setPlannedBrews(plan);
  // }, [])
  // useEffect(() => { loadData() }, []);

  return (<>

    <Dialog open={(newBrew !== undefined)} onClose={handleCancel}>
      <DialogTitle>New Brew Plan{<RowText row={newBrew} prefix=": " field="$name" />}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText> */}
        {newBrew && <NewBrewMin edit={newBrew} />}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={handleAddBrew} variant="contained">Add Brew</Button>
      </DialogActions>
    </Dialog>

    <Container style={{ minHeight: '100%' }}>
      <ErrorAlert errors={[activeBrewsError, plannedBrewsError, errorAllRecipes, errorAllFTanks]} />

      {allRecipes &&
        <Select name="Recipe" title="New Brew" variant="standard" value={""} onChange={onNewBrew} displayEmpty style={{ float: 'right' }}>
          <MenuItem value="">Add New Planned Brew</MenuItem>
          {allRecipes && allRecipes.map((r) => <MenuItem value={r.$gid} key={r.$gid}>
            <div style={{ width: '20px', height: '20px', marginRight: '10px', backgroundColor: r.get('color') }}></div>{r.$name}
          </MenuItem>)}
        </Select>
      }

      <Typography variant="h4">Brew Summary Page</Typography>

      {activeDialogComp != null &&
        <DialogStartBrew dbbrew={activeDialogComp} onDone={handlePlannedPopoverClose} />
      }
      {activeEndBrewRow != null &&
        <DialogEndBrew dbbrew={activeEndBrewRow} onDone={handleActivePopoverClose} />
      }


      {/* <Popover
        open={activePopoverRef.current != null}
        anchorEl={activePopoverRef.current}
        onClose={handleActivePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">{activePopoverRow?.$name}</Typography>
          <Divider />
          <Typography variant="button" >Actions:</Typography>
          <br />
          <Button sx={{ marginLeft: 2 }} variant='contained'>End Brew</Button>
        </Box>
      </Popover> */}

      <Accordion onChange={accordianChangeHandler} expanded={currentAccordian === "2"} sx={ACCORD_SX}>
        <AccordionSummary data-value="2" expandIcon={<GridExpandMoreIcon />} >
          <Typography variant="h5">Planned Brews:  {plannedBrews ? plannedBrews.length : '#'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell align="right">start</TableCell>
                  <TableCell align="right">brew days</TableCell>
                  <TableCell align="right">ferment days</TableCell>
                  <TableCell align="right">tank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plannedBrews && plannedBrews.map((row) => {
                  const foundRecipeColor = allRecipes?.find((r) => r.$gid == row.get('recipe'))?.get('color');
                  return (
                    <TableRow
                      key={row.$id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#e3f2fd60' } }}
                      className="expandRowLink"
                      onClick={handlePlannedPopoverOpen}
                      vdb-rowid={row.$id}
                    >
                      <TableCell component="th" scope="row">
                        <div style={{ display: 'inline-block', width: '20px', height: '20px', marginRight: '10px', backgroundColor: foundRecipeColor }}></div>
                        {row.getDisplay('recipe')}
                        {/* <Link to={`/404/${row.$id}/`} className="expandRowLink"></Link> */}
                      </TableCell>
                      <TableCell align="right"><i>{row.getDisplay('start_brew')}</i></TableCell>
                      <TableCell align="right">{row.getDisplay('brewduration')}</TableCell>
                      <TableCell align="right">{row.getDisplay('fermentduration')}</TableCell>
                      <TableCell align="right">{row.getDisplay('tank')}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion onChange={accordianChangeHandler} expanded={currentAccordian === "1"} sx={ACCORD_SX}>
        <AccordionSummary data-value="1" expandIcon={<GridExpandMoreIcon />} >
          <Typography variant="h5">Active Brews: {activeBrews ? activeBrews.length : '#'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell align="right">start</TableCell>
                  <TableCell align="right">brew days</TableCell>
                  <TableCell align="right">ferment days</TableCell>
                  <TableCell align="right">tank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeBrews && activeBrews.map((row) => {
                  const foundRecipeColor = allRecipes?.find((r) => r.$gid == row.get('recipe'))?.get('color');
                  return (
                    <TableRow
                      key={row.$id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#e3f2fd60', cursor: 'pointer' } }}
                      className="expandRowLink"
                      onClick={handleEndBrewPopup}
                      vdb-rowid={row.$id}
                    >
                      <TableCell component="th" scope="row">
                        <div style={{ display: 'inline-block', width: '20px', height: '20px', marginRight: '10px', backgroundColor: foundRecipeColor }}></div>
                        {row.getDisplay('recipe')}
                        {/* This is to make the entire row a clickable href link: <Link to={`/404/${row.$id}/`} className="expandRowLink"></Link> */}
                      </TableCell>
                      <TableCell align="right">{row.getDisplay('start_brew')}</TableCell>
                      <TableCell align="right">{row.getDisplay('brewduration')}</TableCell>
                      <TableCell align="right">{row.getDisplay('fermentduration')}</TableCell>
                      <TableCell align="right">{row.getDisplay('tank')}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Accordion onChange={accordianChangeHandler} expanded={currentAccordian === "3"} sx={ACCORD_SX}>
        <AccordionSummary data-value="3" expandIcon={<><span style={{ float: 'right', fontStyle: 'italic' }}>(dev/testing)</span><GridExpandMoreIcon /></>} >
          <Typography variant="h5">Current By Tank</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>tank</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell align="right">start</TableCell>
                  <TableCell align="right">brew days</TableCell>
                  <TableCell align="right">ferment days</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fermTanks && fermTanks.map((tank) => {
                  const activeBrew = activeBrews?.find((b) => b.get("tank") == tank.$gid);
                  return (
                    <TableRow
                      key={tank.$id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#e3f2fd60', cursor: 'pointer' } }}
                      className="expandRowLink"
                    // onClick={handleEndBrewPopup}
                    // vdb-rowid={tank.$id}
                    >
                      <TableCell>{tank.$name}</TableCell>
                      <TableCell component="th" scope="row">
                        {activeBrew && activeBrew.getDisplay('recipe')}
                        {/* This is to make the entire row a clickable href link: <Link to={`/404/${row.$id}/`} className="expandRowLink"></Link> */}
                      </TableCell>
                      <TableCell align="right">{activeBrew && activeBrew.getDisplay('start_brew')}</TableCell>
                      <TableCell align="right">{activeBrew && activeBrew.getDisplay('brewduration')}</TableCell>
                      <TableCell align="right">{activeBrew && activeBrew.getDisplay('fermentduration')}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

    </Container >
  </>);
}