import { IDBRow } from "./VaultDB";

// const userKeyIdentifier = new RegExp(/[a-zA-Z][a-zA-Z0-9-_]+/);
export const globalID = new RegExp(/^\/?(\$?[a-zA-Z][a-zA-Z0-9-_]+)(\/_?\$?[_a-zA-Z0-9-_]+)?/);
export const relativeID = new RegExp(/^(\.\/)?(_?\$?[_a-zA-Z][a-zA-Z0-9-_]+)/);

export default class Reference {
  context: 'FIELD' | 'TABLE';
  majorParts: string[];

  constructor(contextType: 'FIELD' | 'TABLE', parts: string[]) {
    this.context = contextType;
    this.majorParts = parts;
  }

  /** Field w/ no follow '>' parts */
  isLocal() {
    return (this.majorParts.length === 1)
  }

  /** Absolute has follow '>' parts */
  isChain() {
    return (this.majorParts.length > 1);
  }

  /** Absolute table/id or has follow '>' parts */
  isRemote() {
    return (this.context === 'TABLE' || this.majorParts.length > 1);
  }

  getTable(pos: number = 0) {
    if (this.majorParts && this.majorParts.length > pos)
      return this.majorParts[pos].match(globalID)![1];
  }
  getID(pos: number = 0) {
    if (this.majorParts && this.majorParts.length > pos)
      return this.majorParts[pos].match(globalID)![2];
  }
  getField(pos: number = 0) {
    if (this.majorParts && this.majorParts.length > pos)
      return this.majorParts[pos].match(relativeID)![2];
  }

  resolveLocal(row: IDBRow<any>, arrayPos?: number) {
    if (this.isLocal() === false)
      throw new Error("Reference.resolveLocal: this is not a local reference");

    const parsed = relativeID.exec(this.majorParts[0]);
    if (parsed === null)
      throw new Error("Reference.resolveLocal: unable to parsed is not a local reference, should not be possible: " + this.majorParts[0]);
    let fieldName = parsed[2];
    console.log("Ref.resolveL", this.majorParts[0], fieldName, arrayPos, row.getEither(fieldName, arrayPos));

    return row.getEither(fieldName, arrayPos);
  }

  resolveLocalMaxArrayPos(row: IDBRow<any>): number {
    if (this.isLocal() === false)
      throw new Error("Reference.resolveLocal: this is not a local reference");

    const parsed = relativeID.exec(this.majorParts[0]);
    if (parsed === null)
      throw new Error("Reference.resolveLocal: unable to parsed is not a local reference, should not be possible: " + this.majorParts[0]);
    let fieldName = parsed[2];

    return row.getLength(fieldName);
  }

  static parseAnyOrNull(ref: any): Reference | null {
    if (typeof ref === 'string')
      return this.parse(ref);
    else
      return null;
  }


  static parse(ref: string): Reference | null {
    if (ref === null || ref === undefined || typeof ref !== 'string' || ref.length <= 1)
      return null;
    if (ref[0] != '@') {
      // console.error("input doesn't start with @: ", ref);
      return null;
    }
    let parse = ref.substring(1);

    let majorParts = parse.split('>');

    let tableRef = false;

    for (let i = 0; i < majorParts.length; i++) {
      let majorPart = majorParts[i];

      if (i == 0 && majorPart.match(globalID)) {
        tableRef = true;
      }
      else if (majorPart.match(relativeID)) {

      } else {
        throw new Error("Doesn't match RegEx part[" + i + "]: " + majorPart);
      }
    }

    // console.log("Reference.parse out", new Reference((tableRef) ? 'TABLE' : 'FIELD', majorParts))
    return new Reference((tableRef) ? 'TABLE' : 'FIELD', majorParts);
  }

}
