import { Container, LinearProgress } from "@mui/material";
import { BrewSimpleEdit } from "client/components/BrewSimpleEdit";
import getdb from "client/hopiqvdb";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { DBBrew, DBBrewTable, Brew_Fields } from "shared/vdb/vdbsharedclass";

const vdb = getdb("editBrews");

// React.PropsWithChildren<{ vdb: BranchVaultDB<any, any>, table: TABLE }>

export function PlanBrewEdit(props: {}): JSX.Element {
  const { id } = useParams();
  const [row, setRow] = useState<DBRowEdit<Brew_Fields> | null>(null);

  useEffect(() => {
    if (id != undefined && Number.parseInt(id)) {
      vdb.Brew.get(Number.parseInt(id)).then((row) => {
        if (row)
          setRow(row.edit());
      })
    }
  }, [id])


  if (row !== null)
    return (<Container><BrewSimpleEdit row={row} /></Container>);
  else
    return (<LinearProgress />);
}
