import { DBRowEdit } from './DBRowEdit';
import Reference from './Reference';
import { GRaw, SchemaTable, QUERY_REQ, SAVE_SINGLE_REQ, STATUS, STATUS_STRINGS, GRawFields, GRawCalc, STR_KEY_MAP, GRawSystem, PREFIX_CALC_CHAR, GRawEither } from './types';
import * as utils from './utils';
import { VDBEditBranch } from './VDBEditBranch';
import { globalID } from './Reference';
import RefID from './RefID';
import { MultiSavePostResponse, PostSaveValue } from './APITypes';

export type ClassConstructor<T extends abstract new (...args: any) => T> = { new(...args: ConstructorParameters<T>): T };


export function isTempID(id: string | IDBRow<any> | RefID | number | undefined): boolean {
  if (typeof id === 'number') {
    return id < 0;
  } else if (typeof id === 'string') {
    let r = RefID.parse(id);
    if (r != null) return r.isTemp();
  } else if (id instanceof RefID) {
    return id.isTemp();
  } else if (typeof id === 'object' && '$gid' in id) {
    let gid = id.$gid;
    let r = RefID.parse(gid);
    if (r != null) return r.isTemp();
  }

  return false;
}

export function getGID<FIELDS extends STR_KEY_MAP>(id: string | IDBRow<FIELDS> | undefined): string | undefined {
  if (typeof id === 'string') {
    if (globalID.test(id))
      return id;
    else
      return undefined;
  }
  else if (id != null)
    return id.$gid;
  else
    return undefined;
}

export class ID {
  public readonly id: string;
  constructor(id: string) {
    this.id = id;
  }
}

export class SchemaPart<SCHEMA extends SchemaTable, FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, DBROW extends IDBRow<FIELDS>, SSCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string, DBTABLE extends DBTable<FIELDS, RAW, DBROW, SSCHEMA, TABLENAMES>> {
  schema: SCHEMA;
  // fields: FIELDS;
  dbrow: NewDBRow<FIELDS, DBROW>;
  dbtable: NewableDBTable<FIELDS, RAW, DBROW, SSCHEMA, TABLENAMES, DBTABLE>

  constructor(schema: SCHEMA, dbrow: NewDBRow<FIELDS, DBROW>, dbtable: NewableDBTable<FIELDS, RAW, DBROW, SSCHEMA, TABLENAMES, DBTABLE>) {
    this.schema = schema;
    // this.fields = fields;
    this.dbrow = dbrow;
    this.dbtable = dbtable;
  }
  table(src: IVaultDB<SSCHEMA, TABLENAMES>, schema: SchemaTable): DBTable<FIELDS, RAW, DBROW, SSCHEMA, TABLENAMES> {
    return new this.dbtable(src, schema, this.dbrow);
  }
}

export type SchemaTables<TABLENAMES extends string> = {
  [tableName in TABLENAMES]: SchemaPart<any, any, any, any, any, any, any>
}

export class Schema<TABLENAMES extends string> {
  name: string;
  tables: SchemaTables<TABLENAMES>;
  constructor(name: string, tables: SchemaTables<TABLENAMES>) {
    this.name = name;
    this.tables = tables;
  }
}


export type NewDBRow<FIELDS extends STR_KEY_MAP, T extends IDBRow<FIELDS>> = { new <FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(src: IVaultDB<SCHEMA, TABLENAMES>, schema: SchemaTable, raw: RAW): T; };
export type NewableDBTable<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string, T extends DBTable<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>> = { new(src: IVaultDB<SCHEMA, TABLENAMES>, schema: SchemaTable, DBRowConstructor: NewDBRow<FIELDS, ROW>): T; };

export abstract class DBTable<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string> {
  public readonly src: IVaultDB<SCHEMA, TABLENAMES>;
  public readonly schema: SchemaTable;
  public readonly wrapRawFunc;

  // wrapRawFunc: (row:RAW)=>ROW
  //(src:VDBSource,schema:SchemaTable,raw:RAW)=>ROW 

  constructor(src: IVaultDB<SCHEMA, TABLENAMES>, schema: SchemaTable, DBRowConstructor: NewDBRow<FIELDS, ROW>) {
    this.src = src; this.schema = schema;
    //this.wrapRawFunc=wrapRawFunc;
    this.wrapRawFunc = (raw: RAW) => { return new DBRowConstructor<FIELDS, RAW, SCHEMA, TABLENAMES>(this.src, this.schema, raw) }
  }

  // async getRAW(id: number): Promise<RAW> {
  //   return this.src.getRaw(`@${this.schema.key}/${id}`);
  // }

  async get(id: number): Promise<IDBRow<FIELDS> | null> {
    // let raw: RAW = await this.getRAW(id);
    // return this.wrapRawFunc(raw);

    // let row: IDBRow<FIELDS> | null = await this.src.get(`@${this.schema.key}/${id}`);
    // if (row != null)
    //   return row;
    // throw new Error("Row not found: " + `@${this.schema.key}/${id}`);

    return this.src.get(`@${this.schema.key}/$${id}`);;
  }

  async getByKey(key: string): Promise<IDBRow<FIELDS> | null> {
    // let raw: RAW = await this.getRAW(id);
    // return this.wrapRawFunc(raw);

    // let row: IDBRow<FIELDS> | null = await this.src.get(`@${this.schema.key}/${id}`);
    // if (row != null)
    //   return row;
    // throw new Error("Row not found: " + `@${this.schema.key}/${id}`);

    if (!key) {
      throw new Error("getByKey invalid key value passed: " + key);
    } else if (key.startsWith('$') == true) {
      throw new Error("getByKey invalid key value, must not start with '$': " + key);
    }

    let fullKey = (key.startsWith('@')) ? key : `@${this.schema.key}/${key}`;
    let refId = RefID.parse(fullKey);
    if (refId == null)
      throw new Error("Passed key unable to be parsed as valid RefID: " + fullKey);
    // This error should probably come from the RefID.parse once we have a parseOrNull varient

    return this.src.get(fullKey);;
  }

  get name() {
    return this.schema.name;
  }

  abstract new(parent?: string | IDBRow<FIELDS>): DBRow<FIELDS>;
  abstract $schema(): SchemaTable;
  abstract query(filter: any): DBQuery<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>;


  // query(filter: EquipFermentationTank_FilterType) {
  //   return new DBQuery(this, filter);
  // }
  // $schema() {
  //   return vdbschema.EquipFermentationTank;
  // }

  // get(id);
  // list(): Promise<ID[]>;
  // abstract query();

}

export class DBQuery<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string> {
  public readonly dbtable: DBTable<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>;
  public readonly filter: any;
  constructor(dbtable: DBTable<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>, filter: any) {
    this.dbtable = dbtable; this.filter = filter;
  }

  listRaw(): Promise<RAW[]> {
    return this.dbtable.src.queryRaw(this.dbtable.schema, this.filter);
  }
  async list() {
    let results = await this.listRaw();
    return results.map((r) => this.dbtable.wrapRawFunc(r));
  }

}

export interface IDBRow<FIELDS extends STR_KEY_MAP> {
  readonly $src: IVaultDB<any, any>;
  readonly $schema: SchemaTable;
  readonly $raw: Readonly<GRaw<FIELDS>>;

  get $table(): string;
  /** Auto-generated incremental id, or later UUID, CID, etc. */
  get $id(): number;
  /** Global id: table/$id */
  get $gid(): string;

  /** If $key is set: table/key, otherwise $gid() */
  get $kid(): string
  /** 0,1,3,4,8 should be valid values, see $status */;
  get $st(): STATUS;
  get $status(): STATUS_STRINGS;
  get $pv(): Date;
  get $v(): Date;
  get $key(): string | undefined;
  get $name(): string | undefined;
  get $path(): string | undefined;

  /** arrayPos defaults to -1, meaning ignore if the returned value is an array. 0 works in case the value isn't an array, 1+ is the position to get or null if not an array.  */
  get(fieldName: keyof GRaw<FIELDS> | keyof GRawEither<FIELDS>, arrayPos?: number): any;
  // getData(fieldName: keyof GRawFields<FIELDS> | keyof GRawSystem): any;
  // getCalc(fieldName: keyof GRaw<FIELDS> | keyof GRawCalc<GRawSystem>): any;

  getDisplay(fieldName: keyof GRaw<FIELDS>, arrayPos?: number): string;
  getE(fieldName: keyof GRaw<FIELDS>, arrayPos?: number): any;
  getEither(fieldName: keyof GRaw<FIELDS>, arrayPos?: number): any;
  getLength(...fieldName: (keyof GRaw<FIELDS>)[]): number;

  edit(branchName?: string): DBRowEdit<FIELDS>;
}

export function toCalcKey<FIELDS extends STR_KEY_MAP>(key: keyof FIELDS | keyof GRawSystem): keyof GRawCalc<FIELDS> | keyof GRawSystem {
  if (typeof key === 'string') {
    // Ok, so typescript should stop this case. Do we need an 'any' version that's not TS constrainted?
    if (key.startsWith(PREFIX_CALC_CHAR))
      return key as keyof GRawCalc<FIELDS> | keyof GRawSystem;
    else
      return PREFIX_CALC_CHAR + key as keyof GRawCalc<FIELDS>;
  } else {
    // This should never happen...
    return "_SHOULDBEIMPOSSIBLE";
  }
}
export function toNonCalcKey<FIELDS extends STR_KEY_MAP>(key: keyof FIELDS | keyof GRawSystem): keyof GRaw<FIELDS> | keyof GRawSystem {
  if (typeof key === 'string') {
    // Ok, so typescript should stop this case. Do we need an 'any' version that's not TS constrainted?
    if (key.startsWith(PREFIX_CALC_CHAR))
      return key.substring(1);
    else
      return key;
  } else {
    // This should never happen...
    return "_SHOULDBEIMPOSSIBLE";
  }
}
/** Optimistically returns '\_'+key unless key already starts with '\_' */
export function toAnyCalcKey(key: string): keyof GRawCalc<any> {
  if (typeof key === 'string') {
    // Ok, so typescript should stop this case. Do we need an 'any' version that's not TS constrainted?
    if (key.startsWith(PREFIX_CALC_CHAR))
      return key as keyof GRawCalc<any>;
    else
      return PREFIX_CALC_CHAR + key as keyof GRawCalc<any>;
  } else {
    // This should never happen...
    return "_SHOULDBEIMPOSSIBLE";
  }
}
/** Optimistically returns ${key}, even if key starts with '_' */
export function toAnyNonCalcKey(key: string): string {
  if (typeof key === 'string') {
    // Ok, so typescript should stop this case. Do we need an 'any' version that's not TS constrainted?
    if (key.startsWith(PREFIX_CALC_CHAR))
      return key.substring(1);
    else
      return key;
  } else {
    // This should never happen...
    return "_SHOULDBEIMPOSSIBLE";
  }
}

export class DBRow<FIELDS extends STR_KEY_MAP> implements IDBRow<FIELDS>{
  public readonly $src: IVaultDB<any, any>;
  public readonly $schema: SchemaTable;
  public readonly $raw: GRaw<FIELDS>;

  constructor(src: IVaultDB<any, any>, schema: SchemaTable, raw: GRaw<FIELDS>) {
    this.$src = src; this.$schema = schema; this.$raw = raw;
    Object.freeze(raw);
  }
  get(fieldName: (keyof GRaw<FIELDS> | keyof GRawEither<FIELDS>) & string, arrayPos: number = -1) {
    //<KEY extends keyof GRaw<FIELDS>>
    //: FIELDS[KEY]
    if (fieldName === "$gid") return this.$gid;
    if (fieldName === "$kid") return this.$kid;
    if (fieldName === "$status") return this.$status;

    if (fieldName && fieldName.startsWith('?'))
      return utils.getArrayPos(this.getEither(fieldName.substring(1)), arrayPos);
    else
      return utils.getArrayPos(this.$raw[fieldName], arrayPos);
  }
  // getData(fieldName: keyof GRawFields<FIELDS>) {
  //   return this.$raw[fieldName];
  // }
  // getCalc(fieldName: keyof GRawCalc<FIELDS>) {
  //   return this.$raw[fieldName];
  // }
  getDisplay(fieldName: keyof GRawFields<FIELDS>, arrayPos: number = -1): string {
    return utils.getRowDisplay(this, fieldName, arrayPos);
  }
  getE(fieldName: string) { return this.getEither(fieldName); }
  getEither(fieldName: keyof FIELDS & string): any {
    const isFieldNameCalc = (typeof fieldName === 'string' && fieldName.startsWith('_'))

    if (typeof fieldName === 'string' && (fieldName.startsWith('?') || fieldName.startsWith('_')))
      fieldName = fieldName.substring(1);

    if (fieldName === "$gid") return this.$gid;
    if (fieldName === "$kid") return this.$kid;
    if (fieldName === "$status") return this.$status;

    if (isFieldNameCalc)
      return utils.firstNotUndefined(this.$raw[toCalcKey(fieldName)], this.$raw[fieldName]);
    else
      return utils.firstNotUndefined(this.$raw[fieldName], this.$raw[toCalcKey(fieldName)]);
  }
  getLength(...fieldName: (keyof GRaw<FIELDS> & string)[]): number {
    const lengths = fieldName.map((f) => utils.getArrayLength(this.get(f)));
    return Math.max(...lengths);
  }

  get $table() { return this.$raw.$table; }
  get $id() { return this.$raw.$id; }
  get $gid() { return utils.$IRowProcessGID(this.$table, this.$id) }
  get $kid() { return utils.$IRowProcessKID(this.$table, this.$key, this.$id) }


  get $st() { return this.$raw.$st; }
  get $status() { return utils.$IRowProcessSTATUS(this.$raw.$st) } //: "DELETED"|"NEW"|"DRAFT"|"ACTIVE"|"UNKNOWN"
  get $pv() { return this.$raw.$pv; }
  get $v() { return this.$raw.$v; }
  get $key() { return this.$raw.$key; }
  get $name() { return utils.$IRowProcessNAME(this.$raw) }
  get $path() { return this.$raw.$path; }

  edit(branchName?: string | BranchVaultDB<Schema<any>, any>, tempBranch?: boolean): DBRowEdit<FIELDS> {
    // This could be an issue in case the given branch has a different 'base' dbrow
    if (branchName instanceof BranchVaultDB) {
      return branchName._wrapEdit(this);
    }
    else if (this.$src instanceof RootVaultDB) {
      if (branchName === undefined)
        throw new Error("DBRow not attached to branch, MUST specify branchname");

      return this.$src.branch(branchName, tempBranch)._wrapEdit(this);
    } else if (this.$src instanceof BranchVaultDB) {
      if (branchName !== undefined)
        throw new Error("DBRow attached to branch, must NOT specify branchname");

      return this.$src._wrapEdit(this);
    }
    throw new Error("SNH - VaultDB.ts parent not root or branch");
  }

}

let GLOBAL_TEMP_COUNTER = -1;
export function NEXT_TEMP_ID() {
  return GLOBAL_TEMP_COUNTER--;
}


export abstract class RAWSource {
  static getNextTempID() { return NEXT_TEMP_ID(); }
  abstract getRaw<RAWTYPE>(gid: string): Promise<RAWTYPE>;
  abstract queryRaw<RAWTYPE>(table: SchemaTable, filter: any): Promise<RAWTYPE[]>;

  abstract saveRaw<RAWTYPE extends STR_KEY_MAP>(raw: GRaw<RAWTYPE>, merged: GRaw<RAWTYPE>, edits: Partial<GRaw<RAWTYPE>>): Promise<RAWTYPE[]>;

  abstract saveMultiRaw<RAWTYPE>(title: string, toSave: DBRowEdit<any>[]): Promise<MultiSavePostResponse>;
}

export class NetRAWSource extends RAWSource {

  serverPrefix = "";
  onAuthenticationError;

  constructor(onAuthenticationError?: () => void) {
    super();
    this.onAuthenticationError = onAuthenticationError;
  }

  async getRaw<RAWTYPE>(gid: string): Promise<RAWTYPE> {
    const ref = RefID.parse(gid);

    // const ref = Reference.parse(gid);
    if (ref == null) { //|| ref.isChain()
      throw new Error("Unable to parse gid, or gid is not TABLE type, or does not start with @: " + gid);
    }
    // const [tableKey, id] = ref.majorParts;
    const tableKey = ref.getTable();
    const id = (ref.hasID()) ? "$" + ref.getID() : ref.getKey();

    console.warn(`URL: ${this.serverPrefix}/api/rest/${tableKey}/${id}?d=GET/${tableKey}/${id}`);
    const resultPromise = fetch(`${this.serverPrefix}/api/rest/${tableKey}/${id}?d=GET/${tableKey}/${id}`);
    const result = await resultPromise;
    console.log("result", result);

    if (result.status == 401 && this.onAuthenticationError) {
      this.onAuthenticationError();
    }
    if (result.status !== 200) {
      throw new Error("Returned code " + result.status + ": " + result.statusText + "\n\nBody: " + (await result.text()));
    }

    return result.json().then((rawResult) => {
      console.log("getRaw server response: ", rawResult);

      return rawResult;
    }).catch((errorResult) => {
      console.log("errorResult", errorResult);

      if (errorResult instanceof Error) {
        console.error("Server call returned: ", errorResult.message, errorResult.stack);
        throw errorResult;
        // throw new Error("Returned code " + result.status + "/" + result.statusText + ":" + errorResult.message);
      }
      else
        throw new Error("ERROR but not Error: Returned code " + result.status + "/" + result.statusText + ":" + errorResult.message);
      // setPrismaResult(JSON.stringify(errorResult, null, 2));
    });
  }

  async queryRaw<RAWTYPE>(table: SchemaTable, filter: any): Promise<RAWTYPE[]> {
    // return [] as RAWTYPE[];

    const result = await fetch(
      `${this.serverPrefix}/api/rest/${table.key}/query?d=POST/${table.key}/query`,
      {
        method: 'POST', //headers: { "Content-Type": 'application/json' }, 
        body: JSON.stringify({ filter: filter } as QUERY_REQ)
      }
    );
    // console.log("result", result);
    if (result.status == 401 && this.onAuthenticationError) {
      this.onAuthenticationError();
    }

    let response: { result: RAWTYPE[] } = await result.json();
    console.log("VDB.Query() Returned code " + result.status + ": " + result.statusText + "\n\nBody: " + (response));
    return response.result;
  }

  async saveRaw<RAWTYPE extends STR_KEY_MAP>(raw: GRaw<RAWTYPE>, merged: GRaw<RAWTYPE>, edits: Partial<GRaw<RAWTYPE>>): Promise<RAWTYPE[]> {
    // return [] as RAWTYPE[];
    const table = raw.$table;
    const id = raw.$id;

    const result = await fetch(
      `${this.serverPrefix}/api/rest/${table}/${id}?d=POST/SAVE/${table}/${id}`,
      {
        method: 'POST', //headers: { "Content-Type": 'application/json' }, 
        body: JSON.stringify({ merged, edits } as SAVE_SINGLE_REQ<RAWTYPE>)
      }
    );
    // console.log("result", result);
    if (result.status == 401 && this.onAuthenticationError) {
      this.onAuthenticationError();
    }

    // I think response type should be: { result: GRaw<any> }
    let response: RAWTYPE[] = await result.json();
    console.log("VDB.saveRaw() Returned code " + result.status + ": " + result.statusText + "\n\nBody: " + (response));

    return response;
  }


  async saveMultiRaw(title: string, toSave: DBRowEdit<any>[]): Promise<MultiSavePostResponse> {

    let saveValues = toSave.map((edit) => { return { gid: edit.$gid, prevVersion: edit.$raw.$v, edits: edit.edits } })

    let postSaveValue: PostSaveValue = {
      title: title,
      data: saveValues,
    }

    const result = await fetch(
      `${this.serverPrefix}/api/save/`,
      {
        method: 'POST', //headers: { "Content-Type": 'application/json' }, 
        body: JSON.stringify(postSaveValue)
      }
    );
    // console.log("result", result);
    if (result.status == 401 && this.onAuthenticationError) {
      this.onAuthenticationError();
    }

    let response: MultiSavePostResponse = await result.json();
    console.log("VDB.saveMultiRaw() Returned code " + result.status + ": " + result.statusText + "\n\nBody: " + JSON.stringify(response));

    // VDB.Query() Returned code 500: Internal Server Error
    // Body: {"error":"\nInvalid `tablePrisma.create()` invocation in\n/home/jason/neon/svn-odp/reactapp/hopiq3/build/server.js:309:51\n\n  306 // @ts-ignore\n  307 let newRowPrisma = _shared_vdb_utils__WEBPACK_IMPORTED_MODULE_3__.convertRawToPrisma(newRow);\n  308 // @ts-ignore\n→ 309 let createRsp = await tablePrisma.create(\nUnique constraint failed on the fields: (`s_key`)","errorname":"Error"}

    return response;
  }

}


// const VDB_BACKEND_CONST: { [key: string]: VDBEditBranch } = { "__DEFAULT_BRANCH__": new VDBEditBranch("__DEFAULT_BRANCH__") }

export class VaultDB {

  //   src: RAWSource;
  //   config = "__DEFAULT_CONFIG__"
  //   branch = "__DEFAULT_BRANCH__";

  //   constructor(src: RAWSource) {
  //     this.src = src;
  //   }

  //   editBranch() { return VDB_BACKEND_CONST[this.branch] }

  //   wrapEdit<FIELDS>(row: DBRow<FIELDS>) {
  //     let edit = new DBRowEdit(row);
  //     if (this.editBranch()) {
  //       let fullID = edit.$gid;
  //       this.editBranch().edits[fullID] = edit;
  //     }
  //     return edit;
  //   }

  //   // getStatus(): DATA_SOURCE_STATUS;
  //   // name?: null | string;
  //   // getUser(): Promise<db.UserX>; //IThenable<User> 
  //   // getAccountDTO(): Promise<db.SchemaX>; //IThenable<Schema> 
  //   // save(entity: db.DBEVersionedX<any>[]): Promise<any>;//SaveResp>; //IThenable<SaveResp> 
  //   // query(jsQuery: vaultdb_api_query.QueryX, withSumms?: boolean): any;//IThenable<ListResp> 
  //   // searchKeys(table: DBIDX<db.TableX>, keyPrefix?: string): any; //IThenable<KeyResp> 
  //   // get<T extends db.DBEVersionedX<T>, I extends DBIDX<T>>(id: I): Promise<T>; //<R extends DBEVersioned<R>> IThenable<R> 
  //   // getVersion<T extends db.DBEVersionedX<T>, I extends DBIDX<T>>(id: I, version?: DBIDX<db.VersionX>): Promise<T>; //<R extends DBEVersioned<R>> IThenable<R> 
  //   // getVersions(id: DBIDX<any>): Promise<DBIDX<db.VersionX>[]>; //IThenable<DBID<Version>[]> 
  //   // getByKey(type: DBTYPES, parentID: DBIDX<db.TableX>, key: string): Promise<db.RowX>;//IThenable<DBEVersioned>  DBID <? extends DBEVPrimary > 
  //   // list(type: DBTYPES, parentID: DBIDX<db.TableX>, status: STATUS_NAMES, withSumms?: boolean): Promise<vaultdb_api_resp.ListRespX>;//IThenable<ListResp> 
  //   // summList(parentID: DBIDX<db.TableX>, summFilterStart?: string, isPrefix?: boolean): any;//IThenable<ListResp> 
}
export default VaultDB;


export interface IVaultDB<SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string> {
  isBranch(): boolean;

  schema(): SCHEMA;
  // getRaw<FIELDS>(gid: string, version?: any): Promise<GRaw<FIELDS> | null>;
  get<FIELDS extends STR_KEY_MAP>(gid: string, version?: any): Promise<IDBRow<FIELDS> | null>;
  query<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>>(table: string, filter: any): DBQuery<FIELDS, RAW, ROW, SCHEMA, TABLENAMES> | null;

  table(name: TABLENAMES | string): DBTable<any, any, any, SCHEMA, TABLENAMES> | null;

  queryRaw<RAWTYPE>(table: SchemaTable, filter: any): Promise<RAWTYPE[]>;
}


export abstract class BranchVaultDB<SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string> implements IVaultDB<SCHEMA, TABLENAMES> {
  src: RootVaultDB<SCHEMA, TABLENAMES, BranchVaultDB<SCHEMA, TABLENAMES>>;
  vdbEditBranch: VDBEditBranch;

  constructor(src: RootVaultDB<SCHEMA, TABLENAMES, BranchVaultDB<SCHEMA, TABLENAMES>>, branchName: string) {
    this.src = src;
    this.vdbEditBranch = new VDBEditBranch(branchName);
  }

  isBranch() { return true; }

  schema(): SCHEMA {
    return this.src.schema();
  }
  async get<RAW, FIELDS extends STR_KEY_MAP>(gid: string, version?: any): Promise<IDBRow<FIELDS> | null> {
    if (this.vdbEditBranch.edits[gid]) {
      // Because the key contains table, this should be a safe cast
      let rowEdit: DBRowEdit<FIELDS> = this.vdbEditBranch.edits[gid];
      return Promise.resolve(rowEdit);
    }

    const raw: GRaw<FIELDS> = await this.src.getRaw(gid); //, version

    let table = RefID.parse(gid)?.getTable();
    if (raw != null && table && this.table(table)) {
      let found = this.table(table)?.wrapRawFunc(raw);
      return found || null;
    }
    return null;
  }

  table(name: TABLENAMES | string): DBTable<any, any, any, SCHEMA, TABLENAMES> | null {
    // const ref = Reference.parse('@/'+name);
    // if (ref != null && ref.isRemote())
    //   name = ref.majorParts[0] as TABLENAMES;

    let castName = name as TABLENAMES;

    // Attempt to find sub-class field of table name first

    // @ts-ignore
    if (castName in this && this[castName] instanceof DBTable) {
      // @ts-ignore
      // console.log("Using the this[castname] method: " + castName);
      // @ts-ignore
      return this[castName];
    }

    const tableSchemaPart = this.src._schema.tables[castName];
    if (tableSchemaPart)
      return tableSchemaPart.table(this, tableSchemaPart.schema)
    else
      return null;
  }

  //<FIELDS, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>>
  query(table: string, filter: any): DBQuery<any, any, any, SCHEMA, TABLENAMES> | null {
    // : DBQuery<FIELDS, RAW, ROW, SCHEMA, TABLENAMES> | null
    return this.src.query(table, filter);
  }
  queryRaw<RAWTYPE>(table: SchemaTable, filter: any): Promise<RAWTYPE[]> {
    return this.src.queryRaw(table, filter);
  }

  waitAsyncDone() {
    const edits = Object.values(this.vdbEditBranch.edits);
    return Promise.all(edits.map((e) => e.waitAsyncDone()));
  }

  async save(title: string, includeCalc: boolean = false) { //, includeCalc?: boolean
    let rebaseProcesses: Promise<any>[] = [];

    // Make sure all async processes have settled (calcs, etc)
    await this.waitAsyncDone();

    // Collect only the rows that have changed
    const changedRows = Object.values(this.vdbEditBranch.edits).filter((e) => e.hasChange(includeCalc));
    if (changedRows.length === 0) {
      console.warn("BranchVauldDB.save() called with no changes, immediate return");
      return Promise.resolve();
    }

    // Save rows, wait for server response
    const response: MultiSavePostResponse = await this.src.saveMultiRaw(title, changedRows);
    for (let [id, row] of Object.entries(response.saved)) {
      // id2 is the previous temporary id before save. We will search for both just in case, 
      // thou it should be possible for id2 != null but for us to find something under id
      let foundNewIDMapping = Object.entries(response.idmap).find(([tempID, newID]) => id == newID);
      let foundTempIDMapping = (foundNewIDMapping !== undefined) ? foundNewIDMapping[0] : null;

      // Rebase Row, second branch handles New row (TempID) -> ServerSavedID
      if (this.vdbEditBranch.edits[id])
        rebaseProcesses.push(this.vdbEditBranch.edits[id].rebase(response.idmap, row));
      else if (foundTempIDMapping !== null && this.vdbEditBranch.edits[foundTempIDMapping])
        rebaseProcesses.push(this.vdbEditBranch.edits[foundTempIDMapping].rebase(response.idmap, row));
      // TODO: Don't we want to change its edits[key] to use the new real id instead of the temp id???
      else
        console.error("multiSave - couldn't find to rebase: ", id, row, this.vdbEditBranch.edits);
    }

    await Promise.allSettled(rebaseProcesses);
    return response.idmap;
  }

  // VDBEditBranch forwards
  name() { return this.vdbEditBranch.name; }
  hasChange(includeCalc = true) { return this.vdbEditBranch.hasChange(includeCalc); }
  isLocked() { return this.vdbEditBranch.isLocked(); }
  isValdating() { return this.vdbEditBranch.isValdating(); }
  hasError() { return this.vdbEditBranch.hasError(); }
  hasWarning() { return this.vdbEditBranch.hasWarning(); }

  _wrapEdit<FIELDS extends STR_KEY_MAP>(row: DBRow<FIELDS>) {
    let fullID = row.$gid;

    if (this.vdbEditBranch.edits[fullID]) {
      return this.vdbEditBranch.edits[fullID];
    } else {
      let edit = new DBRowEdit(row);
      this.vdbEditBranch.edits[fullID] = edit;
      return edit;
    }
  }

}

export abstract class RootVaultDB<SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string, BRANCH extends BranchVaultDB<SCHEMA, TABLENAMES>> implements IVaultDB<SCHEMA, TABLENAMES>, RAWSource {

  _schema: SCHEMA;
  _src: RAWSource;
  // _cache: any;
  _branchGenerator;
  branches: { [key: string]: BRANCH } = {}

  constructor(schema: SCHEMA, src: RAWSource, branchGenerator: (name: string) => BRANCH) {
    this._schema = schema;
    this._src = src;
    this._branchGenerator = branchGenerator;
  }

  isBranch() { return false; }

  branch(name: string, isTemp?: boolean): BRANCH {
    if (isTemp)
      return this._branchGenerator(name);

    if (!this.branches[name]) {
      this.branches[name] = this._branchGenerator(name);
    }
    return this.branches[name];
  }

  schema() { return this._schema };
  table(name: TABLENAMES | string): DBTable<any, any, any, SCHEMA, TABLENAMES> | null {
    const ref = Reference.parse(name);
    if (ref != null && ref.isRemote())
      name = ref.majorParts[0] as TABLENAMES;

    let castName = name as TABLENAMES;

    const tableSchemaPart = this._schema.tables[castName];
    if (tableSchemaPart)
      return tableSchemaPart.table(this, tableSchemaPart.schema)
    else
      return null;
  }

  getRaw<RAWTYPE>(gid: string) { return this._src.getRaw<RAWTYPE>(gid) };
  queryRaw<RAWTYPE>(table: SchemaTable, filter: any) { return this._src.queryRaw<RAWTYPE>(table, filter) };
  saveRaw<FIELDS extends STR_KEY_MAP>(raw: GRaw<FIELDS>, merged: GRaw<FIELDS>, edits: Partial<GRaw<FIELDS>>) {
    let respPromise = this._src.saveRaw(raw, merged, edits);
    // TODO: For new, this will probably not work. Actually, how do we handle a rebase on a new?
    respPromise.then((rsp) => { this._notifyRebase(Object.keys(utils.$IRowProcessGID(merged.$table, merged.$id))) });
    return respPromise;
  };
  saveMultiRaw(title: string, toSave: DBRowEdit<any>[]) {
    let respPromise = this._src.saveMultiRaw(title, toSave);
    // TODO: For new, this will probably not work. Actually, how do we handle a rebase on a new?
    respPromise.then((rsp) => { this._notifyRebase(Object.keys(rsp.saved)) });
    return respPromise;
  };

  _notifyRebase(gids: string[]) {
    for (let b of Object.values(this.branches))
      for (let gid of gids)
        b.vdbEditBranch.dispatch('rebase', gid);
  }

  async get<RAW extends GRaw<FIELDS>, FIELDS extends STR_KEY_MAP>(gid: string, version?: any): Promise<IDBRow<FIELDS> | null> {
    const raw: RAW = await this.getRaw<RAW>(gid);

    let table = RefID.parse(gid)?.getTable();
    if (table && this.table(table)) {
      let found = this.table(table)?.wrapRawFunc(raw);
      return found || null;
    }

    return null;
    // return this.table(gid)?.get()  wrapRawFunc(raw)
  };
  query(table: string, filter: any): DBQuery<any, any, any, SCHEMA, TABLENAMES> | null {
    // let dbtable: DBTable<FIELDS, RAW, ROW, SCHEMA, TABLENAMES> | null = this.table(table);
    // if (dbtable) {
    //   return dbtable.query(filter);
    // }
    // return null;

    let dbtable = this.table(table);
    if (dbtable) {
      let query: DBQuery<any, any, any, SCHEMA, TABLENAMES> = dbtable.query(filter)
      return query;
      // this._src.queryRaw(dbtable.$schema(), filter);
    }
    return null;

    // return this.table(table)?.query(filter) || null;
  }

}




