import { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Container, SxProps, Tab, Tabs, Theme, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

import GenericBrowseEdit from "../../../client/components/GenericBrowseEdit";
import SimpleBrews from "../setup/OldPages/SimpleBrews";


import { GridExpandMoreIcon } from "@mui/x-data-grid";

import { DBBrew } from "shared/vdb/vdbsharedclass";
import { Link } from "react-router-dom";
import useVDB from "../../../client/vdb/useVDB";
import ErrorAlert from "client/vdb/ErrorAlert";

import getdb from 'client/hopiqvdb';
const vdb = getdb("genericBrowse");

const ACCORD_SX: SxProps<Theme> = { backgroundColor: '#f6fbff', ":hover": { backgroundColor: '#e3f2fd' } };

export default function PlanList() {

  // const [activeBrews, setActiveBrews] = useState<DBBrew[] | null>(null);
  // const [plannedBrews, setPlannedBrews] = useState<DBBrew[] | null>(null);

  const [activeBrews, error1] = useVDB(vdb.Brew.query({ where: { status: { equals: "ACTIVE" } }, orderBy: { start_brew: "desc" } }));
  const [plannedBrews, error2] = useVDB(vdb.Brew.query({ where: { status: { equals: "PLAN" } }, orderBy: { start_brew: "desc" } }));

  const [currentAccordian, setCurrentAccordian] = useState<string>("-1");
  const accordianChangeHandler = useMemo(() => (event: React.SyntheticEvent<Element, Event>, open: boolean) => {
    const data = event.currentTarget.getAttribute('data-value');
    if (data != null)
      setCurrentAccordian((prev) => {
        return (prev == data) ? "-1" : data;
      })
  }, [setCurrentAccordian]);

  // const loadData = useMemo(() => async () => {
  //   let active = await vdb.Brew.query({ where: { status: { equals: "ACTIVE" } }, orderBy: { start_brew: "desc" } }).list();
  //   setActiveBrews(active);

  //   let plan = await vdb.Brew.query({ where: { status: { equals: "PLAN" } }, orderBy: { start_brew: "desc" } }).list();
  //   setPlannedBrews(plan);
  // }, [])
  // useEffect(() => { loadData() }, []);

  return (<>
    <Container style={{ minHeight: '100%' }}>
      <ErrorAlert errors={[error1, error2]} />
      <Typography variant="h4">Plan Summary Page - {JSON.stringify(error1)} - {JSON.stringify(error2)}</Typography>


      <Accordion onChange={accordianChangeHandler} expanded={currentAccordian === "1"} sx={ACCORD_SX}>
        <AccordionSummary data-value="1" expandIcon={<GridExpandMoreIcon />} >
          <Typography variant="h5">Active Brews: {activeBrews ? activeBrews.length : '#'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell align="right">start</TableCell>
                  <TableCell align="right">brew days</TableCell>
                  <TableCell align="right">ferment days</TableCell>
                  <TableCell align="right">tank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeBrews && activeBrews.map((row) => (
                  <TableRow
                    key={row.$id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#e3f2fd60' } }}
                    className="expandRowLink"
                  >
                    <TableCell component="th" scope="row">
                      {row.getDisplay('recipe')}
                      <Link to={`/404/${row.$id}/`} className="expandRowLink"></Link>
                    </TableCell>
                    <TableCell align="right">{row.getDisplay('start_brew')}</TableCell>
                    <TableCell align="right">{row.getDisplay('brewduration')}</TableCell>
                    <TableCell align="right">{row.getDisplay('fermentduration')}</TableCell>
                    <TableCell align="right">{row.getDisplay('tank')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>


      <Accordion onChange={accordianChangeHandler} expanded={currentAccordian === "2"} sx={ACCORD_SX}>
        <AccordionSummary data-value="2" expandIcon={<GridExpandMoreIcon />} >
          <Typography variant="h5">Planned Brews:  {plannedBrews ? plannedBrews.length : '#'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell align="right">start</TableCell>
                  <TableCell align="right">brew days</TableCell>
                  <TableCell align="right">ferment days</TableCell>
                  <TableCell align="right">tank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plannedBrews && plannedBrews.map((row) => (
                  <TableRow
                    key={row.$id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#e3f2fd60' } }}
                    className="expandRowLink"
                  >
                    <TableCell component="th" scope="row">
                      {row.getDisplay('recipe')}
                      <Link to={`/404/${row.$id}/`} className="expandRowLink"></Link>
                    </TableCell>
                    <TableCell align="right">{row.getDisplay('start_brew')}</TableCell>
                    <TableCell align="right">{row.getDisplay('brewduration')}</TableCell>
                    <TableCell align="right">{row.getDisplay('fermentduration')}</TableCell>
                    <TableCell align="right">{row.getDisplay('tank')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

    </Container >
  </>);
}