import { Container, LinearProgress } from "@mui/material";
import { BrewSimpleEdit } from "client/components/BrewSimpleEdit";
import getdb from "client/hopiqvdb";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { DBBrew, DBBrewTable, Brew_Fields } from "shared/vdb/vdbsharedclass";

const vdb = getdb("editBrews");

// React.PropsWithChildren<{ vdb: BranchVaultDB<any, any>, table: TABLE }>

export function PlanGraphEdit(props: {}): JSX.Element {
  const { editid } = useParams();
  const [row, setRow] = useState<DBRowEdit<Brew_Fields> | null>(null);

  useEffect(() => {
    if (editid != undefined && Number.parseInt(editid)) {
      vdb.Brew.get(Number.parseInt(editid)).then((row) => {
        if (row)
          setRow(row.edit());
      })
    }
  }, [editid])


  if (row !== null)
    return (<Container><BrewSimpleEdit row={row} /></Container>);
  else
    return (<><LinearProgress /></>);
}
