import { useCallback, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import ChartTasks from "../../../client/charttasks/ChartTasks";
import { GroupData, TaskData } from "client/charttasks";

import { dateFns, localeCompare, toDate, toDateOr, toDayStr, toNum } from "shared/vdb/utils";

import getdb from 'client/hopiqvdb';
import useVDB from "../../../client/vdb/useVDB";
import SaveBar from "./SaveBar";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { Brew_Fields, DBBrew, DBFermentationTank } from "shared/vdb/vdbsharedclass";
import date_utils from "client/charttasks/date_utils";

import PlanGraphChartHeader from "./PlanGraph.ChartHeader";
import PlanGraphBottomDrawer from "./PlanGraph.BottomDrawer";
import { STATUS } from "shared/vdb/types";
import { Check as StatusPlan, PublishedWithChanges as StatusActive, CheckBoxOutlineBlank as StatusDone } from '@mui/icons-material';
import { ITask } from "client/charttasks/Task";
import { Route, Routes } from "react-router-dom";
import { PlanGraphEdit } from "./PlanGraphEdit";

const vdb = getdb("editBrews");

export type TaskDataRef = TaskData & { ref: DBRowEdit<Brew_Fields> }
export type GroupDataRef = GroupData & { key?: string | number, sref: DBFermentationTank | null }

const brewToTaskDataRef = (bs: DBBrew): TaskDataRef[] => {
  let out: TaskDataRef[] = [];
  let edit = bs.edit();

  let StatusIcon = (bs.get('status') == "PLAN") ? StatusDone : (bs.get('status') == "ACTIVE") ? StatusActive : StatusPlan;

  let endBrewDate: Date | null = null;

  const startBrew = bs.get("?start_brew");
  const brewduration = toNum(bs.get("?brewduration"), 1);

  const startDate = toDateOr(startBrew, null);
  if (startDate != null) {
    if (Number.isFinite(brewduration)) {
      endBrewDate = dateFns.addDays(startDate, brewduration);
    } else {
      endBrewDate = dateFns.addDays(startDate, 1);
    }
  }

  if (startDate === null || endBrewDate === null) {
    return [];
  }


  let brewtask: TaskDataRef = {
    id: bs.$gid + "|brew",
    gid: bs.$gid,
    name: "",//(bs.$name || bs.$key),
    start: startDate,
    end: endBrewDate,
    progress: 0,
    dependencies: [],
    groupID: "brew",
    ref: edit,
    canMoveStart: true,
    canMoveGroup: false,
    canResize: false,
    rowsHeight: (toNum(bs.get('?amount'), 1) > 7 || brewduration > 1) ? 2 : 1
  }
  if (brewtask.start instanceof Date === false || Number.isNaN(brewtask.start.valueOf()))
    return [];
  if (brewtask.end instanceof Date === false || Number.isNaN(brewtask.end.valueOf()))
    return [];
  out.push(brewtask);

  // if (toNum(bs.get('?amount'), 1) > 7 || brewduration > 1) {
  //   let brewtask2 = Object.assign({}, brewtask);
  //   brewtask2.id = bs.$gid + "|brew2";
  //   out.push(brewtask2);
  // }

  // TODO: Multiple fermentations
  let prevEndDate = endBrewDate;
  let prevBarID = bs.$gid + "|brew"

  for (let arrayPos = 0; arrayPos < bs.getLength("fermentduration", "tank"); arrayPos++) {
    const fermentduration = toNum(bs.get("?fermentduration", arrayPos), null);
    let tank = bs.get('tank', arrayPos)
    if (fermentduration) {
      let startFermentDate = prevEndDate;
      // +1 b/c it takes the entire end day, otherwise it'd end at the start
      let endFermentDate = dateFns.addDays(startFermentDate, fermentduration);

      let thisID = bs.$gid + "|ferment|" + arrayPos;

      console.log("BadFermentDur: ", bs.$name, fermentduration, arrayPos, startBrew, toDayStr(startDate), toDayStr(endBrewDate), toDayStr(startFermentDate), toDayStr(endFermentDate));
      let fermtask: TaskDataRef = {
        id: thisID,
        gid: bs.$gid,
        name: (bs.$name || bs.$key),
        start: startFermentDate,
        end: endFermentDate,
        progress: 0,
        dependencies: [prevBarID],
        groupID: "" + (tank || ""),
        ref: edit,
        statusIcon: StatusIcon,
        canMoveStart: false,
        // If PLAN, allow drag. ACTIVE or DONE shouldn't allow to be dragged.
        canMoveGroup: edit.get('status') == "PLAN" ? true : false,
        // Here, plan or active can be resized.
        canResize: edit.get('status') == "PLAN" || edit.get('status') == "ACTIVE" ? true : false,
      }
      if (fermtask.start instanceof Date === false || Number.isNaN(fermtask.start.valueOf()))
        return [];
      if (fermtask.end instanceof Date === false || Number.isNaN(fermtask.end.valueOf()))
        return [];
      out.push(fermtask);

      // For the next bar if multiple ferments
      prevEndDate = endFermentDate;
      prevBarID = thisID;
    }
  }

  return out;
}

const processTasks = (colorMode: "status" | "beer", colorCache: Record<string, string>, tasks: TaskDataRef[]) => {
  for (let task of tasks) {
    if (colorMode === "status") {
      let status = task.ref.get('status');
      task.color = (status === "PLAN") ? "#FF9000" : (status === "ACTIVE") ? "#90FF90" : (status === "DONE") ? "#009F9F" : undefined;
    } else if (colorMode === "beer") {
      // If status is deleted, color it RED
      if (task.ref.$status === 'DELETED') {
        task.color = '#FF0000';
      } else {
        let key = task.ref.get('recipe');
        if (typeof key === 'string')
          task.color = colorCache[key];
      }
    }

  }
  return tasks;
}

/** Create string from values we care about. Used to compare if something changed or not */
const taskValueHash = (changed: TaskDataRef) => {
  // Use this to compare against prev values, only .set if something has changed
  let startBrew = changed.start.toISOString().split('T')[0];
  let duration = date_utils.diff(changed.end, changed.start, 'day');
  let tank = changed.groupID;

  // combined to test against
  return [startBrew, duration, tank].toString();
}

const EMPTY_GROUP = { id: "", name: "", sref: null };
const BREW_GROUP = { id: "brew", name: "Brew", sref: null };

export default function PlanGraph() {

  const [allBrews, error1] = useVDB(vdb.Brew.query({ where: { s_st: STATUS.ACTIVE }, orderBy: { start_brew: "desc" } }));
  const [allRecipes, error2] = useVDB(vdb.Recipe.query({}));
  const [allFTanks, error3] = useVDB(vdb.FermentationTank.query({}));

  const [tasks, setTasks] = useState<TaskDataRef[]>([]);
  const [colorCache, setColorCache] = useState<Record<string, string>>({});
  const [groups, setGroups] = useState<GroupDataRef[]>([
    BREW_GROUP, EMPTY_GROUP
    // { id: "t", name: "Unassigned" },
    // { id: "t1", name: "T1 7bbl" },
    // { id: "t2", name: "T2 7bbl" },
    // { id: "t3", name: "T3 15bbl" },
    // { id: "t4", name: "T4 7bbl" },
    // { id: "t5", name: "T5 15bbl" },
    // { id: "t6", name: "T6 15bbl" },
    // { id: "t7", name: "T7 7bbl" },
    // { id: "t8", name: "T8 15bbl" },
    // { id: "t9", name: "T9 15bbl" },
    // { id: "t10", name: "T10 15bbl" },
  ]);
  const [options, setOptions] = useState({});

  const [colorMode, setColorMode] = useState<"status" | "beer">("beer");
  const onColorModeChange = useCallback((e: SelectChangeEvent<"status" | "beer">) => {
    setColorMode(e.target.value as "status" | "beer");
  }, []);


  const [selectedTask, setSelectedTask] = useState<TaskDataRef | null>(null);
  const [dataUpdated, setPrevDataValueUpdate] = useState("");

  useEffect(() => {
    console.log("xxx AllBrews changed, reprocessing");
    if (allBrews === undefined || allRecipes === undefined || allFTanks === undefined) {
      setTasks([]);
    } else {
      let brews: TaskDataRef[] = [];

      for (let brew of allBrews) {
        let out = brewToTaskDataRef(brew);
        brews.push(...out);
      }

      let groups: GroupDataRef[] = allFTanks.map((t) => { return { id: t.$gid, key: t.$key, name: `${t.$name} [${t.get('capacity')}]`, sref: t } });
      groups = [BREW_GROUP, EMPTY_GROUP, ...groups];

      // Collect group IDs
      let groupMap: Record<string, GroupDataRef> = {};
      for (let g of groups) { groupMap[g.id] = g; }

      // Process brews, add groups as needed
      for (let brew of brews) {
        if (brew.groupID === undefined) {
          brew.groupID = "";
        } else if (groupMap[brew.groupID] === undefined) {
          let newGroup = { id: brew.groupID, name: "[" + brew.groupID + "]", sref: null };
          groupMap[brew.groupID] = newGroup;
          groups.push(newGroup);
        }
      }

      groups.sort((a, b) => {
        // Hardcoded, brew is at top, empty is second
        if (a.id === "brew") return -1;
        if (b.id === "brew") return 1;
        if (a.id === "") return -1;
        if (b.id === "") return 1;

        let srefCmp = 0;
        if (a.sref != null) srefCmp = (b.sref != null) ? 0 : -1;
        if (b.sref != null) srefCmp = (a.sref != null) ? 0 : 1;
        if (srefCmp != 0) return srefCmp;

        let aToCmp = (a.key) ? a.key + "" : a.id;
        let bToCmp = (b.key) ? b.key + "" : b.id;
        let nameCmp = localeCompare(aToCmp, bToCmp);
        return nameCmp;
      })

      // Seed task colors
      setTasks(brews);
      setGroups(groups);

      let colorCacheOut: Record<string, string> = {};
      for (let r of allRecipes) {
        colorCacheOut['' + r.$gid] = r.get('color') + '';
        colorCacheOut['' + r.$key] = r.get('color') + '';
      }
      setColorCache(colorCacheOut);

      // If we have mode colorMode, try to populate the correct colors
      // if (colorMode == 'beer') {

      // const resolveColors = async (allTasks: TaskDataRef[]) => {
      //   let cache: Record<string, string> = {};
      //   for (let task of allTasks) {
      //     const key = task.ref.get("recipe");
      //     let color = await getBeerColor(task.ref);
      //     if (typeof key === 'string' && color != undefined) {
      //       cache[key] = color;
      //     }
      //   }
      //   return cache;
      // }
      // resolveColors(out).then((cache) => setColorCache(cache));

      // }
    }
  }, [allBrews, allRecipes, allFTanks]) //, colorMode


  /** This adds color effects to tasks */
  useEffect(() => {
    // Update task colors - also happens in onSelectedTaskDeleteToggle
    setTasks((tasks) => [...processTasks(colorMode, colorCache, tasks)]);
  }, [colorCache, colorMode])

  const newBrewHandler = useCallback((newBrew: DBRowEdit<Brew_Fields>) => {
    console.log("Adding new brew", newBrew);
    // debugger;
    let newBrewTaskData = brewToTaskDataRef(newBrew);
    if (newBrewTaskData !== null) {
      let notNullVersion = newBrewTaskData;
      setTasks((tasks) => [...notNullVersion, ...tasks])
      // Reprocess colors to catch new entry
      setColorCache((cache) => { return { ...cache } })
    }
  }, []);

  const onSelectedTaskDeleteToggle = useCallback(() => {
    if (selectedTask) {
      // Are we deleting or undeleting?
      if (selectedTask.ref.$status === 'ACTIVE') {
        selectedTask.ref.set('$status', 'DELETED')
      } else {
        selectedTask.ref.set('$status', 'ACTIVE')
      }

      // Update task colors - also happens in an effect above
      setTasks((tasks) => [...processTasks(colorMode, colorCache, tasks)]);

      // console.log("onSelectedTaskDeleteToggle", selectedTask.gid, selectedTask, selectedTask.ref);





      // let tasksToDelete = (selectedTask.gid === undefined) ? [selectedTask] : tasks.filter((t) => t.gid == selectedTask.gid);

      // // console.log("onSelectedTaskDeleteToggle", selectedTask.gid, tasksToDelete);
      // for (let toDeleteTask of tasksToDelete) {
      //   // console.log("onSelectedTaskDeleteToggle1", toDeleteTask.color, toDeleteTask.ref.$status, toDeleteTask.ref.get('recipe'), colorCache[toDeleteTask.ref.get('recipe')]);
      //   if (actionDelete) {
      //     toDeleteTask.ref.set('$status', 'DELETED')
      //     toDeleteTask.color = '#FF0000';
      //   } else {
      //     toDeleteTask.ref.set('$status', 'ACTIVE')
      //     let key = toDeleteTask.ref.get('recipe');
      //     if (typeof key === 'string')
      //       toDeleteTask.color = colorCache[key];
      //   }
      // }

      // Trigger reload
      setTasks((tasks) => [...tasks])
      // console.log("onSelectedTaskDeleteToggle2", selectedTask.color, selectedTask.ref.$status);
    }
  }, [selectedTask, dataUpdated]);

  const headerComponent =
    <PlanGraphChartHeader errors={[error1, error2, error3]}
      colorMode={colorMode}
      setColorMode={setColorMode}
      allRecipes={allRecipes}
      addBrew={newBrewHandler}
      vdb={vdb}
    />;

  const onDataChange = useCallback((changedTask: ITask<TaskDataRef>, allTasks: ITask<TaskDataRef>[]) => {
    // Below is all to not spam edit.set changes into DBRowEdit
    // It does that by combining the values into a string & comparing against the 'prevDataValueUpdate' 
    // to see if we need to edit.set
    // This should almost certainly be moved into Task, since it knows both directions of change.
    // Task should also monitor for changes (such as to _$name) and update accordingly

    let changed = changedTask.data;

    let filteredGroupTasks = (changed.gid === undefined) ? [] : allTasks.filter((t) => t.gid == changed.gid);
    let allGroupTasks = new Map<string, ITask<TaskDataRef>>();
    for (let gt of filteredGroupTasks) {
      allGroupTasks.set(gt.id, gt);
    }

    if (changed.ref) {
      let brew = changed.ref;

      // This only gets called on COMPLETED data changes now, so no need to prevent quick changes that don't effect our output data
      // let newData = taskValueHash(changed);
      // Probably shouldn't do this... but can't work out another way to reference the current 'dataUpdated' value, 
      // since events happen too quickly to update the callback into ChartTasks
      // let changed = false;
      // setPrevDataValueUpdate((prevData) => {
      //   if (prevData != newData) {
      //     // changed = true;
      //     return newData;
      //   } else {
      //     return prevData;
      //   }
      // });

      if (changed.id.endsWith('|brew')) {
        // Can only change start date
        let startDay = toDayStr(toDate(changed.start));
        brew.set('start_brew', startDay);
        console.log('onDataChange(): ', changed.id, startDay);

        // This triggers the bottom area to update
        setPrevDataValueUpdate(taskValueHash(changed));
      } else if (changed.id.indexOf('|ferment|') != -1) {
        // Parse out position from end, can only change ferment duration for this index
        let fermentPos = toNum(changed.id.split('|')[2], null);
        if (fermentPos != null) {
          let duration = date_utils.diff(changed.end, changed.start, 'day');
          brew.set('fermentduration', duration, undefined, fermentPos)
          // XXX: Ok, this is probably the chart row -> dbedit
          brew.set('tank', changed.groupID, undefined, fermentPos);
          console.log('onDataChange(): ', changed.id, duration);

          // This triggers the bottom area to update
          setPrevDataValueUpdate(taskValueHash(changed));
        }
      }

      let tasks = brewToTaskDataRef(brew);
      for (let upTask of tasks) {
        let existingTask = allGroupTasks.get(upTask.id);
        if (existingTask) {
          existingTask.data.start = upTask.start;
          existingTask.data.end = upTask.end;
          existingTask.data.progress = upTask.progress;
          existingTask.dataToPosition();
          // TODO: This won't update group/tank programmatically!
        }

      }

    }

  }, [setPrevDataValueUpdate]);

  const onSelectChange = useCallback((changed: TaskDataRef | null) => {
    console.log("select changed", changed);
    setSelectedTask(changed)
  }, [setSelectedTask]);

  return (<>
    <SaveBar vdb={vdb} />

    <Routes>
      <Route path=":editid" element={<PlanGraphEdit />} />
      <Route index={true} element={<>
        {tasks &&
          <ChartTasks
            title="Brew Plan"
            tasks={tasks} groups={groups} options={options}
            headerComponent={headerComponent}
            onDataChange={onDataChange} onDataSelect={onSelectChange}
          />
        }
        <PlanGraphBottomDrawer selectedTask={selectedTask} dataUpdated={dataUpdated} onSelectedTaskDeleteToggle={onSelectedTaskDeleteToggle} />
      </>} />
    </Routes>
  </>);
}
