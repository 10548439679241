
export function brewStatusToExplain(brewStatus: string) {
  switch (brewStatus) {
    case 'PLAN':
      return "Brewing has not yet started";
    case 'ACTIVE':
      return "Brewing & Fermentation has started but is not yet finished";
    case 'DONE':
      return "Fermentation has completed.";
    default:
      return "Unknown brew status.";
  }
}
