import { Link, Route, Routes, useLocation, useMatch, useMatches, useNavigate, useParams } from "react-router-dom";
import Browse, { BROWSE_PROPS } from "../../../client/components/Browse";
import getdb from 'client/hopiqvdb';
import { Breadcrumbs, Container, Typography } from "@mui/material";
import GenericBrowseEdit from "client/components/GenericBrowseEdit";
import RRMUILink from "client/components/RRLink";
import { Crumbs } from "client/components/Crumbs";
const vdb = getdb('SetupIndex');

const links = [
  //"beertype", 
  "brew", "fermentationtank", "ingredient", "recipe",
];

export default function SetupIndex(props: {}) {
  // table={vdb.Brew} defaultNav={true} 
  return (
    <Container>

      <Routes>
        <Route path="beertype/*" element={<GenericBrowseEdit table={vdb.BeerType} defaultNav={true} />} />
        <Route path="brew/*" element={<><Crumbs crumbs={[["Config", "/hopiq/config"], ["Brew Sessions", "/hopiq/config/brew"]]} /><GenericBrowseEdit table={vdb.Brew} defaultNav={true} /></>} />
        <Route path="fermentationtank/*" element={<><Crumbs crumbs={[["Config", "/hopiq/config"], ["Fermentation Tanks", "/hopiq/config/fermentationtank"]]} /><GenericBrowseEdit table={vdb.FermentationTank} defaultNav={true} /></>} />
        <Route path="ingredient/*" element={<><Crumbs crumbs={[["Config", "/hopiq/config"], ["Ingredients", "/hopiq/config/ingredient"]]} /><GenericBrowseEdit table={vdb.Ingredient} defaultNav={true} /></>} />
        <Route path="recipe/*" element={<><Crumbs crumbs={[["Config", "/hopiq/config"], ["Recipe", "/hopiq/config/recipe"]]} /><GenericBrowseEdit table={vdb.Recipe} defaultNav={true} /></>} />
        <Route index={true} element={
          <>
            <Crumbs crumbs={[["Config", "/hopiq/config"]]} />
            <h2>Setup Index Home Page        </h2>
            <ul>
              {links.map((l) => <li key={l}><Link to={l}>{l}</Link></li>)}
            </ul>

          </>
        } />
      </Routes>
    </Container>
  );
}
