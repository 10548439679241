import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useMatches } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Slide } from '@mui/material';

const areas = ['Brew', 'Plan', 'Config'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


  // Handle moving appbar up to hide during scroll, noop when beyond size of appbar
  const appBarRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    let state = 0;

    const handleScroll = (event: Event) => {
      if (appBarRef.current) {
        let newState = (window.scrollY < appBarRef.current.clientHeight + 20) ? window.scrollY : appBarRef.current.clientHeight + 20;

        if (state != newState) {
          // console.log('scroll stateChange: ', { state, newState, scrollY: window.scrollY, compOffset: appBarRef.current.clientHeight + 20 });
          if (newState == 0)
            appBarRef.current.style.top = "0px";
          else
            appBarRef.current.style.top = -newState + "px";

          state = newState;
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])


  const matches = useMatches();
  console.log({ matches });

  let area = "HopIQ";
  const pn = matches[0].pathname;
  for (let i = 0; i < areas.length; i++)
    if (pn.indexOf(areas[i].toLocaleLowerCase()) > 0)
      area = areas[i];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleClickUserMenu = () => {
    handleCloseUserMenu();
    alert("Not implemented yet");
  };

  return (
    <>
    {/* Offset is 64px, plus 8px for spacing */}
      <div style={{ height: '72px' }}></div>
      <AppBar position="fixed" style={{ left: 0, right: 0, top: 0 }} ref={appBarRef}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Small */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {areas.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu} selected={(page == area)}>
                    <Link key={page} to={page.toLowerCase()}
                      style={{
                        color: 'black',
                        textDecorationLine: 'none',
                        width: '100%',
                      }}
                    >
                      {page}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* Large */}
            {/* <Box sx={{ display: { xs: 'none', md: 'flex' }, pr: 2 }}>
            <img src="/hopiq-white.png" height="35" />
          </Box> */}
            {/* small */}
            {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src="/hopiq-white.png" height="35" />
          </Box> */}

            {/* Both */}
            <Box sx={{ display: { xs: 'flex', md: 'flex' }, pr: 2, flexGrow: { xs: 1, md: 0 } }}>

              <Link to={'./'}>
                <img src="/hopiq-white.png" height="35" />
              </Link>

              {/* Small Only */}
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  ml: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 0,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {area}
              </Typography>
            </Box>

            {/* Large */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {areas.map((page) => (
                <Link key={page} to={page.toLowerCase()}
                  style={{
                    textDecorationLine: (page == area) ? 'underline' : 'none',
                    textDecorationColor: 'white',
                    textDecorationStyle: 'solid',
                    textDecorationThickness: 'auto',
                  }}
                >
                  <Typography
                    sx={{
                      // display: 'block',
                      px: 1,
                      // mt: 1,
                      // mb: 1,
                      minWidth: '64px',
                      verticalAlign: 'middle',
                      position: 'relative',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      color: 'white',
                    }}>
                    {page}
                  </Typography>
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open User & Settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Users Name" src="/logo192.png" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleClickUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

    </>
  );
}
export default ResponsiveAppBar;