import { Grid, MenuItem } from "@mui/material";
import { EditFieldMUI, EditIDSelectFieldMUI } from "client/vdb/mui/MUIEditFields";
import { RowText } from "client/vdb/mui/MUIEditParts";
import { useEffect } from "react";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import RefID from "shared/vdb/RefID";
import { GRaw } from "shared/vdb/types";
import * as utils from "shared/vdb/utils";
import { Brew_Fields, DBRecipe } from "shared/vdb/vdbsharedclass";

function generateColorRecipeMenuItem(raw: GRaw<any>, gid: string) {
  const name = utils.$IRowProcessNAME(raw);
  return <MenuItem key={gid} value={gid} data-gid={gid} data-name={name}>
    <div style={{ display: 'inline-block', width: '20px', height: '20px', marginRight: '10px', backgroundColor: raw['color'] }}></div>{name}
  </MenuItem>;
}

export default function NewBrewMin(props: { edit: DBRowEdit<Brew_Fields> }) {
  const edit = props.edit;

  // When recipe changes, update the durations from recipe
  useEffect(() => {
    // If no status set, default to PLAN
    if (edit.get('status') == null)
      edit.set('status', 'PLAN');

    return edit.eventbus.onData("recipe", async (event) => {
      let recipedID = RefID.parseAny(event.value);
      if (recipedID != null && recipedID.getTable() === 'Recipe') {
        edit.$src.get(recipedID.value).then((recipe) => {
          if (recipe) {
            edit.set('brewduration', recipe.get('brewduration'))
            edit.set('fermentduration', recipe.get('fermentduration'))
          }
        })
      }

    });
  }, [props.edit]);

  return (
    <Grid container spacing={2} data-id-wrap={edit.$gid} style={{ maxWidth: '450px' }}>
      <Grid item xs={12}>
        {/* <EditFieldMUI edit={edit} field="name" fullWidth={true} /> */}
      </Grid>
      {/* <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="type" fullWidth={true} /></Grid> */}
      <Grid item xs={12}>
        {/* <EditFieldMUI edit={edit} field="recipe" fullWidth={true} /> */}
        <EditIDSelectFieldMUI edit={edit} field="recipe" fullWidth={true} generateMenuItem={generateColorRecipeMenuItem} />
      </Grid>

      <Grid item xs={12}><EditFieldMUI edit={edit} field="start_brew" fullWidth={true} /></Grid>
      <Grid item xs={12}><EditFieldMUI edit={edit} field="brewduration" fullWidth={true} /></Grid>
      <Grid item xs={12}><EditFieldMUI edit={edit} field="fermentduration" fullWidth={true} /></Grid>
    </Grid>
  )

}