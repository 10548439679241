import { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Container, SxProps, Tab, Tabs, Theme, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

import GenericBrowseEdit from "../../../client/components/GenericBrowseEdit";
import SimpleBrews from "../setup/OldPages/SimpleBrews";

import getdb from 'client/hopiqvdb';

import { Route, Routes } from "react-router-dom";
import PlanGraph from "./PlanGraph";
import PlanList from "./PlanList";
import PlanHome from "./PlanHome";
import { PlanBrewEdit } from "./PlanBrewEdit";
const vdb = getdb("genericBrowse");

export default function PlanIndex() {
  return (
    <Routes>
      <Route path={"graph/*"} element={<PlanGraph />} />
      <Route path={"brew/:id"} element={<PlanBrewEdit />} />
      <Route path={"list"} element={<PlanList />} />
      <Route index={true} element={<PlanHome />} />
    </Routes>
  );
}