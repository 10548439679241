import React from 'react';
import { TaskData, Options } from './index';
import { Group } from './ChartTasks';
import date_utils from './date_utils';
import TaskFunc from './TaskFunc';
import { CalcTool } from './CalcTool';

export abstract class ITask<TD extends TaskData> {
  // Probably should remove this at some point?
  data: TD;
  /** Unique id - redundant */
  id: string;
  gid?: string;
  active: boolean = false;
  group: Group;
  groupPos: number;
  rowsHeight: number;
  x: number = 0;
  width: number = 0;
  progress: number = 0;
  display: string | null = null;

  _startEndDuration: number = 0;
  _y: number = 0;
  // _height: number = 0;

  calcTool: CalcTool | undefined;
  deps: ITask<any>[] = [];

  constructor(data: TD, group: Group, groupPos: number, deps: ITask<any>[]) {
    this.data = data;
    this.id = data.id;
    this.gid = data.gid;
    this.group = group;
    this.groupPos = groupPos;
    this.deps = deps;
    this.rowsHeight = (data.rowsHeight !== undefined) ? data.rowsHeight : 1;
  }

  getRowPosNum() {
    return (this.group?.prerows || 0) + this.groupPos;
  }

  abstract get_snap_position(deltaX: number): number;
  abstract setChartStart(start: Date): void;

  /** Call when the data has changed, push changes into this Task */
  // abstract dataToPosition(): void;

  dataToPosition() {
    if (this.calcTool) {
      const { step, column_width, header_height, padding, bar_height } = this.calcTool.options;
      const _chart_start = this.calcTool.start_date;
      const rowDownPosition = this.getRowPosNum();


      const diff = date_utils.diff(this.data.start, _chart_start, 'hour');
      let calcDuration = date_utils.diff(this.data.end, this.data.start, 'hour') / step;

      this.x = diff / step * column_width;
      // this._y = header_height + padding + rowDownPosition * (bar_height + padding);
      this._y = padding / 2 + rowDownPosition * (bar_height + padding);
      this.width = column_width * calcDuration;
      // this._height = bar_height;
      this.progress = column_width * calcDuration * (this.data.progress! / 100) || 0;
      this._startEndDuration = calcDuration;

    }

  }


  /** Call when this task has changed, push changes into the data */
  abstract positionToDates(): [start: Date, end: Date, diffInHours: number];

  abstract renderBars(ignore: any): JSX.Element;

  abstract renderArrows(ignore: any): JSX.Element;
}

/** Was made to pass a Task subclass constructor. Ended up not using it. */
export interface CTask<TD extends TaskData> {
  new(task: TD, group: Group, groupPos: number, options: Options, calcTool: CalcTool, chart_start: Date, selectTaskCallBack: undefined): ITask<TD>;
}

export class Task<TD extends TaskData> extends ITask<TD> {
  _options: Options;
  _chart_start: Date;


  constructor(task: TD, group: Group, groupPos: number, options: Options, calcTool: CalcTool, chart_start: Date, selectTaskCallBack: undefined) {
    super(task, group, groupPos, []);
    this.calcTool = calcTool;


    this._options = options;

    // this._height = options.bar_height;
    this._chart_start = chart_start;

    this.dataToPosition();
  }

  getY() {
    return this.group.getSubY(this.groupPos);
    // return this._group.starty + (1 + this.groupPos*2) * (this._options.padding / 2) + (this.groupPos * this._options.bar_height);
  }

  setChartStart(start: Date) {
    this._chart_start = start;
    this.dataToPosition();
  }

  get_snap_position(deltaX: number): number {
    return (this.calcTool) ? this.calcTool.get_snap_position(deltaX) : 0;
  }

  dataToPosition() {
    const { step, column_width, header_height, padding, bar_height } = this._options;
    const { _chart_start } = this;
    const rowDownPosition = this.getRowPosNum();

    const diff = date_utils.diff(this.data.start, _chart_start, 'hour');
    let calcDuration = date_utils.diff(this.data.end, this.data.start, 'hour') / step;

    this.x = diff / step * column_width;
    // this._y = header_height + padding + rowDownPosition * (bar_height + padding);
    this._y = padding / 2 + rowDownPosition * (bar_height + padding);
    this.width = column_width * calcDuration;
    // this._height = bar_height + ((this.rowsHeight - 1) * (bar_height + padding));
    this.progress = column_width * calcDuration * (this.data.progress! / 100) || 0;
    this._startEndDuration = calcDuration;
  }

  positionToDates(): [start: Date, end: Date, diffInHours: number] {
    let diff = (this.x / this._options.column_width) * this._options.step;

    const computed_start_date = date_utils.add(this._chart_start, diff, 'hour');
    const width_in_units = this.width / this._options.column_width;
    const computed_end_date = date_utils.add(computed_start_date, width_in_units * this._options.step, 'hour');

    console.log("convertPositionToDates", "start", computed_start_date.toISOString(), "end", computed_end_date.toISOString());
    this.data.start = computed_start_date;
    this.data.end = computed_end_date;
    if (this.group) {
      // console.log("positionToDates() group ", this._group?.data.id);
      this.data.groupID = this.group?.data.id;
    }
    return [computed_start_date, computed_end_date, width_in_units * this._options.step];
  }

  renderBars(ignore: any) {
    // console.log("onRender: ",ignore?.name, (this === ignore) ? this.name : null);
    // this._active = (this === ignore);

    // Infer Brew status (plan, active [mid-brew], done) from canX flags
    const statusIcon = this.data.statusIcon;

    return <React.Fragment key={"task-" + this.data.id}>
      <TaskFunc key={"task1-" + this.data.id} id={this.data.id}
        name={this.display || this.data.name}
        x={this.x} y={this.getY() || 0} width={this.width} rowheight={this.data.rowsHeight} progress={this.progress}
        active={this.active} options={this._options}
        taskClass={(this.data.id === 't3' ? "yeast" : undefined)} color={this.data.color}
        statusIcon={statusIcon} canResize={ (this.data.canResize === undefined) ? true : this.data.canResize}
      />
      {/* <TaskFunc key={"task2-" + this.task.id} id={this.task.id}
        name={this.display || this.task.name}
        x={this.x + 30} y={this.getY() || 0} width={this.width} height={this._height} progress={this.progress}
        active={this.active} options={this._options}
        taskClass={(this.task.id === 't3' ? "yeast" : undefined)} color={this.task.color}
        statusIcon={statusIcon}
      /> */}
    </React.Fragment>

    // return (
    //   <g className="bar-wrapper" id={this.id}>
    //     {/* bar_group */}
    //     <g className="bar-group">
    //       {/* $bar */}
    //       <rect x={this._x} y={this._y} width={this._width} height={this._options.bar_height} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius} className="bar"></rect>
    //       {/* $bar_progress */}
    //       <rect x={this._x} y={this._y} width={this._progress} height={this._options.bar_height} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius} className="bar-progress"></rect>
    //     </g>
    //     <g className="handle-group">
    //       <rect className="handle right" x={this._x + this._width - 9} y={this._y + 1} width={HANDLE_WIDTH} height={this._options.bar_height - 2} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius}></rect>
    //       <rect className="handle left" x={this._x + 1} y={this._y + 1} width={HANDLE_WIDTH} height={this._options.bar_height - 2} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius}></rect>
    //       <polygon className="handle progress" points={this.calcProgressTrianglePoints()}></polygon>
    //     </g>
    //     <text x={(this._textIsBig) ? (this._x + this._width + 5) : (this._x + this._width / 2)} y={this._y + this._height / 2} className={(this._textIsBig) ? "bar-label big" : "bar-label"}>{this.name}</text>
    //   </g>
    // );
  }


  calculate_path(from: ITask<any>, to: ITask<any>): string {
    let start_x =
      from.x + from.width / 2;

    const condition = () =>
      to.x < start_x + this._options.padding &&
      start_x > from.x + this._options.padding;

    while (condition()) {
      start_x -= 10;
    }

    const start_y = //from._group.getStartY(this.groupPos) + this._options.bar_height;
      this._options.bar_height +
      (this._options.padding + this._options.bar_height) *
      from.getRowPosNum() +
      this._options.padding / 2;

    const end_x = to.x - this._options.padding / 8;
    const end_y = this.group.getSubY(this.groupPos, 'mid');
    // this._options.bar_height / 2 +
    // (this._options.padding + this._options.bar_height) *
    // to.getRowPosNum() +
    // this._options.padding / 2;

    const from_is_below_to =
      from.getRowPosNum() > to.getRowPosNum();
    const curve = this._options.arrow_curve;
    const clockwise = from_is_below_to ? 1 : 0;
    const curve_y = from_is_below_to ? -curve : curve;
    const offset = from_is_below_to
      ? end_y + this._options.arrow_curve
      : end_y - this._options.arrow_curve;

    let path = `
        M ${start_x} ${start_y}
        V ${offset}
        a ${curve} ${curve} 0 0 ${clockwise} ${curve} ${curve_y}
        L ${end_x} ${end_y}
        m -5 -5
        l 5 5
        l -5 5`;

    if (
      to.x <
      from.x + this._options.padding
    ) {
      const down_1 = this._options.padding / 2 - curve;
      const down_2 =
        (to.group.getSubY(this.groupPos, 'mid') || 0) //+
        // to._height / 2 
        - curve_y;
      const left = to.x - this._options.padding;

      path = `
            M ${start_x} ${start_y}
            v ${down_1}
            a ${curve} ${curve} 0 0 1 -${curve} ${curve}
            H ${left}
            a ${curve} ${curve} 0 0 ${clockwise} -${curve} ${curve_y}
            V ${down_2}
            a ${curve} ${curve} 0 0 ${clockwise} ${curve} ${curve_y}
            L ${end_x} ${end_y}
            m -5 -5
            l 5 5
            l -5 5`;
    }

    return path;
  }


  renderArrows(ignore: any) {
    let arrows: JSX.Element[] = [];
    for (let dep of this.deps) {
      arrows.push(
        <path key={dep.id} d={this.calculate_path(dep, this)} data-from={this.data.id} data-to={dep.id} stroke={this.active ? "black" : "lightgrey"}></path>
      );
    }
    return <React.Fragment key={this.id}>{arrows}</React.Fragment>;
  }

}




// if (label.getBBox().width > getWidth(bar)) {
//   label.classList.add('big');
//   label.setAttribute('x', (getX(bar) + getWidth(bar) + 5) as any);
// } else {
//   label.classList.remove('big');
//   label.setAttribute('x', (getX(bar) + getWidth(bar) / 2) as any);
// }