import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Drawer, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, SwipeableDrawer, TextField, Typography, styled } from "@mui/material";

import ChartTasks from "../../../client/charttasks/ChartTasks";
import { GroupData, TaskData } from "client/charttasks";

import { dateFns, toNum } from "shared/vdb/utils";

import getdb from 'client/hopiqvdb';
import useVDB from "../../../client/vdb/useVDB";
import ErrorAlert from "client/vdb/ErrorAlert";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { Brew_Fields, DBBrew, DBRecipe, HopIQBranchVaultDB } from "shared/vdb/vdbsharedclass";
import date_utils from "client/charttasks/date_utils";

import ViewTimelineIconOutlined from '@mui/icons-material/ViewTimelineOutlined';
import CalendarViewMonthIconOutlined from '@mui/icons-material/CalendarViewMonthOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import RefID from "shared/vdb/RefID";
import { ChevronLeft, Inbox } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import VaultDB from "shared/vdb/VaultDB";
import NewBrewMin from "client/components/NewBrewMin";
import { RowText } from "client/vdb/mui/MUIEditParts";


export default function PlanGraphChartHeader(props: {
  errors: any[],
  colorMode: "beer" | "status",
  setColorMode: (colorMode: "beer" | "status") => void,
  allRecipes: DBRecipe[] | undefined,
  addBrew: (newBrew: DBRowEdit<Brew_Fields>) => void,
  vdb: HopIQBranchVaultDB,
}) {
  const onColorModeChange = useCallback((event: SelectChangeEvent<"beer" | "status">, child: React.ReactNode) => {
    props.setColorMode(event.target.value as "beer" | "status");
  }, [props.setColorMode]);

  // const [open, setOpen] = useState(true);
  // const handleClose = useCallback(() => {
  //   setOpen(false);
  // }, [setOpen]);

  const [newBrew, setNewBrew] = useState<DBRowEdit<Brew_Fields> | undefined>();

  const handleAddBrew = useCallback(() => {
    if (newBrew) {
      let parsedDur = toNum(newBrew.get('?duration'), null);
      if (parsedDur === null) {
        alert("Please provide a valid duration");
        return;
      }
      newBrew.set('$status', "ACTIVE");
      props.addBrew(newBrew);
    }
    setNewBrew(undefined);
  }, [newBrew, setNewBrew]);
  const handleCancel = useCallback(() => {
    if (newBrew) {
      newBrew.clearAllEdits();
    }
    setNewBrew(undefined)
  }, [props.addBrew, newBrew, setNewBrew]);

  const onNewBrew = useCallback((event: SelectChangeEvent<string>) => {
    let recipeID = event.target.value;
    let recipe = props.allRecipes?.find((r) => r.$gid == recipeID);
    console.log("props.vdb", props.vdb);
    console.log("props.vdb.Brew", props.vdb.Brew);

    // Start the brew in draft mode
    const nBrew = props.vdb.Brew.new().edit();
    nBrew.set('$status', "DRAFT");
    nBrew.set('status', "PLAN");
    nBrew.set('recipe', recipeID);
    nBrew.set('start_brew', new Date());

    console.log("onNewBrew found recipe? ", Boolean(recipe), recipe);
    if (recipe) {
      nBrew.set('_recipe', recipe.$name);
      nBrew.set('brewduration', recipe.getE('brewduration'));
      nBrew.set('fermentduration', recipe.getE('fermentduration'));
    }

    setNewBrew(nBrew);
  }, [props.allRecipes, props.vdb]);

  return <>
    <div style={{ padding: 5 }}>
      <Paper variant='outlined' style={{ fontSize: 'initial', padding: 4 }}>
        <Stack direction="row" >
          <ErrorAlert errors={props.errors} />
          <Typography variant="h4">Plan Timeline </Typography>

          <Stack direction="row" alignItems="center">
            <ViewTimelineIconOutlined color="primary" fontSize="medium" sx={{ ml: 2, mr: 1 }} />
            <Select name="Color" title="Color" variant="standard" value={props.colorMode} onChange={onColorModeChange}>
              <MenuItem value="status">Color By Status</MenuItem>
              <MenuItem value="beer">Color By Beer</MenuItem>
            </Select>

            <FormControl sx={{ m: 1, minWidth: 80, flexDirection: 'row' }}>
              <InputLabel id="demo-simple-select-autowidth-label"></InputLabel>
              <AddCircleOutlineIcon color="primary" fontSize="medium" sx={{ ml: 2, mr: 1, }} />
              <Select name="Recipe" title="New Brew" variant="standard" value={""} onChange={onNewBrew} displayEmpty>
                <MenuItem value="">Add New Recipe Brew</MenuItem>
                {props.allRecipes && props.allRecipes.map((r) => <MenuItem value={r.$gid} key={r.$gid}>
                  <div style={{ width: '20px', height: '20px', marginRight: '10px', backgroundColor: r.get('color') }}></div>{r.$name}
                </MenuItem>)}
              </Select>
            </FormControl>
          </Stack>

          <Dialog open={(newBrew !== undefined)} onClose={handleCancel}>
            <DialogTitle>New Brew Plan{<RowText row={newBrew} prefix=": " field="$name" />}</DialogTitle>
            <DialogContent>
              {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText> */}
              {newBrew && <NewBrewMin edit={newBrew} />}
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Cancel</Button> */}
              <Button onClick={handleAddBrew} variant="contained">Add Brew</Button>
            </DialogActions>
          </Dialog>

          {/* <CalendarViewMonthIconOutlined color="primary" fontSize="medium" onClick={handleDrawerOpen} /> */}

        </Stack>
      </Paper>
    </div>
  </>;
}