import { GRaw } from './types';
import { PARTIAL_NEW_ACTIVE } from './utils'
import { Prisma } from '@prisma/client';
import { RootVaultDB, BranchVaultDB, VaultDB, DBQuery, DBRow, DBTable, RAWSource, IDBRow, getGID, Schema, SchemaTables, SchemaPart, IVaultDB } from './VaultDB';
import * as vdbschema from './config/vdb_schema';

export type Ingredient_Fields = {
  $table: "Ingredient",
  name?: string,
  type?: string,
  unit?: string,
}

export type IngredientGRaw = GRaw<Ingredient_Fields>;
export type Ingredient_FilterType = Pick<Prisma.IngredientFindManyArgs,'where'|'orderBy'|'take'|'skip'>;
export class DBIngredientTable extends DBTable<Ingredient_Fields, IngredientGRaw, DBIngredient, typeof HopIQSchema, HopIQTableNames> {
  constructor(src: IVaultDB<typeof HopIQSchema, HopIQTableNames>) {
    super(src, vdbschema.Ingredient, DBIngredient);
  }
  new(parent?:string|IDBRow<Ingredient_Fields>) {
    return this.wrapRawFunc({
      $id: RAWSource.getNextTempID(),
      $table: this.schema.key as "Ingredient",
      $path: getGID(parent),
      ...PARTIAL_NEW_ACTIVE
    });
  }
  query(filter: Ingredient_FilterType): DBQuery<Ingredient_Fields, IngredientGRaw, DBIngredient, typeof HopIQSchema, HopIQTableNames> {
    return new DBQuery(this, filter);
  }
  $schema() {
    return vdbschema.Ingredient;
  }
}
export class DBIngredient extends DBRow<Ingredient_Fields> {
}


export type Recipe_Fields = {
  $table: "Recipe",
  name?: string,
  brew?: string,
  label?: string,
  brewduration?: number,
  fermentduration?: number[],
  color?: string,
  notes?: string,
  ingredients?: string,
  ingredient_item?: string[],
  ingredient_type?: string[],
  ingredient_amount?: number[],
  ingredient_unit?: string[],
}

export type RecipeGRaw = GRaw<Recipe_Fields>;
export type Recipe_FilterType = Pick<Prisma.RecipeFindManyArgs,'where'|'orderBy'|'take'|'skip'>;
export class DBRecipeTable extends DBTable<Recipe_Fields, RecipeGRaw, DBRecipe, typeof HopIQSchema, HopIQTableNames> {
  constructor(src: IVaultDB<typeof HopIQSchema, HopIQTableNames>) {
    super(src, vdbschema.Recipe, DBRecipe);
  }
  new(parent?:string|IDBRow<Recipe_Fields>) {
    return this.wrapRawFunc({
      $id: RAWSource.getNextTempID(),
      $table: this.schema.key as "Recipe",
      $path: getGID(parent),
      ...PARTIAL_NEW_ACTIVE
    });
  }
  query(filter: Recipe_FilterType): DBQuery<Recipe_Fields, RecipeGRaw, DBRecipe, typeof HopIQSchema, HopIQTableNames> {
    return new DBQuery(this, filter);
  }
  $schema() {
    return vdbschema.Recipe;
  }
}
export class DBRecipe extends DBRow<Recipe_Fields> {
}


export type FermentationTank_Fields = {
  $table: "FermentationTank",
  capacity?: number,
  manufacture?: string,
  model?: string,
}

export type FermentationTankGRaw = GRaw<FermentationTank_Fields>;
export type FermentationTank_FilterType = Pick<Prisma.FermentationTankFindManyArgs,'where'|'orderBy'|'take'|'skip'>;
export class DBFermentationTankTable extends DBTable<FermentationTank_Fields, FermentationTankGRaw, DBFermentationTank, typeof HopIQSchema, HopIQTableNames> {
  constructor(src: IVaultDB<typeof HopIQSchema, HopIQTableNames>) {
    super(src, vdbschema.FermentationTank, DBFermentationTank);
  }
  new(parent?:string|IDBRow<FermentationTank_Fields>) {
    return this.wrapRawFunc({
      $id: RAWSource.getNextTempID(),
      $table: this.schema.key as "FermentationTank",
      $path: getGID(parent),
      ...PARTIAL_NEW_ACTIVE
    });
  }
  query(filter: FermentationTank_FilterType): DBQuery<FermentationTank_Fields, FermentationTankGRaw, DBFermentationTank, typeof HopIQSchema, HopIQTableNames> {
    return new DBQuery(this, filter);
  }
  $schema() {
    return vdbschema.FermentationTank;
  }
}
export class DBFermentationTank extends DBRow<FermentationTank_Fields> {
}


export type Brew_Fields = {
  $table: "Brew",
  recipe?: string,
  start_brew?: Date,
  end_brew?: Date,
  duration?: number,
  status?: string,
  amount?: number,
  batchNum?: number,
  brewer?: string,
  asst_brewer?: string,
  notes?: string,
  brewduration?: number,
  fermentation?: string,
  fermentduration?: number[],
  tank?: string[],
  ingredients?: string,
  ingredient_item?: string,
  ingredient_type?: string,
  ingredient_amount?: number,
  ingredient_unit?: string,
  package?: string,
  pack_type?: string[],
  pack_qty?: number[],
}

export type BrewGRaw = GRaw<Brew_Fields>;
export type Brew_FilterType = Pick<Prisma.BrewFindManyArgs,'where'|'orderBy'|'take'|'skip'>;
export class DBBrewTable extends DBTable<Brew_Fields, BrewGRaw, DBBrew, typeof HopIQSchema, HopIQTableNames> {
  constructor(src: IVaultDB<typeof HopIQSchema, HopIQTableNames>) {
    super(src, vdbschema.Brew, DBBrew);
  }
  new(parent?:string|IDBRow<Brew_Fields>) {
    return this.wrapRawFunc({
      $id: RAWSource.getNextTempID(),
      $table: this.schema.key as "Brew",
      $path: getGID(parent),
      ...PARTIAL_NEW_ACTIVE
    });
  }
  query(filter: Brew_FilterType): DBQuery<Brew_Fields, BrewGRaw, DBBrew, typeof HopIQSchema, HopIQTableNames> {
    return new DBQuery(this, filter);
  }
  $schema() {
    return vdbschema.Brew;
  }
}
export class DBBrew extends DBRow<Brew_Fields> {
}


export type BeerType_Fields = {
  $table: "BeerType",
  name?: string,
  style?: string,
  color?: string,
}

export type BeerTypeGRaw = GRaw<BeerType_Fields>;
export type BeerType_FilterType = Pick<Prisma.BeerTypeFindManyArgs,'where'|'orderBy'|'take'|'skip'>;
export class DBBeerTypeTable extends DBTable<BeerType_Fields, BeerTypeGRaw, DBBeerType, typeof HopIQSchema, HopIQTableNames> {
  constructor(src: IVaultDB<typeof HopIQSchema, HopIQTableNames>) {
    super(src, vdbschema.BeerType, DBBeerType);
  }
  new(parent?:string|IDBRow<BeerType_Fields>) {
    return this.wrapRawFunc({
      $id: RAWSource.getNextTempID(),
      $table: this.schema.key as "BeerType",
      $path: getGID(parent),
      ...PARTIAL_NEW_ACTIVE
    });
  }
  query(filter: BeerType_FilterType): DBQuery<BeerType_Fields, BeerTypeGRaw, DBBeerType, typeof HopIQSchema, HopIQTableNames> {
    return new DBQuery(this, filter);
  }
  $schema() {
    return vdbschema.BeerType;
  }
}
export class DBBeerType extends DBRow<BeerType_Fields> {
}


export type ALL_RAW_TYPES = IngredientGRaw | RecipeGRaw | FermentationTankGRaw | BrewGRaw | BeerTypeGRaw

export type HopIQTableNames = "Ingredient" | "Recipe" | "FermentationTank" | "Brew" | "BeerType" ;
const HopIQTables: SchemaTables<HopIQTableNames> = {
  Ingredient: new SchemaPart(vdbschema.Ingredient, DBIngredient, DBIngredientTable),
  Recipe: new SchemaPart(vdbschema.Recipe, DBRecipe, DBRecipeTable),
  FermentationTank: new SchemaPart(vdbschema.FermentationTank, DBFermentationTank, DBFermentationTankTable),
  Brew: new SchemaPart(vdbschema.Brew, DBBrew, DBBrewTable),
  BeerType: new SchemaPart(vdbschema.BeerType, DBBeerType, DBBeerTypeTable),
}
const HopIQSchema = new Schema("HopIQ", HopIQTables);

export class HopIQBranchVaultDB extends BranchVaultDB<typeof HopIQSchema, HopIQTableNames> {
  Ingredient: DBIngredientTable;
  Recipe: DBRecipeTable;
  FermentationTank: DBFermentationTankTable;
  Brew: DBBrewTable;
  BeerType: DBBeerTypeTable;
  constructor(src: HopIQRootVaultDB, name: string) {
    super(src, name);
    this.Ingredient = new DBIngredientTable(this);
    this.Recipe = new DBRecipeTable(this);
    this.FermentationTank = new DBFermentationTankTable(this);
    this.Brew = new DBBrewTable(this);
    this.BeerType = new DBBeerTypeTable(this);
  }
}

export class HopIQRootVaultDB extends RootVaultDB<typeof HopIQSchema, HopIQTableNames, HopIQBranchVaultDB> {
  Ingredient: DBIngredientTable;
  Recipe: DBRecipeTable;
  FermentationTank: DBFermentationTankTable;
  Brew: DBBrewTable;
  BeerType: DBBeerTypeTable;
  constructor(src: RAWSource) {
    super(HopIQSchema, src, (name: string) => new HopIQBranchVaultDB(this,name));
    this.Ingredient = new DBIngredientTable(this);
    this.Recipe = new DBRecipeTable(this);
    this.FermentationTank = new DBFermentationTankTable(this);
    this.Brew = new DBBrewTable(this);
    this.BeerType = new DBBeerTypeTable(this);
  }
}

