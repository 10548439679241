import { z } from "zod";
import RefID from "./RefID";
import { AsyncFormatter } from "./types";
import * as utils from "./utils";


/*
 * This is the form of a valid INV_DELTA object which will be indexed
 */
export const META_INV_DELTA_ZOD = z.object({
  // '|' delimeted array for inv rollups, including findal item or lot.
  // ex: BrewTanks|@/BrewTank/1
  itempath: z.string(),
  //@table/id - the exact item or itemlot
  // If this is null, 
  item: z.coerce.string().optional(),
  // In the future, grouppath as above for items
  //group: z.string(), //@table/id - for locations?
  when: z.coerce.date(), //z.union([z.string().datetime(), z.date()]),
  status: z.enum(['PLAN', 'DONE', 'CANCEL']),
  adj: z.enum(['-', '+', '=']),
  qty: z.number(),
  srcid: z.string().regex(RefID.regex), // Row this comes from
  srclabel: z.string(), // field|pos|subpart
});
export type META_INV_DELTA = z.infer<typeof META_INV_DELTA_ZOD>;

// This is for a 'resource' use. Processing function should output a start & end version that uses then frees the resource
export const META_INV_RESOURCE_IN = z.object({
  itemprefix: z.string(),
  item: z.coerce.string().optional(), //@table/id|lot#
  // group: z.string(), //@table/id|etc...

  start: z.coerce.date(), //z.union([z.string().datetime(), z.date()]),
  end: z.coerce.date().optional(),
  status: z.enum(["PLAN", "ACTIVE", "DONE", "CANCEL"]),
  // adj: z.enum(['-', '+', '=']),
  qty: z.coerce.number().gt(0),
  brewduration: z.coerce.number().gt(0),

  srcid: z.string().regex(RefID.regex), // Row this comes from
  // srclabel: z.string(), // field|pos|subpart
});
export type META_INV_RESOURCE_IN = z.infer<typeof META_INV_RESOURCE_IN>;
type META_INV_RESOURCE_IN_DEPS = {
  [key in keyof META_INV_RESOURCE_IN]: string | META_INV_RESOURCE_IN[key] | (string | META_INV_RESOURCE_IN[key])[]
}

/** isUse is for 'uses' of a resource which are logged as DONE. isUse false is to for setting resource availability, logged as PLAN */
export function createInvResource(isUse: boolean, deps: META_INV_RESOURCE_IN_DEPS) {
  const process = (fieldName: string, arrayPos: number, depValues: { [key in keyof META_INV_RESOURCE_IN_DEPS]: any }): Promise<META_INV_DELTA | META_INV_DELTA[]> => {
    const values = META_INV_RESOURCE_IN.safeParse(depValues);
    if (values.success === false) {
      // @ts-ignore
      return { ERROR: { deps: deps, depValues: depValues, error: values.error } };
    }

    const { itemprefix, item, start, qty, brewduration, srcid } = values.data;

    let brewqty = Math.ceil(qty / 7);
    if (brewduration > 1 && brewqty == 1) { brewqty = 2 }

    /* 
     * If input status is CANCEL, put this in as CANCEL as well.
     * Otherwise, take isUse flag to set either DONE or PLAN,
     */
    const status = (values.data.status == "CANCEL") ? "CANCEL" : (isUse) ? "DONE" : "PLAN";
    let end = values.data.end;

    if (end == undefined) {
      end = new Date(start)
      end.setDate(end.getDate() + brewduration);
    }

    let itempath = Array.isArray(itemprefix) ? itemprefix : [itemprefix];
    if (item) itempath.push(item);

    const startStatus = status;//(status === 'ACTIVE') ? 'DONE' : status;
    const endStatus = status;//(status === 'ACTIVE') ? 'PLAN' : status;

    let startinv: META_INV_DELTA = { itempath: itempath.join('|'), item, when: start, status: startStatus, adj: '-', qty: brewqty, srcid, srclabel: fieldName + "|" + arrayPos + "|invresource|" + isUse + "|start" };
    let endinv: META_INV_DELTA = { itempath: itempath.join('|'), item, when: end, status: endStatus, adj: '+', qty: brewqty, srcid, srclabel: fieldName + "|" + arrayPos + "|invresource|" + isUse + "|end" };

    return Promise.resolve([startinv, endinv]);
  }

  let asyncProcesser: AsyncFormatter<META_INV_RESOURCE_IN_DEPS> = { deps, process };
  return asyncProcesser;
}

// This is for a 'resource' use. Processing function should output a start & end version that uses then frees the resource
export const META_INV_ITEM_IN = z.object({
  itemprefix: z.string().optional(),
  item: z.string(), //@table/id|lot#
  // group: z.string(), //@table/id|etc...

  when: z.coerce.date(), //z.union([z.string().datetime(), z.date()]),
  status: z.enum(["PLAN", "ACTIVE", "DONE", "CANCEL"]),
  // adj: z.enum(['-', '+', '=']),
  qty: z.coerce.number().gt(0),

  srcid: z.string().regex(RefID.regex), // Row this comes from
  // srclabel: z.string(), // field|pos|subpart
});
export type META_INV_ITEM_IN = z.infer<typeof META_INV_ITEM_IN>;
type META_INV_ITEM_IN_DEPS = {
  [key in keyof META_INV_ITEM_IN]: string | META_INV_ITEM_IN[key] | (string | META_INV_ITEM_IN[key])[]
}

export function createInvUse<DEPS extends META_INV_ITEM_IN_DEPS>(deps: DEPS) {
  const process = (fieldName: string, arrayPos: number, depValues: { [key in keyof DEPS]: any }): Promise<META_INV_DELTA | META_INV_DELTA[]> => {
    const values = META_INV_ITEM_IN.safeParse(depValues);
    if (values.success === false) {
      // @ts-ignore
      return { ERROR: { deps: deps, depValues: depValues, error: values.error } };
    }

    const { itemprefix, item, when, status, qty, srcid } = values.data;

    let itempath = utils.ensureArray(itemprefix);
    itempath.push(item);
    utils.trimArray(itempath)

    const startStatus = (status === 'ACTIVE') ? 'DONE' : status;

    let startinv: META_INV_DELTA = { itempath: itempath.join('|'), item, when: when, status: startStatus, adj: '-', qty: qty, srcid, srclabel: fieldName + "|" + arrayPos + "|inv|use|start" };

    return Promise.resolve([startinv]);
  }

  let asyncProcesser: AsyncFormatter<DEPS> = { deps, process };
  return asyncProcesser;
}

// const TEST_DEBUG = createInvResource({
//   item: ["@fermenttank", "\@EquipFermentationTank/"], // gid of tank, or apply to prefix of all tanks
//   group: "", // Or location
//   start: "@start_brew",
//   end: "@end_brew",
//   status: "@status",
//   adj: '-',
//   qty: ["@amount", -1],
//   srcid: "@$gid", // Row this comes from
//   srclabel: "$inv_delta-brewtank", // field|pos|subpart
// },
//   async (depValues) => {
//     depValues = META_INV_DELTA_IN.parse(depValues);
//     const { item, group, start, end, status, adj, qty, srcid, srclabel } = depValues;
//     return [{ item, group, when: start, status, adj: '-', qty, srcid, srclabel: srclabel + "|0|" }]
//   });










// export type META_INV_COUNT = {
//   itempath: string,
//   when: Date,
//   status: 'PLAN' | 'DONE' | 'CANCEL',
//   adj: '-' | '+' | '=',
//   qty: number,
//   deltaplan: number,
//   deltadone: number,
//   deltatotal: number,
//   plan: number,
//   done: number,
//   total: number,
//   src: string,
// };

// const dogSchema = z.object<META_INV_COUNT>({
//   name: z.string().min(3),
//   neutered: z.boolean(),
// });


export const META_INV_COUNT_ZOD = z.object({
  itempath: z.string(),
  when: z.date(),
  status: z.enum(['PLAN', 'DONE', 'CANCEL']),
  adj: z.enum(['-', '+', '=']),
  qty: z.number(),
  deltaplan: z.number(),
  deltadone: z.number(),
  deltatotal: z.number(),
  plan: z.number(),
  done: z.number(),
  total: z.number(),
  src: z.string(),
});
export type META_INV_COUNT = z.infer<typeof META_INV_COUNT_ZOD>;

export const META_INV_COUNT_DAILY_ZOD = z.object({
  when: z.string(),
  deltaplan: z.number(),
  deltadone: z.number(),
  deltatotal: z.number(),
  plan: z.number(),
  done: z.number(),
  total: z.number(),
  src: z.array(META_INV_COUNT_ZOD),
});
export type META_INV_COUNT_DAILY = z.infer<typeof META_INV_COUNT_DAILY_ZOD>;

export class MetaInvStorage {
  records: Record<string, META_INV_DELTA> = {};
  levels: Record<string, META_INV_COUNT[]> = {};

  static key(item: META_INV_DELTA) { return item.srcid + "," + item.srclabel; }
  add(...items: META_INV_DELTA[]) {
    if (Array.isArray(items) && items.length === 1 && Array.isArray(items[0]))
      items = items[0];

    for (const item of items) {
      if (item != undefined) {
        this.records[MetaInvStorage.key(item)] = META_INV_DELTA_ZOD.parse(item);
      }
    }
  }
  delete(srcidPrefix: string) {
    let currentKeys = Object.keys(this.records).filter((k) => k.startsWith(srcidPrefix));
    for (const keyToDelete of currentKeys) {
      delete this.records[keyToDelete];
    }
  }
  replace(srcid: string, ...items: META_INV_DELTA[]) {
    this.delete(srcid);
    this.add(...items);
  }

  keys() {
    return new Set(Object.keys(this.records));
  }

  itempaths() {
    return new Set(Object.entries(this.records).map(([key, value]) => value.itempath));
  }

  processLevels() {
    let processing: META_INV_DELTA[] = Object.values(this.records);
    // processing.push({
    //   item: 'BrewTankCount',
    //   itempath: 'BrewTankCount',
    //   when: new Date('2023-01-01'),
    //   status: "PLAN", // This is setting resource availability, so PLAN
    //   adj: "=",
    //   qty: 2,
    //   srcid: "Na",
    //   srclabel: "Hardcoded BrewTankCount start"
    // });

    // Sort reverse itempath, ASC when. (this correctly groups deepest itempaths first)
    processing = processing.sort((a, b) => {
      //console.log("sort: ", a, b);
      return b.itempath.localeCompare(a.itempath) || (a.when.getTime() - b.when.getTime())
    });
    let data = processing;

    // Get unique item paths, keeping order from above
    let orderedItemPaths = [];
    {
      let currentItemPath = null;
      for (let row of data) {
        if (currentItemPath != row.itempath) {
          orderedItemPaths.push(row.itempath);
        }
        currentItemPath = row.itempath;
      }
    }

    this.levels = {};
    for (let cItemPath of orderedItemPaths) {
      //console.log("xxxx Processing: ", cItemPath, data.filter((r) => r.itempath.startsWith(cItemPath)).length);
      let lastTally = { plan: 0, done: 0, total: 0 }

      if (this.levels[cItemPath] === undefined) { this.levels[cItemPath] = []; }
      let currentLevel = this.levels[cItemPath];

      // Process all children itempath entries, order by time first (works for one itempath, or summ w/ children)
      let currentItemPathDataToProcess = data.filter((r) => r.itempath == cItemPath);
      let preLevelsToProcess = Object.entries(this.levels).filter(([k, v]) => k != cItemPath && k.startsWith(cItemPath)).flatMap(([k, v]) => v);
      let dataToProcess = [...currentItemPathDataToProcess, ...preLevelsToProcess];
      dataToProcess = dataToProcess.sort((a, b) => (a.when.getTime() - b.when.getTime()) || b.itempath.localeCompare(a.itempath));

      for (let row of dataToProcess) {
        let { plan, done, total } = lastTally;
        if (row.status === 'PLAN') {
          if (row.adj === '-') plan -= row.qty;
          if (row.adj === '+') plan += row.qty;
          if (row.adj === '=') plan = row.qty;
        } else if (row.status === 'DONE') {
          if (row.adj === '-') done -= row.qty;
          if (row.adj === '+') done += row.qty;
          if (row.adj === '=') done = row.qty;
        }
        total = plan + done;

        currentLevel.push({
          itempath: row.itempath,
          when: row.when,
          status: row.status,
          adj: row.adj,
          qty: row.qty,
          plan: plan,
          done: done,
          total: total,
          deltaplan: plan - lastTally.plan,
          deltadone: done - lastTally.done,
          deltatotal: total - lastTally.total,
          // @ts-ignore - Generate was complaining that srclabel didn't exist. Didn't have time to figure out what everything else thought it was fine.
          src: ('src' in row) ? row.src : row.itempath + " --> " + row.srcid + "|" + row.srclabel,
        });
        lastTally.plan = plan;
        lastTally.done = done;
        lastTally.total = total;
      }
    }



    return this.levels;
    // return orderedItemPaths;
    //data.map((d) => { return { itempath: d.itempath, when: d.when }; });
  }

  daily(itemprefix: string): META_INV_COUNT_DAILY[] {
    const levels: META_INV_COUNT[] | undefined = this.levels[itemprefix];
    const dailySumms: META_INV_COUNT_DAILY[] = [];

    const addTo = (input: META_INV_COUNT, existing?: META_INV_COUNT_DAILY): META_INV_COUNT_DAILY => {
      const inputWhenDateStr = utils.toDayStr(input.when);
      if (existing && existing.when != inputWhenDateStr)
        throw new Error("addTo when's don't match: " + existing.when + " / " + inputWhenDateStr);

      if (existing === undefined)
        existing = {
          when: inputWhenDateStr,
          deltaplan: 0,
          deltadone: 0,
          deltatotal: 0,
          plan: 0,
          done: 0,
          total: 0,
          src: []
        }

      existing.deltaplan += input.deltaplan;
      existing.deltadone += input.deltadone;
      existing.deltatotal += input.deltatotal;

      if (existing != undefined) {
        existing.plan += input.deltaplan;
        existing.done += input.deltadone;
        existing.total += input.deltatotal;
      }

      existing.src.push(input);

      return existing;
    }

    if (levels) {
      let currSumm = null;
      for (const lvl of levels) {
        const lvlWhenDateStr = utils.toDayStr(lvl.when);
        if (currSumm != null && currSumm.when == lvlWhenDateStr) {
          addTo(lvl, currSumm);
        } else {
          currSumm = addTo(lvl)
          dailySumms.push(currSumm);
        }
      }

      let currDaily = null;
      for (const daily of dailySumms) {
        if (currDaily != null) {
          daily.plan += currDaily.plan;
          daily.done += currDaily.done;
          daily.total += currDaily.total;
        }
        currDaily = daily;
      }
    }

    return dailySumms;
  }

}




// export type META_INV_DELTA = {
//   item: string, //@table/id|lot#
//   group: string, //@table/id|etc...

//   when: Date,
//   status: 'PLAN' | 'DONE' | 'CANCEL',
//   adj: '-' | '+' | '=',
//   qty: number,

//   srcid: RefID, // Row this comes from
//   srclabel: string, // field|pos|subpart

//   //   // Computed fields, will need to be recomputed depending on scope (should probably be right for lowest lvl)
//   //   plan: number,
//   //   done: number,
//   //   total: number,
// }



// export type AsyncFormatter2<T extends Record<string, any>, OUT extends Record<string, any>> = {
//   deps: { [key in keyof T]: string | T[key] | (string | T[key])[] },
//   format: (depValues: T) => Promise<OUT | OUT[]>,
// }

// export const invtoprocessDeps = {
//   item: ["@fermenttank", "\@EquipFermentationTank/"], // gid of tank, or apply to prefix of all tanks
//   group: "", // Or location
//   start: "@start_brew",
//   end: "@end_brew",
//   status: "@status",
//   adj: '-',
//   qty: ["@amount", -1],

//   srcid: "@$gid", // Row this comes from
//   srcfieldlabel: "$inv_delta-brewtank", // field|pos|subpart
// }
// function invtoprocessfunc(input: Record<keyof typeof invtoprocessDeps, any>): META_INV_DELTA[] {
//   const { item, group, start, end, status, adj, qty, srcid, srcfieldlabel } = input;
//   return [{ item, group, when: start, status, adj: '-', qty, srcid, srclabel: srcfieldlabel + "|0|" }]
// }



// export type AsyncFormatter3<T extends Record<string, any>> = {
//   deps: { [key in keyof T]: string | T[key] | (string | T[key])[] },
//   process: (depValues: T) => Promise<any>,
// }

// function createInvTo<T extends Record<string, any>>(deps: { [key in keyof T]: string | T[key] | (string | T[key])[] }, procFunc: (deps: T) => Promise<any>) {
//   let invToProcessAsync: AsyncFormatter3<T> = {
//     deps: deps,
//     process: procFunc,
//   }
//   return invToProcessAsync;
// }

// const testinvto = createInvTo(invtoprocessDeps,
//   async (deps) => {
//     const { item, group, start, end, status, adj, qty, srcid, srcfieldlabel } = deps;
//     return [{ item, group, when: start, status, adj: '-', qty, srcid, srclabel: srcfieldlabel + "|0|" }]
//   }
// );

// export type AsyncFormatter4<DEPS extends Record<string, any>> = {
//   deps: DEPS,
//   process: (depValues: { [key in keyof DEPS]: UnpackedArrayType<DEPS[key]> }) => Promise<any>,
// }

// function createInvTo2<DEPS extends Record<string, any>>(deps: DEPS, procFunc: (deps: { [key in keyof DEPS]: UnpackedArrayType<DEPS[key]> }) => Promise<any>) {
//   let invToProcessAsync: AsyncFormatter4<DEPS> = {
//     deps: deps,
//     process: procFunc,
//   }
//   return invToProcessAsync;
// }

// const testinvto2 = createInvTo2(invtoprocessDeps,
//   async (deps) => {
//     const { item, group, start, end, status, adj, qty, srcid, srcfieldlabel } = deps;
//     return [{ item, group, when: start, status, adj: '-', qty, srcid, srclabel: srcfieldlabel + "|0|" }]
//   }
// );








// const zod_META_INV_DELTA_IN = z.object({
//   item: z.string(), //@table/id|lot#
//   group: z.string(), //@table/id|etc...

//   start: z.date(),
//   end: z.date(),
//   status: z.enum(['PLAN', 'DONE', 'CANCEL']),
//   adj: z.enum(['-', '+', '=',]),
//   qty: z.number(),

//   srcid: z.string().regex(RefID.regex), // Row this comes from
//   srclabel: z.string(), // field|pos|subpart
// });
// type META_INV_DELTA_IN = z.infer<typeof zod_META_INV_DELTA_IN>;

// type ArrayType<T> = T extends any[] ? T : T | T[];
// type UnpackedArrayType<T> = T extends (infer U)[] ? U : T;

// type META_INV_DELTA_DEPSDEF = {
//   [key in keyof META_INV_DELTA_IN]: string | META_INV_DELTA_IN[key] | (string | META_INV_DELTA_IN[key])[]
// }
// // type META_INV_DELTA_DEPS = {
// //   [key in keyof META_INV_DELTA]: UnpackedArrayType<META_INV_DELTA[key]>
// // }


// export const invtoprocessDeps2: META_INV_DELTA_DEPSDEF = {
//   item: ["@fermenttank", "\@EquipFermentationTank/"], // gid of tank, or apply to prefix of all tanks
//   group: "", // Or location
//   start: "@start_brew",
//   end: "@end_brew",
//   status: "@status",
//   adj: '-',
//   qty: ["@amount", -1],

//   srcid: "@$gid", // Row this comes from
//   srclabel: "$inv_delta-brewtank", // field|pos|subpart
// };

// let t5: AsyncFormatter<META_INV_DELTA> = {
//   deps: invtoprocessDeps2,
//   process: async (depValues): Promise<META_INV_DELTA[]> => {

//     // get META_INV_DELTA type from Zod? Let it process verification?

//     const { item, group, start, end, status, adj, qty, srcid, srcfieldlabel } = depValues;
//     let startinv: META_INV_DELTA = { item, group, when: start, status, adj: '-', qty, srcid, srclabel: srcfieldlabel + "|0|" };

//     return [startinv];
//   }
// }









// //
// // Inventory Meta testing
// //
// inv_item: {
//   $key: "inv_item",
//   $name: "Inv Item",
//   $summtmpl: { $type: TYPES.DSOTMPL, part: "~Kname" },
//   $metatypes: {
//     // Defaults: path:"@$pid", unit: "unit"
//     "$inv.item": { $type: "%inv.item" },
//   },
//   name: { $datatype: 'STR' },
//   size: { $datatype: 'NUM' },
//   color: { $datatype: 'TEXT' },
//   first_seen: { $datatype: 'DATE', $name: "First Seen" },
// },
// inv_tally: {
//   $key: "inv_tally",
//   $name: "Tally",
//   $summtmpl: { $type: TYPES.DSOTMPL, part: ["Tally", "~Ktarget", "~Kqty", "~Kwhen"], delimeter: " " },
//   $metatypes: {
//     // Defaults: target:"@target", group: "@location", when: "@when", qty: "@qty", adjtype: null, status: "@status"
//     "$inv.delta:tally": { $type: "%inv.delta", adjtype: "=" }
//   },
//   target: { $datatype: 'ID' },
//   qty: { $datatype: 'NUM' },
//   when: { $datatype: 'DATE' },
//   status: { $datatype: 'TEXT', $extrahints: ["PLAN", "DONE", "CANCEL"] },
// },
// inv_receive: {
//   $key: "inv_receive",
//   $name: "Receive",
//   $summtmpl: { $type: TYPES.DSOTMPL, part: ["Receive", "~Ktarget", "~Kqty", "~Kwhen"], delimeter: " " },
//   $metatypes: {
//     // Defaults: target:"@target", group: "@location", when: "@when", qty: "@qty", adjtype: null, status: "@status"
//     "$inv.delta:receive": { $type: "%inv.delta", adjtype: "+" }
//   },
//   target: { $datatype: 'ID' },
//   qty: { $datatype: 'NUM' },
//   when: { $datatype: 'DATE' },
//   status: { $datatype: 'TEXT', $extrahints: ["PLAN", "DONE", "CANCEL"] },
// },
// inv_consume: {
//   $key: "inv_consume",
//   $name: "Item Consume",
//   $summtmpl: { $type: TYPES.DSOTMPL, part: ["Consume", "~Ktarget", "~Kqty", "~Kwhen"], delimeter: " " },
//   $metatypes: {
//     // Defaults: target:"@target", group: "@location", when: "@when", qty: "@qty", adjtype: null, status: "@status"
//     "$inv.delta:consume": { $type: "%inv.delta", adjtype: "-" }
//   },
//   target: { $datatype: 'ID' },
//   qty: { $datatype: 'NUM' },
//   when: { $datatype: 'DATE' },
//   status: { $datatype: 'TEXT', $extrahints: ["PLAN", "DONE", "CANCEL"] },
// },
// inv_use: {
//   $key: "inv_use",
//   $name: "Reserve Item",
//   $summtmpl: { $type: TYPES.DSOTMPL, part: ["Consume", "~Ktarget", "~Kqty", "~Kwhen"], delimeter: " " },
//   $metatypes: {
//     // Defaults: target:"@target", group: "@location", when: "@when", qty: "@qty", adjtype: null, status: "@status"
//     "$inv.delta:start": { $type: "%inv.delta", adjtype: "-", when: "@start", qty: 1 },
//     "$inv.delta:end": { $type: "%inv.delta", adjtype: "+", when: "@end", qty: 1 },
//   },
//   target: { $datatype: 'ID' },
//   qty: { $datatype: 'NUM' },
//   start: { $datatype: 'DATE' },
//   end: { $datatype: 'DATE' },
//   status: { $datatype: 'TEXT', $extrahints: ["PLAN", "DONE", "CANCEL"] },
// },
