import { Brew_Fields, DBBrewTable } from "../../shared/vdb/vdbsharedclass";
import { GenericEditProps } from "./GenericEdit";
import { useState } from "react";
import { Button, Divider, Grid, LinearProgress, Paper } from "@mui/material";

import getdb from 'client/hopiqvdb';
import { MUISaveButton, RowText } from "client/vdb/mui/MUIEditParts";
import { EditFieldMUI } from "client/vdb/mui/MUIEditFields";
import MUIEmbedArrayEdit from "client/vdb/mui/MUIEmbedArrayEdit";
import { processBrewLoadRecipe } from "shared/HopIQUtils";
const vdb = getdb('BrewSessionEdit');

// type ImplGenericEdit = <FIELDS extends STR_KEY_MAP, TABLE extends DBTable<FIELDS, any, any, any, any>>(props: React.PropsWithChildren<GenericEditProps<FIELDS, TABLE>>) => JSX.Element;
// <FIELDS extends STR_KEY_MAP, DBBrewSessionTable extends DBTable<FIELDS, any, any, any, any>>

export function BrewSimpleEdit(props: GenericEditProps<Brew_Fields, DBBrewTable>) {
  const edit = props.row;
  const tableSchema = edit.$schema;
  const isNew = (edit && edit.$id < 0);

  return <>
    <Paper variant="outlined" style={{
      // position: 'sticky', zIndex: 1,
      top: '0px', paddingLeft: "8px", paddingRight: "8px", marginTop: "8px", marginBottom: "8px",
      backgroundColor: '#ececec'
      // backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText
      // 'rgba(200,200,200,.85)'
    }} data-editcomponent="BrewSimpleEdit" data-row-gid={edit.$gid} data-row-key={edit.$key}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h2>
            {(isNew) ? "New " : "Edit "} {tableSchema.name}<RowText row={edit} field="$name" prefix=": " /> {(isNew) ? "" : ` (#${props.row.$id})`}
          </h2>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '22px', paddingTop: '30px' }}>
          {/* container direction="row" alignItems="center" justify="flex-end" */}
          {edit && <Button variant="outlined" style={{ marginRight: 8 }}
            onClick={() => {
              if (window.confirm("Replace durations and ingredients from selected recipe?")) {
                processBrewLoadRecipe(edit).then(
                  (good) => { console.log("processBrewLoadRecipe good", good) },
                  (bad) => { console.log("processBrewLoadRecipe bad", bad) }
                );
              }
            }}
          >Load from Recipe</Button>}
          {edit && <MUISaveButton edit={edit} />}
        </Grid>
      </Grid>
    </Paper >
    <BrewSimpleEditComponent row={edit} />
  </>;
}

export function BrewSimpleEditComponent(props: GenericEditProps<Brew_Fields, DBBrewTable>) {
  const edit = props.row;
  const tableSchema = edit.$schema;
  const isNew = (edit && edit.$id < 0);

  return <>
    {!edit && <LinearProgress />}
    {edit && <>

      <Grid container spacing={2} data-id-wrap={edit.$gid}>

        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="recipe" fullWidth={true} /></Grid>
        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="amount" fullWidth={true} /></Grid>
        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="start_brew" fullWidth={true} /></Grid>
        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="status" fullWidth={true} /></Grid>
        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="brewduration" fullWidth={true} /></Grid>
        <Grid item lg={4} md={6} xs={12}><EditFieldMUI edit={edit} field="notes" fullWidth={true} /></Grid>

        <Grid item xs={12}><EditFieldMUI edit={edit} field="fermentation" fullWidth={true} /></Grid>

        <Grid item xs={12}><MUIEmbedArrayEdit edit={edit} field={"ingredients"} /></Grid>

      </Grid>
    </>
    }

  </>;


}