import { string } from "zod";
import { DBRowEdit } from "./DBRowEdit";

export enum DATA_TYPES { STR = 'STR', NUM = 'NUM', BOOL = 'BOOL', TEXT = 'TEXT', ID = 'ID', DATE = 'DATE', LINKIN = 'LINKIN', EMBED_ARRAY = 'EMBED_ARRAY' };
export type DATA_TYPES_STR = 'STR' | 'NUM' | 'BOOL' | 'TEXT' | 'ID' | 'DATE' | 'LINKIN' | 'EMBED_ARRAY';
type DATA_TYPES_ALL = DATA_TYPES_STR | DATA_TYPES

type statusTypes = 'PLAN' | 'DONE' | 'CANCEL';
type adjustTypes = '+' | '-' | '=';

// type META_INV_DELTA = {
//   target?: string[],
//   // group?
//   when?: string[],
//   qty?: string[],
//   unit?: string[],
//   status?: string[],
//   adjustType: string[],
// }
// : META_INV_DELTA 


const DEFAULTS_META_INV_DELTA: Record<string, SCHEMA_META_TYPE_VALUES> = {
  target: ['@target'],
  // group?
  when: ['@when'],
  qty: ['@qty'],
  unit: ['@target>%inv_item/unit'],
  status: ['@status'],
  adjustType: ['@adjustType'],
}


export type SCHEMA_META_TYPE_VALUES = (string | number) | (string | number)[] | CalcFormatter | { unit: string, group?: string };
export type META_TYPE_DEF = Record<string, SCHEMA_META_TYPE_VALUES> | AsyncFormatter<any>;
// {
//   [key: string]: CalcDefinitions
// }


export type SCHEMA_META_TYPES = Record<string, META_TYPE_DEF> | {
  // This was an idea to put $name calc into metatype definition blocks ... prob not the best location since it's a different type of implementation for resolving references
  '$name'?: CalcFormatter,
  // '$inv_item'?: { unit: string, group?: string },
  '$inv_delta'?: typeof DEFAULTS_META_INV_DELTA,
  '$inv_delta_start'?: typeof DEFAULTS_META_INV_DELTA,
  '$inv_delta_end'?: typeof DEFAULTS_META_INV_DELTA,
};
//  & {
//   [key: string]: META_TYPE_DEF
// };

export interface SchemaTable {
  type: "Table",
  key: string,
  name: string,
  fields: { [fieldName: string]: SchemaField },
  // path?: string, - schema doesn't have a path, instances do
  metatypes?: SCHEMA_META_TYPES,
}

export interface ChildTable extends SchemaTable {
  parent: string,
}

export function isSchemaTable(x: SchemaTable | ChildTable): x is SchemaTable {
  return 'type' in x && x.type === "Table" && ('parent' in x === false);
}

export function isChildTable(x: SchemaTable | ChildTable): x is ChildTable {
  return 'type' in x && x.type === "Table" && ('parent' in x === true);
}


export type SchemaField = IFieldBase & (IFieldBoolean | IFieldDate | IFieldLinkIn | IFieldEmbed | IFieldID | IFieldNum | IFieldStr | IFieldText);

export type FieldValidationMethod = (v: any, field: string, rowEdit: DBRowEdit<any>) => { error: string } | { warn: string } | string | undefined;

// Perhaps we should seperate out 'value' fields from grouping fields? 
/** This is not a 'primative' field, but a grouping embed field */
export type IFieldEmbed = {
  $datatype: DATA_TYPES.EMBED_ARRAY | 'EMBED_ARRAY',
  // type: ChildTable, //IEmbed | IEmbed[],
  fields: string[],
  TODOuniqueFields?: string[],
}

type IFieldBase = {
  $name?: string,
  $datatype: DATA_TYPES_ALL,
  $defaultValue?: any,
  index?: true,
  $embed?: string, 
  /** Default 0=no array, 1=true, array of any size, >1=limit array to this size */
  $array?: number, // Default 0=no array, 1=true, array of any size, >1=limit array to this size
  required?: boolean | string,
  validation?: FieldValidationMethod | FieldValidationMethod[],
  calc?: CalcFormatter,
  values?: any[],
  info?: string,
  /** Don't display this field alone, but only as part of a grouping (usually array of embedded obj) */
  embedIn?: string,
}

type IFieldBoolean = {
  $datatype: DATA_TYPES.BOOL | 'BOOL',
}
type IFieldDate = {
  $datatype: DATA_TYPES.DATE | 'DATE',
}
export type IFieldLinkIn = {
  $datatype: DATA_TYPES.LINKIN | 'LINKIN',
  type: ChildTable, //IEmbed | IEmbed[],
  linkField: string,
  order: string,
}
export type IFieldID = {
  $datatype: DATA_TYPES.ID | 'ID',
  /** key name of table to link to, as "@/tableKey" */
  table?: string,
  tables?: string[],
  $query?: string,
}
type IFieldNum = {
  $datatype: DATA_TYPES.NUM | 'NUM',
  prec?: number,
  unit?: string,// | string[],
}
type IFieldStr = {
  $datatype: DATA_TYPES.STR | 'STR',
  values?: string[],
}
type IFieldText = {
  $datatype: DATA_TYPES.TEXT | 'TEXT',
}

export function assertUnreachableFieldDataType(fieldDataType: never, name: string): never {
  throw new Error("Unable to resolve Prisma type for field '" + name + "' type " + fieldDataType);
  throw new Error("Didn't expect to get here");
}

export const dataTypeToPrismaType = (datatype: DATA_TYPES_ALL, name: string) => {
  switch (datatype) {
    case DATA_TYPES.STR:
    case DATA_TYPES.STR:
    case 'STR':
      return "String";
    case DATA_TYPES.NUM:
    case 'NUM':
      return "Float";
    case DATA_TYPES.BOOL:
    case 'BOOL':
      return "Boolean";
    case DATA_TYPES.TEXT:
    case 'TEXT':
      return "String";
    case DATA_TYPES.ID:
    case 'ID':
      return "String";
    case DATA_TYPES.DATE:
    case 'DATE':
      return "DateTime";
    case DATA_TYPES.LINKIN:
    case 'LINKIN':
      return null;
    case DATA_TYPES.EMBED_ARRAY:
    case 'EMBED_ARRAY':
      return null;
    default:
      // If there is a ts error on the next line, you aren't covering all fieldDataTypes
      assertUnreachableFieldDataType(datatype, name);
  }

}

export const dataTypeToJSType = (field: SchemaField, name: string,) => {
  let jstype = dataTypeToJSTypeOnly(field, name);
  return (field.$array != undefined && field.$array >= 0) ? jstype + "[]" : jstype;
}

export const dataTypeToJSTypeOnly = (field: SchemaField, name: string,) => {
  //datatype: DATA_TYPES_ALL, 
  let datatype = field.$datatype;

  switch (datatype) {
    case DATA_TYPES.STR:
    case 'STR':
      return "string";
    case DATA_TYPES.NUM:
    case 'NUM':
      return "number";
    case DATA_TYPES.BOOL:
    case 'BOOL':
      return "boolean";
    case DATA_TYPES.TEXT:
    case 'TEXT':
      return "string";
    case DATA_TYPES.ID:
    case 'ID':
      return "string";
    case DATA_TYPES.DATE:
    case 'DATE':
      return "Date";
    case DATA_TYPES.LINKIN:
    case 'LINKIN':
      return "string"; // Should be none?
    case DATA_TYPES.EMBED_ARRAY:
    case 'EMBED_ARRAY':
      return "string"; // Should be none?
    // return (field as IFieldLinkIn).type.key + "_Fields";
    default:
      // If there is a ts error on the next line, you aren't covering all fieldDataTypes
      assertUnreachableFieldDataType(datatype, name);
  }

}

export interface PrismaBase extends PrismaShared {
  s_id: number,
}

export interface PrismaNew extends PrismaShared {
  s_id: null,
}

export interface PrismaShared {
  s_id: number | null,
  s_st: number
  s_pv: Date
  s_v: Date
  s_key: string
  s_name: string | null
  s_path: string | null
  s_json: string
}

export interface IRaw {
  $table: string
  $id: number
  $st: number
  $pv: Date
  $v: Date
  $key?: string
  $name?: string
  _$name?: string
  $path?: string
}

export enum STATUS {
  NEW = 0,
  ACTIVE = 1,
  DRAFT = 3,
  DELETED = 4,
  UNKNOWN = 8,
}

export type STATUS_STRINGS = "NEW" | "ACTIVE" | "DRAFT" | "DELETED" | "UNKNOWN";

export function statusToString(s: STATUS) {
  return (s === STATUS.NEW) ? "NEW" :
    (s === STATUS.DELETED) ? "DELETED" :
      (s === STATUS.ACTIVE) ? "ACTIVE" :
        (s === STATUS.DRAFT) ? "DRAFT" :
          "UNKNOWN";
}

export function statusStringToNum(s: "NEW" | "ACTIVE" | "DRAFT" | "DELETED" | "UNKNOWN" | string): STATUS {
  return (s === "NEW") ? STATUS.NEW :
    (s === "DELETED") ? STATUS.DELETED :
      (s === "ACTIVE") ? STATUS.ACTIVE :
        (s === "DRAFT") ? STATUS.DRAFT :
          STATUS.UNKNOWN;
}

export type GRawSystem = {
  $table: any,
  $id: number,
  $st: any,
  $pv: any,
  $v: any,
  $key?: any,
  $name?: any,
  $path?: any,
  $fullpath?: string[],
} & STR_KEY_MAP;
export const PREFIX_CALC_CHAR = '_';
export type STR_KEY_MAP = Record<string, any>; //{ [key: string]: any };

export type GRawFields<FIELDS extends STR_KEY_MAP> = { [key in keyof FIELDS]?: FIELDS[key] };
export type GRawCalc<FIELDS extends STR_KEY_MAP> = { [key in keyof FIELDS as `_${key & string}`]?: FIELDS[key] };
export type GRawEither<FIELDS extends STR_KEY_MAP> = { [key in keyof FIELDS as `?${key & string}`]?: FIELDS[key] };

export type GRaw<FIELDS extends STR_KEY_MAP> =
  // System fields
  GRawSystem
  // VDB Database fields
  & GRawFields<FIELDS>
  // Calc fields
  & GRawCalc<FIELDS>;

export type QUERY_REQ = { filter: any } // Add count, skip, etc here?
export type SAVE_SINGLE_REQ<RAWTYPE extends STR_KEY_MAP> = { merged: GRaw<RAWTYPE>, edits: Partial<GRaw<RAWTYPE>> } // Add count, skip, etc here?



/*
 * Display Formatters are simple Refs or literal values. Used to make calc string values.
 */
export function isCalcFormatter(input: unknown): input is CalcFormatter {
  return (input !== null && typeof input === 'object' && 'deps' in input && Array.isArray(input.deps) && 'format' in input && typeof input.format === 'function');
}
/** Deps must be all refs or string literals. If format func is undefined, return first non-null (processed ref) from array. */
export type CalcFormatter = {
  deps: string[],
  format?: (depValues: Array<any> | any) => any,
}
export type CalcFormatterGeneric<DEPS extends Array<string>> = {
  deps: DEPS,
  format?: (depValues: ArrayToAny<DEPS>) => any,
}
type ArrayToAny<T extends any[]> = { [I in keyof T]: (any) };
/** Preferred way to create CalcFormatter, checks array size of deps & formatter args */
export function newCalcFormat<DEPS extends any[]>(deps: [...DEPS], formatter?: (depValues: ArrayToAny<DEPS>) => any): {
  deps: DEPS,
  format?: (depValues: ArrayToAny<DEPS>) => any,
} {
  return {
    deps: deps,
    format: formatter,
  }
}
export type CalcFormatterDefinition = string | string[] | CalcFormatterGeneric<string[]>;


// /*
//  * Display Formatters are simple Refs or literal values. Used to make calc string values.
//  */
// export function isDisplayFormatter(input: unknown): input is DisplayFormatterGeneric<string[]> {
//   return (input !== null && typeof input === 'object' && 'deps' in input && Array.isArray(input.deps) && 'format' in input && typeof input.format === 'function');
// }
// /** Use to resolve calc values. Deps can be references or string literals. If format func is undefined, same rules as DisplayDefinitions */
// export type DisplayFormatter = {
//   deps: Array<string>,
//   format: (depValues: Array<any> | any) => string,
// }
// /** Use to resolve calc values. Deps can be references or string literals. If format func is undefined, same rules as DisplayDefinitions */
// export type DisplayFormatterGeneric<DEPS extends Array<string>> = {
//   deps: DEPS,
//   format: (depValues: ArrayToAny<DEPS>) => string,
// }
// /** Resolve strings or .deps as references or string literals. In array, take first resolved value that isn't null or an empty string. */
// export type DisplayDefinitions = string | string[] | DisplayFormatterGeneric<string[]>;




/*
 * AsyncFormatters are Refs, RefChains or literal values. Used to make metatype values. Output can be non-strings.
 */
export function isAsyncFormatter<T extends Record<string, any>, O extends Record<string, any>>(input: unknown): input is AsyncFormatter<T> {
  return (input !== null && typeof input === 'object' && 'deps' in input && 'process' in input && typeof input.process === 'function');
}
type UnpackedArrayType<T> = T extends (infer U)[] ? U : T;
export type AsyncFormatter<DEPS extends Record<string, any>> = {
  deps: DEPS,
  process: (fieldName: string, arrayPos: number, depValues: { [key in keyof DEPS]: UnpackedArrayType<DEPS[key]> }) => Promise<any>,
}







