import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Divider, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import getdb from 'client/hopiqvdb';
import { AutoMUIEditLayout } from 'client/vdb/mui/AutoMUIEditLayout';
import { EditFieldMUI } from 'client/vdb/mui/MUIEditFields';
import { RowText } from 'client/vdb/mui/MUIEditParts';
import useVDB, { useVDBRowEdit } from 'client/vdb/useVDB';
import { useEffect, useMemo, useState } from 'react';
import { DBRowEdit } from 'shared/vdb/DBRowEdit';
import { IDBRow } from 'shared/vdb/VaultDB';
import { Brew_Fields, DBBrew } from 'shared/vdb/vdbsharedclass';
const vdb = getdb("BrewHome", false);


export default function DialogStartBrew(props: { dbbrew: DBBrew | null, onDone: (gid?: string) => void }): JSX.Element | null {
  const dbbrew = props.dbbrew;
  const [edit, error] = useVDBRowEdit(vdb, props.dbbrew?.$gid);
  // const edit = useMemo(async () => {
  //   if (props.dbbrew != null) {
  //     let row = (await vdb.get(props.dbbrew.$gid));
  //     if (row)
  //       return row.edit();
  //     else
  //       return null;
  //   } else {
  //     return null;
  //   }
  // }, [props.dbbrew]);

  const fullScreen = useMediaQuery('(max-width:600px)');

  const [show, setShow] = useState<"select" | "startbrew" | "changestart" | "delete">("select");

  const closeDialog = (gid?: string) => {
    Object.values(vdb.vdbEditBranch.edits).forEach((edit) => edit.clearAllEdits())
    props.onDone(gid);
  };

  if (edit !== undefined) {
    let subtitle = "Selected";
    let subcomponent =
      (<>
        <Typography variant="button" >Actions:</Typography>
        <br />
        <Button sx={{ margin: 1 }} variant='contained' onClick={() => setShow("startbrew")}>Start Brew</Button><br />
        <Button sx={{ margin: 1 }} variant='contained' onClick={() => setShow("changestart")}>Change Planned Start</Button><br />
        <Button sx={{ margin: 1 }} variant='contained' onClick={() => setShow("delete")}>Delete</Button><br />
      </>
      );


    if (show === "startbrew") {
      subtitle = "Start Brew";
      subcomponent = <DialogStartBrewEdit brewedit={edit} onDone={(isChange: boolean) => { setShow("select"); closeDialog(isChange ? edit.$gid : undefined); }} />
    }
    else if (show === "changestart") {
      subtitle = "Change Planned Start:";
      subcomponent = <DialogChangeStartEdit brewedit={edit} onDone={(isChange: boolean) => { setShow("select"); closeDialog(isChange ? edit.$gid : undefined); }} />
    }
    else if (show === "delete") {
      subtitle = "Delete Brew";
      subcomponent = <Button onClick={() => { setShow("select"); }}>DELETE HERE</Button>
    }


    return (
      <Dialog open={true} fullScreen={fullScreen} onClose={() => closeDialog()}>
        <Box sx={{ padding: 2 }}>
          <DialogTitle sx={{ paddingLeft: 0, paddingBottom: 0, position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            {subtitle}: <RowText row={edit} field='$name' />
            <Divider variant='fullWidth' style={{ borderColor: 'rgba(0, 0, 0, 0.32)' }} />
          </DialogTitle>

          <Divider />
          {subcomponent}
        </Box>

      </Dialog>
    );

  } else {
    return (
      <Dialog open={false} fullScreen={fullScreen} onClose={() => closeDialog()}></Dialog>
    );
  }
  return null;
}


export function DialogChangeStartEdit(props: { brewedit: DBRowEdit<Brew_Fields>, onDone: (isChange: boolean) => void }) {
  const brew = props.brewedit;
  const [error, setError] = useState<string | null>(null);

  const onCancel = () => {
    brew.clearAllEdits();
    props.onDone(false);
  };
  const onStartBrew = async () => {
    try {
      await brew.branch.save("Brew-ChangePlanStart", false);
    } catch (error) {
      setError(JSON.stringify(error));
      return;
    }
    props.onDone(true);
  };

  return (
    <Container>
      <Typography variant='overline'>Please verify the values below:</Typography>
      <div style={{ height: '4px' }} />

      {error && <Typography color='error' variant='overline'>{error}</Typography>}
      {(brew != null) && <>
        {/* <AutoMUIEditLayout edit={brew} /> */}
        <Grid container>
          <Grid item xs={6}><EditFieldMUI edit={brew} field="start_brew" /></Grid>
        </Grid>
      </>
      }
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onStartBrew}>Change Planned Start</Button>
      </DialogActions>
    </Container>
  );
}

export function DialogStartBrewEdit(props: { brewedit: DBRowEdit<Brew_Fields>, onDone: (close: boolean) => void }) {
  const brew = props.brewedit;
  const [error, setError] = useState<string | null>(null);

  const onCancel = () => {
    brew.clearAllEdits();
    props.onDone(false);
  };
  const onStartBrew = async () => {
    try {
      brew.set('status', 'ACTIVE');
      await brew.branch.save("Brew-StartBrew", false);
    } catch (error) {
      setError(JSON.stringify(error));
      return;
    }
    props.onDone(true);
  };

  return (
    <Container>
      <Typography variant='overline'>Please verify the values below:</Typography>
      <div style={{ height: '4px' }} />

      {error && <Typography color='error' variant='overline'>{error}</Typography>}
      {(brew != null) && <>
        {/* <AutoMUIEditLayout edit={brew} /> */}
        <Grid container>

          <Grid xs={6}><EditFieldMUI edit={brew} field="start_brew" /></Grid>
          <Grid xs={6}><EditFieldMUI edit={brew} field="brewduration" /></Grid>
          <Box sx={{ padding: 1 }}>
            <EditFieldMUI edit={brew} field="fermentation" />
          </Box>
          <Grid xs={12}><Typography variant='button'>Verify Ingredients & Amounts:</Typography></Grid>
          <EditFieldMUI edit={brew} field="ingredients" />
        </Grid>
      </>
      }
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onStartBrew}>Start Brew</Button>
      </DialogActions>
    </Container>
  );
}