import { Button, FormControlLabel, Grid, IconButton, InputLabel, List, ListItem, MuiMediaQueryList, Paper, Typography } from "@mui/material";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { GRaw, STR_KEY_MAP } from "shared/vdb/types"
import { EditFieldMUI, EditFieldProps } from "./MUIEditFields"
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { isEmpty } from "shared/vdb/utils";


export default function MUIEmbedArrayEdit<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) {
  const edit: DBRowEdit<FIELDS> = props.edit;
  const field: keyof GRaw<FIELDS> & string = props.field;
  const fieldSchema = edit.$schema.fields[field];

  if (!fieldSchema || fieldSchema.$datatype != "EMBED_ARRAY") {
    throw new Error("ERROR: Invalid configuration for MUIEmbedArrayEdit, schema not found or type != 'EMBED_ARRAY'. (" + fieldSchema + ")");
  } else {
    const fields = fieldSchema.fields;

    const [length, setLength] = useState(Math.max(1, edit.getLength(...fields)))
    const [dataLength, setDataLength] = useState(edit.getLength(...fields))

    useEffect(() => {
      // In the future, handle all the restricted 'unique' fields?
      let field = fields[0];
      const removeCallback = edit.eventbus.onData(field,
        async (cb) => {
          let currentLength = edit.getLength(field);
          setDataLength(currentLength);
          setLength((l) => Math.max(l, currentLength));
        });

      return removeCallback;
    }, [edit])

    const deleteFieldIdx = (i: number) => {
      for (let field of fields) {
        edit.remove(field, null, i);
      }
      setLength(Math.max(1, edit.getLength(field)));
    }

    const addNew = () => {
      setLength((l) => dataLength + 1);
    }

    let output = [];
    for (let i = 0; i < length; i++) {
      let innerOutput = [];
      let sizes = (fields.length === 1) ? [12, 12, 12] : (fields.length === 2) ? [6, 6, 12] : (fields.length === 3) ? [4, 4, 12] : [3, 6, 12];
      for (let f = 0; f < fields.length; f++) {
        innerOutput.push(
          <Grid key={i + "|" + f} item lg={sizes[0]} md={sizes[1]} xs={sizes[2]} paddingBottom={.5}>
            <EditFieldMUI edit={edit} field={fields[f]} arrayPos={i} fullWidth={true} />
          </Grid>
        );
      }
      output.push(
        <Grid key={i} container justifyContent="space-between" spacing={2} rowSpacing={3} sx={{ ":hover": { backgroundColor: 'rgba(0,0,0,.2)' } }}>
          <Grid item flexGrow={1}>
            <Grid container spacing={2} >
              {innerOutput}
            </Grid>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <IconButton color="error" edge="start" aria-label="delete" onClick={() => { deleteFieldIdx(i) }}>
              <Delete />
            </IconButton>
          </Grid>

        </Grid>
      );
    }

    return <>
      <Paper variant="outlined" sx={{ padding: 3, paddingTop: 0 }} >
        {/* <InputLabel>{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</InputLabel> */}
        {/* <Typography variant="body1">{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography> */}
        {/* <Typography variant="body2">{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography> */}
        {/* <Typography variant="caption">{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography> */}
        {/* <Typography variant="subtitle2">{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography>  */}

        {/* <Typography variant="button">{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography> */}
        <Typography variant="overline" fontSize="1.2rem" noWrap sx={{ position: 'relative' }}>{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography>
        {/* <Typography variant="subtitle1" fontSize="1.1rem" noWrap>{(fieldSchema && fieldSchema.$name) ? fieldSchema.$name : field}</Typography> */}

        {/* Length: {length} / DataLength: {dataLength} */}

        <List key={field} sx={{ paddingTop: 0 }}>
          {output}
          {dataLength == length && (
            <ListItem >
              <Button onClick={addNew}>+Add</Button>
            </ListItem>
          )}
        </List>
      </Paper>
    </>;
  }
};

export function MUIArrayEdit<FIELDS extends STR_KEY_MAP,>(props: EditFieldProps<FIELDS>) {
  const edit: DBRowEdit<FIELDS> = props.edit;
  const field: keyof GRaw<FIELDS> & string = props.field;

  const [newRowPos, setNewRowPos] = useState<number>(0)
  const [prevDisplayLength, setPrevDisplayLength] = useState<number>(edit.getLength(field))

  const [updateTrigger, setUpdateTrigger] = useState<number>(0);

  // When the data changes, update array length if necessary
  useEffect(() => {
    const removeCallback = edit.eventbus.onData(field,
      async (cb) => {
        let currentLength = edit.getLength(field);
        setNewRowPos((oldRowPos) => {
          // return oldRowPos - 1;
          console.log("MUIArrayEdit - in useEffect - need to update newRowPos? ", (currentLength === oldRowPos), { currentLength, oldRowPos, length: edit.getLength(field), value: JSON.stringify(edit.get(field)) });
          if (currentLength === oldRowPos) {
            console.log("MUIArrayEdit - in useEffect - SETTING newRowPos to 0!!! ");
            return 0;
          } else {
            console.log("MUIArrayEdit - in useEffect - leaving newRowPos at prev value of ", oldRowPos);
            return oldRowPos;
          }
        });

        setPrevDisplayLength((prevDisplayLength) => (prevDisplayLength != currentLength) ? currentLength : prevDisplayLength);

        setUpdateTrigger((prev) => prev + 1);
      });
    return removeCallback;
  }, [edit])

  const deleteIdx = (i: number) => {
    // This keeps a new row that doesn't have a value in it, if you delete a higher row that has a value
    if (currentDataLength + 1 == newRowPos) { setNewRowPos((prev) => prev - 1); } else { setNewRowPos(0); }

    edit.remove(field, null, i);
  }

  const currentDataLength = edit.getLength(field);
  // Even if empty, always show 1 (now new entry)
  const rowsToDisplay = Math.max(1, currentDataLength + ((currentDataLength + 1 == newRowPos) ? 1 : 0));
  if (rowsToDisplay !== prevDisplayLength) { setPrevDisplayLength(rowsToDisplay); }

  const addNew = () => {
    setNewRowPos(currentDataLength + 1);
  }

  console.log("MUIArrayEdit", rowsToDisplay, { currentDataLength, newRowPos, value: JSON.stringify(edit.get(field)) });
  let output = [];
  for (let i = 0; i < rowsToDisplay; i++) {
    output.push(
      <ListItem key={i} style={{ paddingTop: 0, paddingLeft: 0 }}
        secondaryAction={
          // (isEmpty(edit.get(field, i)) == false) ?
          <IconButton edge="end" aria-label="delete" onClick={() => deleteIdx(i)}>
            <Delete />
          </IconButton>
          // : undefined
        }>
        <EditFieldMUI edit={edit} field={field} arrayPos={i} showName={(i === 0)} fullWidth={true} />
      </ListItem>

    );
  } // End for length

  return (
    <List key={field} disablePadding>
      {output}
      {currentDataLength == rowsToDisplay && (
        <ListItem key={'addbtn'} disablePadding>
          <Button onClick={addNew}>+Add</Button>
        </ListItem>
      )}
    </List>
  );
};