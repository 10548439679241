import { Route, Routes } from "react-router-dom";

import HopIQIndex from "./hopiq/Index";
import Home from "./Home";
import EditTest from "./EditTest";
import InvTest from "./InvTest";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import getdb from 'client/hopiqvdb';
import TestTRPC from "./TestTRPC";
import TaskChartTest from "./TaskChartTest";
import { Box, Container, Grid, Typography } from "@mui/material";
import Login from "./Login";
const vdb = getdb('EditTest');

export function NotFound() {

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">
              404
            </Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default function Root() {
  let ping = vdb.isBranch();
  return (
    <>
      <Routes>
        <Route path="hopiq/*" element={<HopIQIndex />} />
        <Route path="charttest/*" element={<TaskChartTest />} />
        <Route path="login" element={<Login />} />

        {/* <Route path="dashboard" element={<div>DASHBOARD!!!</div>} />
        <Route path="about" element={<div>about...!!!</div>} />
        <Route path="edittest" element={<EditTest />} />
        <Route path="invtest" element={<InvTest />} />
        <Route path="testtrpc" element={<TestTRPC />} /> */}
        <Route index={true} element={<Home />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}
