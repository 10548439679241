
export default function Home() {

  window.location.assign("/hopiq");

  return <>
    <div id="sidebar">
      <h1>HopIQ - Root Page</h1>
      <nav>
        <ul>
          {/* <li>
            <a href={`edittest`}>edittest</a>
          </li> */}
          <li>
            <a href={`hopiq`}>HopIQ</a>
          </li>
          {/* <li>
            <a href={`testtrpc`}>testtrpc</a>
          </li> */}
        </ul>
      </nav>
    </div>
    <div id="detail"></div>
  </>;
}