import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Divider, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import getdb from 'client/hopiqvdb';
import { AutoMUIEditLayout } from 'client/vdb/mui/AutoMUIEditLayout';
import { EditFieldMUI } from 'client/vdb/mui/MUIEditFields';
import { RowText } from 'client/vdb/mui/MUIEditParts';
import useVDB, { useVDBRowEdit } from 'client/vdb/useVDB';
import { useEffect, useMemo, useState } from 'react';
import { DBRowEdit } from 'shared/vdb/DBRowEdit';
import { IDBRow } from 'shared/vdb/VaultDB';
import { dateFns, isEmpty, toDate, toDayStr, toNum } from 'shared/vdb/utils';
import { Brew_Fields, DBBrew } from 'shared/vdb/vdbsharedclass';
const vdb = getdb("BrewHome", false);


export default function DialogEndBrew(props: { dbbrew: DBBrew | null, onDone: () => void }): JSX.Element | null {
  const dbbrew = props.dbbrew;
  const [edit, error] = useVDBRowEdit(vdb, props.dbbrew?.$gid);

  const fullScreen = useMediaQuery('(max-width:600px)');

  const [show, setShow] = useState<"select" | "endbrew">("select");

  const calculatePlannedEndDate = () => {
    if (edit) {
      const val_end_date = toDate(edit.getEither("_end_brew"));
      if (val_end_date == null) {
        let end_date = toDate(edit.getEither("start_brew"));
        const duration = toNum(edit.getEither("duration"), null);

        if (end_date && duration) {
          end_date = dateFns.addDays(end_date, duration);
        }

        return end_date;
      }
      return val_end_date;
    }
    return null;
  }
  const plannedEndDate = calculatePlannedEndDate();

  const closeDialog = (gid?: string) => {
    Object.values(vdb.vdbEditBranch.edits).forEach((edit) => edit.clearAllEdits())
    props.onDone();
  };

  if (edit !== undefined) {
    let subtitle = "Selected";
    let subcomponent =
      (<>
        <Typography variant="button" >Actions:</Typography>
        <br />
        <Button sx={{ margin: 1 }} variant='contained' onClick={() => {
          if (plannedEndDate != null)
            edit.set("end_brew", toDayStr(plannedEndDate));
          setShow("endbrew");
        }}>End As Planned: {toDayStr(plannedEndDate)}</Button><br />
        <Button sx={{ margin: 1 }} variant='contained' onClick={() => {
          edit.set("end_brew", toDayStr(new Date()));
          setShow("endbrew");
        }}>End Brew Today</Button><br />
      </>
      );

    if (show === "endbrew") {
      subtitle = "End Brew";
      subcomponent = <DialogEndBrewEdit brewedit={edit} onDone={(isChange: boolean) => { setShow("select"); closeDialog(isChange ? edit.$gid : undefined); }} />
    }

    return (
      <Dialog open={true} fullScreen={fullScreen} onClose={() => closeDialog()}>
        <Box sx={{ padding: 2 }}>
          <DialogTitle sx={{ paddingLeft: 0, paddingBottom: 0, position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }}>
            {subtitle}: <RowText row={edit} field='$name' />
            <Divider variant='fullWidth' style={{ borderColor: 'rgba(0, 0, 0, 0.32)' }} />
          </DialogTitle>

          <Divider />
          {subcomponent}
        </Box>

      </Dialog>
    );

  } else {
    return (
      <Dialog open={false} fullScreen={fullScreen} onClose={() => closeDialog()}></Dialog>
    );
  }
  return null;
}


export function DialogEndBrewEdit(props: { brewedit: DBRowEdit<Brew_Fields>, onDone: (close: boolean) => void }) {
  const brew = props.brewedit;
  const [error, setError] = useState<string | null>(null);

  const onCancel = () => {
    brew.clearAllEdits();
    props.onDone(false);
  };
  const onEndBrew = async () => {
    try {
      brew.set('status', 'DONE');
      await brew.branch.save("Brew-EndBrew", false);
    } catch (error) {
      setError(JSON.stringify(error));
      return;
    }
    props.onDone(true);
  };

  return (
    <Container>
      <Typography variant='overline'>Please verify the values below:</Typography>
      <div style={{ height: '4px' }} />

      {error && <Typography color='error' variant='overline'>{error}</Typography>}
      {(brew != null) && <>
        {/* <AutoMUIEditLayout edit={brew} /> */}
        <Grid container>

          <Grid xs={12}><Typography variant='button'>Verify End Date & Amounts:</Typography></Grid>
          <Grid xs={12}><EditFieldMUI edit={brew} field="end_brew" /></Grid>
          <Grid xs={12}><Typography variant='button'>Package Amounts:</Typography></Grid>
          <EditFieldMUI edit={brew} field="package" />
        </Grid>
      </>
      }
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onEndBrew}>End Brew</Button>
      </DialogActions>
    </Container>
  );
}