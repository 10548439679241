import { useCallback, useState } from "react";

import { Alert, AlertTitle, Button, Card, CardActionArea, CardContent, CardHeader, Divider, Grid, Stack, TextField } from "@mui/material";



export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.name == "email")
      setEmail(e.target.value);
    if (e.target.name == "password")
      setPassword(e.target.value);
  }, [setEmail, setPassword])

  const onSubmit = useCallback(async () => {
    const redirect = new URLSearchParams(window.location.search).get('r') || undefined;

    const result = await fetch(
      `/login`,
      {
        method: 'POST', //headers: { "Content-Type": 'application/json' }, 
        body: JSON.stringify({ email, password }),
      }
    );

    if (result.status < 200 || result.status >= 300) {
      setError(result.statusText);
      setSuccess("");
    } else if (result.body != null) {
      let json = await result.json() as { success: boolean, message: string };
      if (json.success) {
        setSuccess(json.message);
        setError("");

        if (redirect != undefined) {
          setTimeout(()=>{
            window.location.replace(redirect);
          },500);
        }

      } else {
        setError(json.message);
        setSuccess("");
      }
    }

  }, [email, password])

  return (
    <div style={{ position: 'fixed', overflow: 'scroll', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'lightgrey' }}>
      <Grid container justifyContent="center">
        <Grid item>
          <Card sx={{ margin: 4, width: '500px' }}>
            <CardHeader title="Login" subheader="Please enter user info below" sx={{ pb: 0 }}></CardHeader>
            <CardContent>
              <Divider />
              <br />
              {error &&
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
                </Alert>
              }
              {success &&
                <Alert severity="success">
                  <AlertTitle>Success!</AlertTitle>
                  {success}
                </Alert>
              }
              <Stack padding={2} spacing={1} width={400}>
                <TextField label="Email Address" name="email" type="email" value={email} onChange={onChange} focused={true} variant="standard" />
                <TextField label="Password" name="password" type="password" value={password} onChange={onChange} focused={true} variant="standard" />
              </Stack>
            </CardContent>
            <CardActionArea>
              <Button onClick={onSubmit} style={{ margin: '8px', float: 'right' }} variant="contained">Submit</Button>
            </CardActionArea>
          </Card>

        </Grid>
      </Grid>
    </div>
  );
}