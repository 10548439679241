import { Options } from ".";

export class CalcTool {

  options: Options;
  start_date: Date;

  constructor(options: Options, start_date: Date) {
    this.options = options;
    this.start_date = start_date;
  }

  computeSnapX(deltaX: number, midbreak: boolean = true): number {
    const options = this.options;

    // Move breakpoint to beginning of column, instead of midpoint which feels good for dragging
    if (midbreak == false)
      deltaX = deltaX - options.column_width / 2;

    let position = deltaX;
    let partial;
    let round;

    if (options.view_mode == "Week") {
      partial = deltaX % (options.column_width / 7);
      round = (partial < options.column_width / 14 ? 0 : options.column_width / 7);
    } else if (options.view_mode == "Month") {
      partial = deltaX % (options.column_width / 30);
      round = (partial < options.column_width / 60 ? 0 : options.column_width / 30);
    } else {
      partial = deltaX % options.column_width;
      round = (partial < options.column_width / 2 ? 0 : options.column_width);
    }
    return position - partial + round;
  };

  computeSnapY(deltaY: number, midbreak: boolean = true): number {
    const options = this.options;

    // Move breakpoint to beginning of column, instead of midpoint which feels good for dragging
    if (midbreak == false)
      deltaY = deltaY - (options.bar_height + options.padding) / 2;

    let position = deltaY;

    let partial = deltaY % (options.bar_height + options.padding);
    let round = (partial < (options.bar_height + options.padding) / 2 ? 0 : (options.bar_height + options.padding));

    return position - partial + round;
  };


  get_snap_position(deltaX: number): number {
    let position = deltaX;
    let partial;
    let round;

    if (this.options.view_mode == "Week") {
      partial = deltaX % (this.options!.column_width / 7);
      round =
        (partial < this.options!.column_width / 14
          ? 0
          : this.options!.column_width / 7);
    } else if (this.options.view_mode == "Month") {
      partial = deltaX % (this.options!.column_width / 30);
      round =
        (partial < this.options!.column_width / 60
          ? 0
          : this.options!.column_width / 30);
    } else {
      partial = deltaX % this.options!.column_width;
      round =
        (partial < this.options!.column_width / 2
          ? 0
          : this.options!.column_width);
    }
    return position - partial + round;
  }


}

