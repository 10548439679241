import { useState, useEffect, useRef, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { MUISaveButton } from 'client/vdb/mui/MUIEditParts';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

import { HopIQBranchVaultDB } from 'shared/vdb/vdbsharedclass';
import { EventAllTypes } from 'client/vdb/mui/EditEventBuffer';


function SaveBar(props: { vdb: HopIQBranchVaultDB, visible?: true, onCancel?: () => void }) {
  const [visible, setVisible] = useState(props.visible || false);
  const [changeCount, setchangeCount] = useState(0);
  const [warning, setwarning] = useState(false);
  const [error, seterror] = useState(false);

  const changesIncludeCalc = false;

  // Setup event listeners, show & update contents if there are changes
  useEffect(() => {
    const callback = (event: EventAllTypes) => {
      console.log("SaveBar event ", event, { visible: (props.visible != undefined ? props.visible : props.vdb.hasChange(changesIncludeCalc)), hasChange: props.vdb.hasChange(changesIncludeCalc) });
      if (event.type == "data" || event.type == "calc") {
        setVisible(props.visible != undefined ? props.visible : props.vdb.hasChange(changesIncludeCalc));
        setchangeCount(props.vdb.vdbEditBranch.countChanged(changesIncludeCalc));
        setwarning(props.vdb.vdbEditBranch.hasWarning());
        seterror(props.vdb.vdbEditBranch.hasError());
      }
    };
    const unsubscribe = props.vdb.vdbEditBranch.subscribe("", callback);
    return unsubscribe;
  },
    [props.vdb]
  );

  // Show 'there are changes' alert on navigation, if there are unsaved changes
  useEffect(() => {
    const handler = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    // if visible, there is a change
    if (visible) {
      window.addEventListener('beforeunload', handler);
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => { };
  }, [visible]);

  // Handle moving appbar up to hide during scroll, noop when beyond size of appbar+20
  const saveBarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let state = 0;

    const handleScroll = (event: Event) => {
      if (saveBarRef.current) {
        // +20 is to make sure the bar is offset, not just on the edge (and maybe bleeding a shadow or something over)
        let newState = (window.scrollY < saveBarRef.current.clientHeight + 20) ? window.scrollY : saveBarRef.current.clientHeight + 20;

        if (state != newState) {
          // console.log('scroll stateChange: ', { state, newState, scrollY: window.scrollY, compOffset: appBarRef.current.clientHeight + 20 });
          if (newState == 0)
            saveBarRef.current.style.top = "0px";
          else
            saveBarRef.current.style.top = -newState + "px";

          state = newState;
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  const onCancelHandler = useCallback(() => {
    props.vdb.vdbEditBranch.clearAllEdits();
    if (props.onCancel) props.onCancel();
  }, [props.onCancel]);

  const onClickConsoleChanges = useCallback(() => {
    console.log("Changes found - edit branch: ", props.vdb.vdbEditBranch, Object.values(props.vdb.vdbEditBranch.edits).filter((r)=>r.hasChange(false)));
  }, [props.vdb]);

  // position:fixed b/c of timeline, need to stay put during scroll left/right
  return (
    <AppBar position="fixed" style={{ left: 0, right: 0, top: 0, visibility: (visible ? 'visible' : 'hidden') }}
      sx={{ backgroundColor: 'Highlight' }} elevation={2}
      ref={saveBarRef}
    >
      <Toolbar>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: 'black' }}>
          Edit Plan
        </Typography>
        <Box sx={{ flexGrow: 1 }}>

          <Tooltip title={changeCount > 0 ? "Changes found" : "No changes"}>
            <IconButton onClick={onClickConsoleChanges}>
              <ChangeHistoryIcon color={changeCount > 0 ? 'secondary' : 'disabled'} />
              <Typography>{changeCount}</Typography>
            </IconButton>
          </Tooltip>

          <Tooltip title={warning ? "Warnings found in changes" : "No warnings"}>
            <IconButton>
              <WarningIcon color={warning ? 'warning' : 'disabled'} />
            </IconButton>
          </Tooltip>

          <Tooltip title={error ? "Errors found in changes" : "No errors"}>
            <IconButton>
              <ErrorIcon color={error ? 'error' : 'disabled'} />
            </IconButton>
          </Tooltip>

        </Box>

        <Button variant='outlined' sx={{ mr: 1 }} onClick={onCancelHandler}>Cancel</Button>
        <MUISaveButton edit={props.vdb} />
      </Toolbar>
    </AppBar>



    // <AppBar position="static">
    //   <Container maxWidth="xl">
    //     <Toolbar disableGutters>
    //       {/* Small */}
    //       <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
    //         <IconButton
    //           size="large"
    //           aria-label="account of current user"
    //           aria-controls="menu-appbar"
    //           aria-haspopup="true"
    //           onClick={handleOpenNavMenu}
    //           color="inherit"
    //         >
    //           <MenuIcon />
    //         </IconButton>
    //         <Menu
    //           id="menu-appbar"
    //           anchorEl={anchorElNav}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'left',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'left',
    //           }}
    //           open={Boolean(anchorElNav)}
    //           onClose={handleCloseNavMenu}
    //           sx={{
    //             display: { xs: 'block', md: 'none' },
    //           }}
    //         >
    //           {areas.map((page) => (
    //             <MenuItem key={page} onClick={handleCloseNavMenu} selected={(page == area)}>
    //               <Link key={page} to={page.toLowerCase()}
    //                 style={{
    //                   color: 'black',
    //                   textDecorationLine: 'none',
    //                   width: '100%',
    //                 }}
    //               >
    //                 {page}
    //               </Link>
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </Box>
    //       {/* Large */}
    //       {/* <Box sx={{ display: { xs: 'none', md: 'flex' }, pr: 2 }}>
    //         <img src="/hopiq-white.png" height="35" />
    //       </Box> */}
    //       {/* small */}
    //       {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
    //         <img src="/hopiq-white.png" height="35" />
    //       </Box> */}

    //       {/* Both */}
    //       <Box sx={{ display: { xs: 'flex', md: 'flex' }, pr: 2, flexGrow: { xs: 1, md: 0 } }}>

    //         <Link to={'./'}>
    //           <img src="/hopiq-white.png" height="35" />
    //         </Link>

    //         {/* Small Only */}
    //         <Typography
    //           variant="h5"
    //           noWrap
    //           component="a"
    //           href=""
    //           sx={{
    //             ml: 2,
    //             display: { xs: 'flex', md: 'none' },
    //             flexGrow: 0,
    //             fontFamily: 'monospace',
    //             fontWeight: 700,
    //             letterSpacing: '.3rem',
    //             color: 'inherit',
    //             textDecoration: 'none',
    //           }}
    //         >
    //           {area}
    //         </Typography>
    //       </Box>

    //       {/* Large */}
    //       <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
    //         {areas.map((page) => (
    //           <Link key={page} to={page.toLowerCase()}
    //             style={{
    //               textDecorationLine: (page == area) ? 'underline' : 'none',
    //               textDecorationColor: 'white',
    //               textDecorationStyle: 'solid',
    //               textDecorationThickness: 'auto',
    //             }}
    //           >
    //             <Typography
    //               sx={{
    //                 // display: 'block',
    //                 px: 1,
    //                 // mt: 1,
    //                 // mb: 1,
    //                 minWidth: '64px',
    //                 verticalAlign: 'middle',
    //                 position: 'relative',
    //                 textAlign: 'center',
    //                 textTransform: 'uppercase',
    //                 color: 'white',
    //               }}>
    //               {page}
    //             </Typography>
    //           </Link>
    //         ))}
    //       </Box>

    //       <Box sx={{ flexGrow: 0 }}>
    //         <Tooltip title="Open settings">
    //           <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
    //             <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
    //           </IconButton>
    //         </Tooltip>
    //         <Menu
    //           sx={{ mt: '45px' }}
    //           id="menu-appbar"
    //           anchorEl={anchorElUser}
    //           anchorOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //           keepMounted
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'right',
    //           }}
    //           open={Boolean(anchorElUser)}
    //           onClose={handleCloseUserMenu}
    //         >
    //           {settings.map((setting) => (
    //             <MenuItem key={setting} onClick={handleCloseUserMenu}>
    //               <Typography textAlign="center">{setting}</Typography>
    //             </MenuItem>
    //           ))}
    //         </Menu>
    //       </Box>
    //     </Toolbar>
    //   </Container>
    // </AppBar>
  );
}
export default SaveBar;