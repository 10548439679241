import { LinkProps, Link as MuiLink } from "@mui/material";
import { LinkProps as RRLinkProps, Link as RRLink } from "react-router-dom";

import React, { FC } from "react";

const RRMUILink: FC<LinkProps> = props => {
  return (
    <MuiLink {...props} component={RRLink} to={props.href ?? "#"} />
  );
};

export default RRMUILink;