import React, { useEffect, useMemo, useState } from "react";

import { Breadcrumbs, Button, Container, Link, LinkTypeMap, Stack, Typography } from "@mui/material";
import ViewTimelineIconOutlined from '@mui/icons-material/ViewTimelineOutlined';
import CalendarViewMonthIconOutlined from '@mui/icons-material/CalendarViewMonthOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import InsertChartOutlinedSharpIcon from '@mui/icons-material/InsertChartOutlinedSharp';

import { Link as RRLink } from "react-router-dom";
import getdb from 'client/hopiqvdb';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import DisplayMetaInvStorageAsText from "./DisplayMetaInvStorageAsText";

const vdb = getdb("genericBrowse");

function RRRLink(props: React.PropsWithChildren & { to: string, underline?: "hover" | "none" | "always" }) {
  return (<Link key="1" component={RRLink} to={props.to} underline={props.underline || "hover"} color="inherit">{props.children}</Link>);
}

const breadcrumbs = [
  <RRRLink key="1" to="/hopiq" >
    HopIQ
  </RRRLink>,
  <RRRLink key="2" to="/hopiq/plan" >
    Plan
  </RRRLink>,
  <Typography key="3" color="text.primary">
    Home
  </Typography>,
];



export default function PlanHome() {
  return (
    <Container >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>

      <RRRLink to="graph" underline="none">
        <Stack direction="row" gap={1} marginTop={1}>
          <ViewTimelineIconOutlined color="primary" fontSize="large" />
          <Typography variant='h4' color="primary" >View Timeline</Typography>
        </Stack>
      </RRRLink>

      {/* <CalendarViewMonthIconOutlined color="primary" fontSize="large" /> */}

      <RRRLink to="list" underline="none">
        <Stack direction="row" gap={1} marginTop={1}>
          <TableViewOutlinedIcon color="primary" fontSize="large" />
          <Typography variant='h4' color="primary" >Browse Brew Plan</Typography>
        </Stack>
      </RRRLink>

      {/* <RRRLink to="list" underline="none">
        <Stack direction="row" gap={1} marginTop={1}>
          <InsertChartOutlinedSharpIcon color="primary" fontSize="large" />
          <Typography variant='h4' color="primary" >Browse Brew Plan</Typography>
        </Stack>
      </RRRLink> */}

      <DisplayMetaInvStorageAsText />

      {/* <h1>Forecasts - metric dashboard</h1>
      <h1>Inventory Levels</h1> */}

    </Container>
  );
}