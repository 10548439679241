import flexdebounce from './flexdebounce';
import EditEventBuffer, { EventCallback, EventTypes, ListenerTriplet } from '../../client/vdb/mui/EditEventBuffer';
import Reference from './Reference';
import { GRaw, GRawCalc, GRawEither, GRawSystem, META_TYPE_DEF, PREFIX_CALC_CHAR, SchemaTable, STR_KEY_MAP } from './types';
import * as utils from './utils';
import { firstNotUndefined, isObjEmpty } from './utils';
import { BranchVaultDB, DBRow, IDBRow, isTempID, IVaultDB, toAnyCalcKey, toAnyNonCalcKey, toCalcKey } from './VaultDB';
import { VDBEditBranch } from './VDBEditBranch';
import { ElectricScooterRounded } from '@mui/icons-material';
import { IDMap } from './APITypes';
import { util } from 'zod';

var fastDeepEquals = require('fast-deep-equal');

export class DBRowEdit<FIELDS extends STR_KEY_MAP> implements IDBRow<FIELDS> {
  type = "DBRowEdit";
  // dbrow: DBRow<FIELDS>;
  $src: IVaultDB<any, any>;
  $schema: SchemaTable;
  $raw: Readonly<GRaw<FIELDS>>;
  edits: Partial<GRaw<FIELDS>> = {};
  eventbus: EditEventBuffer = new EditEventBuffer(this);
  branch: BranchVaultDB<any, any>;

  asyncProcessesQueue = flexdebounce(async (runAsync: true) => { await this.runAsyncProcesses(); });
  validationQueue = flexdebounce(async (validate: true) => { await this.runValidation(); this.asyncProcessesQueue(true); });
  errors: any = {};
  warnings: any = {};
  info: any = {};

  isAsyncDone() {
    return this.validationQueue.isQueued() === false && this.asyncProcessesQueue.isQueued() === false;
  }
  waitAsyncDone(checktime = 100): Promise<void> {
    // Short circuit - Verify this can't happen too quickly...
    // if (this.isAsyncDone())
    //   return Promise.resolve();

    checktime = Math.max(50, checktime);
    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        // console.log('Waiting async done, is done? ', this.isAsyncDone());
        if (this.isAsyncDone()) {
          clearInterval(timer);
          resolve();
        }
      }, checktime);
    });
  }

  // TODO: Remove, not needed anymore?
  finishCBs: (() => void)[] = [];

  constructor(row: DBRow<FIELDS>) {
    // this.dbrow = row;
    this.$src = row.$src;
    this.$schema = row.$schema;
    this.$raw = Object.freeze(row.$raw);

    if (row.$src instanceof BranchVaultDB) {
      this.branch = row.$src;
    } else {
      throw new Error("DBRowEdit constructor called on non-branch attached DBRow");
    }
    // this.finishCBs.push(this.eventbus.onData("", ({ type, field, value, caller }) => {
    //   console.log("---1 - setting new data into rowEdit: ", field);
    //   this.setAny(field, value);
    // }));
    // this.finishCBs.push(this.eventbus.onCalc("", ({ type, field, value, caller }) => {
    //   this.setCalcAny(field, value);
    // }))
    this.validationQueue(true);
  }
  // get $src() { return this.dbrow.$src }
  // get $schema() { return this.dbrow.$schema }
  get $table() { return this.$raw.$table; }
  get $id() { return this.$raw.$id; }
  get $gid() { return utils.$IRowProcessGID(this.$table, this.$id) } // this.dbrow.$gid;
  get $kid() { return utils.$IRowProcessKID(this.$table, this.$key, this.$id) } // this.dbrow.$kid;
  get $st() { return this.$raw.$st; }
  get $status() { return ("$st" in this.edits) ? utils.$IRowProcessSTATUS(this.edits.$st) : utils.$IRowProcessSTATUS(this.$raw.$st) } //: "DELETED"|"NEW"|"DRAFT"|"ACTIVE"|"UNKNOWN"
  get $pv() { return this.$raw.$pv; }
  get $v() { return this.$raw.$v; }
  get $key() { return this.$raw.$key; }
  get $name() { return utils.$IRowProcessNAME(this.combinedRaw()); }
  get $path() { return this.$raw.$path; }

  /** Remove all callbacks */
  finish() {
    try {
      while (this.finishCBs.length > 0) {
        let removeCB = this.finishCBs.pop();
        if (removeCB !== undefined) {
          try {
            removeCB();
          }
          catch (ignore) { }
        }
      }
    }
    catch (ignore) { }
  }

  // displayAsElement(field: (keyof GRaw<FIELDS> | keyof GRawEither<FIELDS>) & string): JSX.Element {
  //   return RowText({ row: this, field: field });
  // }

  isLocked() { return this.eventbus.isLocked; }
  isValdating() { return this.validationQueue.isQueued(); }
  hasChange(includeCalc = true) { return Object.keys(this.edits).some((k) => k != null && (includeCalc || !k.startsWith('_'))); }
  hasError() {
    // console.log("this.hasError: ", (!isObjEmpty(this.errors)), this.errors); 
    return !isObjEmpty(this.errors)
  }
  hasWarning() {
    //  console.log("this.hasWarning: ", (!isObjEmpty(this.warnings)), this.warnings); 
    return !isObjEmpty(this.warnings)
  }

  combinedRaw() {
    let lRaw: Readonly<GRaw<FIELDS>> = this.$raw;
    let lEdits: Partial<GRaw<FIELDS>> = this.edits;
    let combined = Object.assign({}, this.$raw, this.edits) as GRaw<FIELDS>;
    // console.log("+ combinedRaw: ", combined);
    // console.log("+ raw: ", this.$raw);
    // console.log("+ edits: ", this.edits);
    return combined;
  }

  combinedDBRow() {
    // TODO: Set this to draft? Something else so show it's not a normal DBRow? (fake dbrow?)
    return new DBRow(this.$src, this.$schema, this.combinedRaw());
  }

  onCombinedChange(callback: (newVersion: DBRow<FIELDS>) => void) {
    console.log("DBRowEdit.onCombinedChange - START")
    let removeCB = this.eventbus.onData("", async ({ type, field, value, caller }) => {
      console.log("DBRowEdit.onCombinedChange - EXECUTE")
      callback(this.combinedDBRow());
    })
    return () => { console.log("DBRowEdit - remove onCombinedChange listener"); removeCB(); }
  }

  // TODO: rename all .get to .getEither - then fix the get
  /** Returns the first of Edit Data, Orig Data, Edit Calc, Orig Calc */
  get(fieldName: (keyof GRaw<FIELDS> | keyof GRawEither<FIELDS>) & string, arrayPos: number = -1) {

    if (fieldName === "$gid") return this.$gid;
    if (fieldName === "$kid") return this.$kid;
    if (fieldName === "$status") return this.$status;

    if (fieldName && fieldName.startsWith('?'))
      return this.getEither(fieldName.substring(1), arrayPos);
    else {
      return utils.fnn(utils.getArrayPos(this.edits[fieldName], arrayPos), utils.getArrayPos(this.$raw[fieldName], arrayPos));
    }
  }
  /** Returns the first of Edit Data, Orig Data, Edit Calc, Orig Calc */
  // getData(fieldName: keyof GRaw<FIELDS> & string) {
  //   return firstNotUndefined(this.edits[fieldName], this.$raw[fieldName]);
  // }
  // getCalc(fieldName: keyof GRaw<FIELDS> | keyof GRawCalc<GRawSystem>) {
  //   // @ts-ignore
  //   const editCalc = (this.edits.$calc) ? this.edits.$calc[fieldName] : undefined;
  //   // @ts-ignore
  //   const rawCalc = (this.$raw.$calc) ? this.$raw.$calc[fieldName] : undefined;

  //   return firstNotUndefined(editCalc, rawCalc);
  // }

  edit(branchName?: string | undefined): DBRowEdit<FIELDS> {
    return this;
  }

  getDisplay(fieldName: keyof GRaw<FIELDS> & string, arrayPos: number = -1): string {
    // console.log("getDisplay()", fieldName, arrayPos);
    return utils.getRowDisplay(this, fieldName, arrayPos);
  }
  getE(fieldName: keyof GRaw<FIELDS> & string, arrayPos: number = -1) {
    return this.getEither(fieldName, arrayPos)
  }
  getEither(fieldName: keyof GRaw<FIELDS> & string, arrayPos: number = -1) {
    const isFieldNameCalc = (typeof fieldName === 'string' && fieldName.startsWith('_'))

    let dataFieldName = (fieldName.startsWith('?') || fieldName.startsWith(PREFIX_CALC_CHAR)) ? fieldName.substring(1) : fieldName;
    let calcFieldName = PREFIX_CALC_CHAR + dataFieldName;

    if (fieldName === "$gid") return this.$gid;
    if (fieldName === "$kid") return this.$kid;
    if (fieldName === "$status") return this.$status;

    if (isFieldNameCalc)
      return utils.getArrayPos(firstNotUndefined(this.edits[calcFieldName], this.edits[dataFieldName], this.$raw[calcFieldName], this.$raw[dataFieldName]), arrayPos);
    else
      return utils.getArrayPos(firstNotUndefined(this.edits[dataFieldName], this.edits[calcFieldName], this.$raw[dataFieldName], this.$raw[calcFieldName]), arrayPos);
  }
  getLength(...fieldName: (keyof GRaw<FIELDS> & string)[]): number {
    // if (fieldName.length === 1 && this.$schema.fields[fieldName] && this.$schema.fields[fieldName].$datatype === 'EMBED_ARRAY')

    const lengths = fieldName.map((f) => utils.getArrayLength(this.get(f)));
    console.log('getLength() ' + fieldName.toString() + " lengths: " + lengths.toString())
    return Math.max(...lengths);
  }

  getEditAll(fieldName: keyof GRaw<FIELDS>) {
    let value =
    {
      orig: {
        data: this.$raw[fieldName],
        // @ts-ignore
        calc: this.$raw['_' + fieldName]
      },
      edit: {
        data: this.edits[fieldName],
        // @ts-ignore
        calc: this.edits['_' + fieldName]
      }
    };
    return value;
  }
  set(fieldName: keyof GRaw<FIELDS>, newValue: any, caller?: any, arrayPos: number = -1) {
    return this.setAny(fieldName as string, newValue, caller, arrayPos);
  }
  setAny(fieldName: string, newValue: any, caller?: any, arrayPos: number = -1) {
    if (fieldName === "$status") {
      fieldName = "$st";
      newValue = utils.statusStringToNumber(newValue);
    }

    let prevValue = this.internalSetAny(fieldName, newValue, arrayPos);
    // Should this happen after return?
    this.eventbus.data(fieldName, newValue, caller);
    this.validationQueue(true);
    return prevValue;
  }
  remove(fieldName: keyof GRaw<FIELDS> & string, caller?: any, arrayPos: number = -1) {

    const prevValue = this.edits[fieldName] || this.$raw[fieldName];
    const prevCalcValue = this.edits['_' + fieldName] || this.$raw['_' + fieldName];

    // If >= 0 then edit in place if possible, instead of replace
    if (arrayPos >= 0) {
      // setArrayPos will can't always edit in place, so reassign even if it isn't necessary
      const nv = utils.removeArrayPos(prevValue, arrayPos);
      (this.edits as Record<string | keyof FIELDS | keyof GRawCalc<FIELDS>, any>)[fieldName] = nv;
      this.cleanEditField(fieldName);
      console.log('on data change / DBRowEdit ', fieldName, arrayPos, prevValue, nv, prevCalcValue)
      this.eventbus.data(fieldName, nv, caller);
    } else {
      (this.edits as Record<string | keyof FIELDS | keyof GRawCalc<FIELDS>, any>)[fieldName] = undefined;
      this.cleanEditField(fieldName);
      console.log('on data change / DBRowEdit EMPTY ', fieldName, arrayPos, null, prevCalcValue)
      this.eventbus.data(fieldName, null, caller);
    }
    this.validationQueue(true);
    return prevValue;
  }

  clearAllEdits() {
    const editFields = Object.keys(this.edits);
    for (const fieldName of editFields)
      this.internalSetAny(fieldName, this.$raw[fieldName], -1);
    for (const fieldName of editFields)
      this.eventbus.data(fieldName, this.$raw[fieldName], this);

    // Validation normally puts edits back in place, pretty sure from calc'd fields.
    // this.validationQueue(true);
  }

  // If arrayPos >= 0, returned value may have been edited in place and contain the new value
  internalSetAny(fieldName: string, newValue: any, arrayPos: number) {
    const prevValue = this.edits[fieldName] || this.$raw[fieldName];

    // If >= 0 then edit in place if possible, instead of replace
    if (arrayPos >= 0) {
      // setArrayPos will can't always edit in place, so reassign even if it isn't necessary
      const nv = utils.setArrayPos(prevValue, newValue, arrayPos);
      (this.edits as Record<string, any>)[fieldName] = nv;
    } else {
      (this.edits as Record<string, any>)[fieldName] = newValue;

    }
    this.cleanEditField(fieldName);

    return prevValue;
  }

  setCalc(fieldName: keyof GRaw<FIELDS> & string, value: any, caller?: any) {
    this.setCalcAny(fieldName, value, caller);
  }
  setCalcAny(fieldName: string, newValue: any, caller?: any) {
    let calcFieldName = toAnyCalcKey(fieldName);
    const prevValue = this.internalSetCalcAny(calcFieldName, newValue);

    // Only kick off events & validation if value changed
    // if (fastDeepEquals(newValue, prevValue) === false) {
    // }

    // Should this happen after return?
    this.eventbus.calc(calcFieldName, newValue, caller);
    this.validationQueue(true);

    return prevValue;
  }

  internalSetCalcAny(fieldName: string, newValue: any) {
    let anyCalcFieldName = toAnyCalcKey(fieldName);

    const prevValue = this.edits[anyCalcFieldName];

    // @ts-ignore
    this.edits[anyCalcFieldName] = newValue;
    this.cleanEditField(anyCalcFieldName);

    return prevValue;
  }

  /** If raw & edit values are the same, remove edit value */
  cleanEditField(field: string) {
    const raw = this.$raw[field];
    const edit = this.edits[field];

    if (fastDeepEquals(raw, edit)) {
      delete this.edits[field];
      return true;
    }
    return false;
  }

  rebase(idmap: IDMap, newRowVersion: GRaw<any>) {
    // if (isTempID(this.$id)) {
    //   let newId = idmap[this.$id];
    // }
    console.log("NV: rebase()")

    if (this.$raw.$table != newRowVersion.$table) {
      throw new Error("SNH: rebase called w/ row that doesn't have matching $table: orig: " + this.$raw.$table + " vs new: " + newRowVersion.$table);
    }

    this.$raw = Object.freeze(newRowVersion);
    for (let key of Object.keys(this.edits)) {
      this.cleanEditField(key);

      if (key.startsWith(PREFIX_CALC_CHAR))
        this.eventbus.calc(key, this.get(key), null);
      else
        this.eventbus.data(key, this.get(key), null);
    }

    this.validationQueue(true);
    this.asyncProcessesQueue(true);

    return this.waitAsyncDone()
    // Don't rebase here... let that come from the RootVaultDB after save has returned from server
    // .finally(() => { this.eventbus.rebase(null) });
  }

  // async save() {
  //   if (this.branch === null) {
  //     let merged = { ...this.$raw, ...this.edits };
  //     // @ts-ignore
  //     merged.$calc = { ...this.$raw.$calc, ...this.edits.$calc };

  //     if (this.dbrow.$src.isBranch() == false) {
  //       throw new Error("Unable to save on 'not' a branch... SNH");
  //     }
  //     // ------- actually, this is just a single save. Always save on a branch?
  //     return (this.dbrow.$src as BranchVaultDB<any, any>).saveRaw(this.$raw, merged, this.edits);
  //   } else {
  //     throw new Error("Part of branch, must save as part of branch");
  //   }
  // }

  // listeners: ListenerTriplet[] = [];

  // subscribe(type: EventTypes, field: string | null, callback: EventCallback<any>) {
  //   this.listeners.push([type, field, callback]);
  //   return () => { this.unsubscribe(callback) }
  // }
  // unsubscribe(callback: EventCallback<any>) {
  //   this.listeners = this.listeners.filter((i) => i[2] !== callback)
  // }

  // TODO: Need 'capture phase' flag to indicate when an event started up the chain, rush to end point then bubble up like normal
  // I imagine events in parent branches/server such as 'calc value has been updated' might cause these?
  // For a full edit/save elsewhere, we would want 

  dispatch(caller: any, toParent: boolean, type: EventTypes, target?: string, field?: string, value?: any): void {
    // When dispatch to parent returns to us, ignore to avoid loop
    if (caller === this) return;

    // Dispatch to this level's children
    // for (const [lType, lField, lCallback] of this.listeners) {
    //   if (type.startsWith(lType)) {
    //     if (lField === null || (field || "").startsWith(lField)) {
    //       lCallback({ type, target, field, value, caller })
    //         .then((value) => { if (value === false) this.unsubscribe(lCallback); })
    //         .catch((error) => { this.unsubscribe(lCallback); })
    //     }
    //   }
    // }
    // if (type !== "")
    //   this.eventbus.call({ type, target, field, value, caller });
    // { type: EventTypes, branch?: string, target?: string, field?: string, value?: any | boolean, caller?: any }

    // If we have a parent, push up the chain.
    // if (this.branch && toParent)
    //   this.branch.branch.dispatch(type, target, field, value, this);
  }

  private updateValidationOutput = (namesChangedArray: string[], name: string, error?: any, warn?: any) => {
    if (error !== undefined && this.errors[name] != error) {
      this.errors[name] = error;
      namesChangedArray.push(name);
    }
    if (warn !== undefined && this.warnings[name] != warn) {
      this.warnings[name] = warn;
      namesChangedArray.push(name);
    }
  };

  runValidation() {

    let validationChanged: string[] = [];

    // Handle schema row level validations
    const { rowErrors, rowWarnings } = utils.rowValidation(this);
    for (let [name, error] of Object.entries(rowErrors))
      this.updateValidationOutput(validationChanged, name, error, null);
    for (let [name, warn] of Object.entries(rowWarnings))
      this.updateValidationOutput(validationChanged, name, null, warn);

    // Validate all fields
    let DBG_VALIDATIONFIELDS = [];
    for (let [name, field] of Object.entries(this.$schema.fields)) {
      if (field.validation) {
        DBG_VALIDATIONFIELDS.push(name);
        // @ts-ignore
        let validation = field.validation(this.getEither(name));
        if (validation === undefined) validation = null;
        const error = (validation !== null && typeof validation === 'string') ? validation : (validation !== null && 'error' in validation) ? validation.error : null;
        const warn = (validation !== null && typeof validation === 'object' && 'warn' in validation) ? validation.warn : null;

        this.updateValidationOutput(validationChanged, name, error, warn);
      }
    }
    console.log("VALIDATION RUN on: ", JSON.stringify(DBG_VALIDATIONFIELDS));

    for (const name of validationChanged)
      this.eventbus.validation(name)

  }

  /** Re-calculate $calc values, $metatype values */
  async runAsyncProcesses() {
    // console.log("DBRowEdit.runAsyncProcessses");
    let asyncPromises = [];

    // Calculation fields - sync so no promises needed
    let calcFields = [];
    for (let [name, field] of Object.entries(this.$schema.fields)) {
      if (field.calc) {
        calcFields.push(name);
        let value = utils.processFieldCalc(this, name, field, field.calc);
        this.internalSetCalcAny(name, value);
        this.eventbus.calc(name, value, null);
      }
    }
    if (calcFields.length > 0)
      console.log("CALCs RUN on: ", JSON.stringify(calcFields));

    // Process metatypes
    if (this.$schema.metatypes) {

      for (const meta of Object.entries(this.$schema.metatypes)) {
        const [metaName, metaDef]: [string, META_TYPE_DEF] = meta;
        if (metaDef !== undefined) {
          let metaPromise: Promise<any> = utils.processMetaType(this, metaName, metaDef).then((processedMeta) => {
            // Successful resolution, now optimistically save MetaType into row

            // Currently we have a special $name processing in metatypes. Need to clear up how this is handled?
            if (metaName === "$name") {
              let raw = this.combinedRaw();
              let fullMetaName = metaName;

              let prev = this.get("_" + fullMetaName)
              // console.log("DBRowEdit.runAsyncProcessses.metatypes - $name?: (name,prev,new) ", fullMetaName, prev, processedMeta);
              // console.log("DBRowEdit.runAsyncProcessses - isEquals?", fastDeepEquals(prev, processedMeta), prev, processedMeta);
              if (fastDeepEquals(prev, processedMeta) == false) {
                // console.log("DBRowEdit.runAsyncProcessses - $name: ", fullMetaName, processedMeta);
                this.internalSetCalcAny(fullMetaName, processedMeta);
                this.eventbus.calc(fullMetaName, processedMeta, null);
              }
            } else {
              let fullMetaName = "%" + metaName;

              let prev = this.get("_" + fullMetaName)
              // console.log("DBRowEdit.runAsyncProcessses.metatypes - SETTING?: (name,prev,new) ", fullMetaName, prev, processedMeta);
              // console.log("DBRowEdit.runAsyncProcessses - isEquals?", fastDeepEquals(prev, processedMeta), prev, processedMeta);
              if (fastDeepEquals(prev, processedMeta) == false) {
                // console.log("DBRowEdit.runAsyncProcessses - SETTING: ", fullMetaName, processedMeta);
                this.internalSetCalcAny(fullMetaName, processedMeta);
                this.eventbus.calc(fullMetaName, processedMeta, null);
              }
            }




          });
          asyncPromises.push(metaPromise);
        }

      } // End forloop
    }

    return Promise.all(asyncPromises);
  } // End runAsyncProcesses()

}
