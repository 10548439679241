import React, { useMemo } from "react";
import { Route, Routes, Link, useNavigate, useMatch } from "react-router-dom";
import { Container, CssBaseline, Divider, ScopedCssBaseline, Tab, Tabs, Typography } from "@mui/material";

import GenericBrowseEdit from "../../client/components/GenericBrowseEdit";
import SimpleBrews from "./setup/OldPages/SimpleBrews";

import getdb from 'client/hopiqvdb';
import TaskChartTest from "../TaskChartTest";
import AllBrewInvPage from "./setup/OldPages/AllBrewInvPage";
import ResponsiveAppBar from "./AppBar";
import BrewIndex from "./brew/BrewIndex";
import PlanIndex from "./plan/PlanIndex";
import Utils from "./Utils";
import SetupIndex from "./setup/SetupIndex";
const vdb = getdb("genericBrowse");

const PAGE_PREFIX = "/hopiq/";

export default function HopIQIndex(props: {}) {
  const nav = useNavigate();

  const brewsimple = useMatch(PAGE_PREFIX + 'brewsimple/*');
  const recipesimple = useMatch(PAGE_PREFIX + 'recipesimple/*');
  const recipesimplepart = useMatch(PAGE_PREFIX + 'recipesimplepart/*');
  const tanks = useMatch(PAGE_PREFIX + 'tanks/*');

  const MATCHES = [brewsimple, recipesimple, recipesimplepart, tanks];
  const TABS = useMemo(() => {
    return [
      { label: "Brew", nav: "brew", element: (<BrewIndex />) },
      { label: "Plan", nav: "plan", element: (<PlanIndex />) },
      //GenericBrowseEdit table={vdb.Brew} defaultNav={true}
      { label: "Config", nav: "config", element: (<SetupIndex />) },
      // { label: "Tanks", nav: "tanks", element: (<GenericBrowseEdit table={vdb.FermentationTank} defaultNav={true} />) },
    ];
  }, [vdb]);

  let tab = MATCHES.findIndex((value, index) => value != null) + 1;

  const linkHelper: React.MouseEventHandler<HTMLAnchorElement> = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    let elem = event.currentTarget;
    nav(elem.href.substring(elem.href.indexOf(PAGE_PREFIX)));
    console.log("linkHelper: ", elem.href, event.target, event.currentTarget);
    event.preventDefault();
  }

  //   useMemo(() =>
  // }, [nav]);

  console.log("tab keys: ", TABS.map((t) => t.nav));

  return (<>
    <CssBaseline />
    <ResponsiveAppBar />
    <Routes>
      {TABS.map((t) => <Route key={t.nav} path={t.nav + "/*"} element={t.element} />)}
      <Route key="utils" path="utils/*" element={<Utils />} />
      <Route key="HARDCODED_index" index={true} element={(
        <Container>
          <h1>HopIQ Index / Home</h1>
        </Container>
      )} />
    </Routes>
  </>);
}