import { Box, Button } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";

import AddIcon from '@mui/icons-material/Add';

export const CustomGridToolbar = (props: {
  // csvOptions?: any,
  // printOptions?: any,
  // excelOptions?: any,
  showQuickFilter: boolean,
  onNewClick?: () => void,
  onNewClickText?: string,
  quickFilterProps?: any
}) => {

  return (
    <div>
      {props.showQuickFilter && <Box sx={{ display: 'inline-block', marginLeft: 1 }}>
        <GridToolbarQuickFilter {...props.quickFilterProps} />
      </Box>
      }
      <Box sx={{ float: 'right' }}>
        {props.onNewClick && <Button href={"./new"} size="small" onClick={props.onNewClick} startIcon={<AddIcon />}>{props.onNewClickText ? props.onNewClickText : "New Entry"}</Button>}
        <GridToolbarColumnsButton />
        {/* <GridToolbarFilterButton /> */}
        {/* <GridToolbarDensitySelector {...props.quickFilterProps} /> */}
        {/* <GridToolbarExport {...props.csvOptions}  {...props.printOptions}  {...props.excelOptions} /> */}
      </Box>
    </div>
  );
}

export default CustomGridToolbar;