
import getdb from 'client/hopiqvdb';
import { useEffect, useState } from 'react';
import { META_INV_COUNT_DAILY, MetaInvStorage } from 'shared/vdb/MetaInv';
const vdb = getdb("DisplayMetaInvStorageAsText");

export default function DisplayMetaInvStorageAsText(props: {}) {

  const [value, setValue] = useState(1);
  const [dailys, setDailys] = useState<META_INV_COUNT_DAILY[]>([]);
  const [mis,] = useState(new MetaInvStorage());

  useEffect(() => {
    const process = async () => {
      let brews = await vdb.Brew.query({}).list();
      await Promise.all(brews.map((b) => b.edit().waitAsyncDone()));

      for (let brew of brews) {
        console.log("mis.add add", brew.get("?%$inv_delta-brewtank-count"));
        let invDeltas = brew.get("?%$inv_delta-brewtank-count");

        if (Array.isArray(invDeltas))
          mis.add(...invDeltas);
        else
          mis.add(invDeltas);

      }

      mis.processLevels()

      // debugger;
      setValue((v) => v + 1)
      console.log("MIS", mis, mis.levels, mis.daily('BrewTankCount'));
      setDailys(mis.daily('BrewTankCount'))
    };
    process();
  }, []);

  //   <div style={{ textAlign: 'center', backgroundColor: 'lightcoral', borderRadius: '5px', padding: '3px' }}>
  //   3/14<br />1
  // </div>

  return (<div>
    <table>
      <tbody>
        <tr><td colSpan={dailys.length}><h4>Changes in Available Brew Sessions per day</h4></td></tr>
        <tr>
          {dailys.map((d) => (
            <td key={d.when} style={{ textAlign: 'center', backgroundColor: (d.total < 0) ? 'lightcoral' : (d.total == 0) ? 'lightyellow' : 'lightgreen', borderRadius: '5px', padding: '3px' }}>
              {d.when.substring(5)}<br />
              {d.total}
            </td>
          ))}

        </tr>
      </tbody>
    </table>

    {/* <pre>{JSON.stringify(mis.daily('BrewTankCount'), null, 2)}</pre>; */}
  </div>);
}
