import { DBRowEdit } from "./DBRowEdit";
import RefID from "./RefID";
import Reference from "./Reference";
import { BranchVaultDB, DBTable, IDBRow, IVaultDB, Schema } from "./VaultDB";
import { STR_KEY_MAP } from "./types";
import { SchemaField } from "./types";

export async function autoLinkProcess<FIELDS extends STR_KEY_MAP, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(vdb: BranchVaultDB<SCHEMA, TABLENAMES>, fieldName: keyof FIELDS & string, tableName: string) {

  let table: DBTable<FIELDS, any, IDBRow<FIELDS>, SCHEMA, TABLENAMES> | null = vdb.table(tableName);

  if (table == null)
    throw new Error("Provided table name not valid: " + tableName);

  // Make sure we have everything we need
  let field: SchemaField | undefined = table.$schema().fields[fieldName];
  if (field == undefined)
    throw new Error("Unable to find schemafield for " + fieldName + " in table " + table.name);
  if (field.$datatype !== 'ID')
    throw new Error("Schemafield for " + fieldName + " in table " + table.name + " is not type ID");
  if (!field.table)
    throw new Error("Schemafield for " + fieldName + " in table " + table.name + " must have 'table' defined");


  let targetTableRef = Reference.parse(field.table);

  let targetTableRefTableName = (targetTableRef != null) ? targetTableRef.getTable() : undefined;
  console.log("has found table via refid key? " + targetTableRefTableName);

  let targetTable = (targetTableRefTableName != undefined) ? vdb.table(targetTableRefTableName) : vdb.table(field.table);

  if (!targetTable)
    throw new Error("Unable to resolve target table for id field " + table.name + "." + fieldName + ": " + field.table);

  // Now loop through all rows, resolving field if needed

  let allRows = await table.query({}).list();

  for (let row of allRows) {
    await autoLinkRow(row, fieldName, targetTable);
  }
}

export async function autoLinkRow<FIELDS extends STR_KEY_MAP, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(row: IDBRow<FIELDS>, fieldName: keyof FIELDS & string, targetTable: DBTable<any, any, any, SCHEMA, TABLENAMES>) {

  let max = row.getLength(fieldName);
  console.log('vdblink - processing row/field/max', row.$gid, fieldName, max);

  for (let i = 0; i < max; i++) {
    let field = row.get(fieldName, i);
    console.log('vdblink - processing row/field/i/max/fieldValue', row.$gid, fieldName, i + "/" + max, row.get(fieldName), field);
    let ref = RefID.parseAny(field);

    let targetRow: IDBRow<any> | null = null;

    if (ref != null) {
      if (ref.getTable() != targetTable.schema.key)
        throw new Error("For " + row.$gid + " field " + fieldName + " is a ref that doesn't point to target table " + targetTable.$schema().name + ": " + field);
      let refid = ref.getID();
      if (refid !== undefined)
        targetRow = await targetTable.get(refid);

      if (ref.getKey())
        field = ref.getKey();
    }

    if (typeof field === 'string') {
      targetRow = await targetTable.getByKey(field);
    }

    // If we found our target...
    if (targetRow != null) {
      let edit = row.edit();
      console.log('vdblink - setting row/field/i/max prevValue:', row.$gid, fieldName, i + "/" + max, row.get(fieldName, i));
      let prevValue = edit.set(fieldName, targetRow.$gid, null, i)
      console.log('vdblink - afterset row/field/i/max afterValue:', row.$gid, fieldName, i + "/" + max, row.get(fieldName, i));
      edit.set('_'+fieldName, targetRow.$name, null, i)

      console.log("Row " + row.$gid + " resolving " + fieldName + " from " + JSON.stringify(prevValue) + " to " + targetRow.$name + "[" + targetRow.$gid + "] ");
    } else {
      console.log("Row " + row.$gid + " unable to resolve " + fieldName + " from " + row.get(fieldName));
    }

  }

}
