import { DBRowEdit } from "../../../shared/vdb/DBRowEdit";

export type EventLock = { type: 'lock' };
export type EventUnlock = { type: 'unlock' };
export type EventValidation = { type: 'validation' };
export type EventChanged = { type: 'change', field: string, value: boolean };
export type EventData = { type: 'data', field: string, value: any, caller: any };
export type EventCalc = { type: 'calc', field: string, value: any, caller: any };
export type EventRebase = { type: 'rebase', target: string, caller: any };
export type EventAll = { type: '', branch?: string, target?: string, field?: string, value?: any | boolean, caller?: any };
export type EventAllTypes = { type: EventTypes, branch?: string, target?: string, field?: string, value?: any | boolean, caller?: any } & (EventAll | EventLock | EventUnlock | EventValidation | EventChanged | EventData | EventCalc | EventRebase);
export type EventCallback<EventType extends EventAllTypes> = (event: EventType) => Promise<false | void>;

export type EventWithBranch<Event extends EventAllTypes> = { branch: string } & Event;


/** Start of an edit, stop validation & saving until the change has been processed */
type ETypeLock = 'lock'
/** End of an edit, once all unlocks are in we can validate */
type ETypeUnlock = 'unlock'
/** Validation has changed, update errors, warning, & info flags and messages */
type ETypeValidation = 'validation'
/** Not currently used */
type ETypeChanged = 'change'
/** Field to indicate that a data value has changed */
type ETypeData = 'data'
/** Indicates that a calc value has changed */
type ETypeCalc = 'calc'
/** Indicates that the version has changed (after save) */
type ETypeRebase = 'rebase'
/** Listen to all event types - blank so it will match all type.startsWith() conditions */
type ETypeAll = ''
export type EventTypes = ETypeLock | ETypeUnlock | ETypeValidation | ETypeChanged | ETypeData | ETypeCalc | ETypeRebase | ETypeAll;

export type ListenerTriplet = [type: EventTypes, field: string | null, callback: EventCallback<any>];

export class EditEventBuffer {

  isLocked = false;
  locks: string[] = [];
  listeners: ListenerTriplet[] = [];

  parent: DBRowEdit<any>;
  constructor(parent: DBRowEdit<any>) {
    this.parent = parent;
  }

  target(type: EventTypes, fullID: string, field: string) {
    return [type, fullID, field].join("|");
  }

  // filterStr(type: EventTypes, target?: string) {
  //   let filter: string[] = [type];
  //   if (target !== undefined)
  //     filter.push(target);

  //   // for (let i = 0; i < args.length; i++) {
  //   //   const v = args[i];
  //   //   if (v !== undefined)
  //   //     filter.push(v);
  //   // }

  //   return filter.join("|");
  // }

  lock(fieldPath: string) {
    this.locks.push(fieldPath);
    if (this.isLocked == false) {
      this.isLocked = true;
      this.call({ type: 'lock' });
    }
  }

  unlock(fieldPath: string) {
    this.locks = this.locks.filter((e) => e !== fieldPath);
    if (this.locks.length === 0) {
      this.isLocked = false;
      this.call({ type: 'unlock' });
    }
  }

  data(fieldPath: string, value: any, caller: any) {
    this.call({ type: 'data', field: fieldPath, value, caller });
  }

  calc(fieldPath: string, value: any, caller: any) {
    this.call({ type: 'calc', field: fieldPath, value, caller });
  }

  rebase(caller: any) {
    this.call({ type: 'rebase', target: this.parent.$gid, caller });
  }

  validation(fieldPath: string) {
    this.call({ type: 'validation', field: fieldPath });
  }

  // private call(type: 'lock'): false | void;
  // private call(type: 'unlock'): false | void;
  // private call(type: 'validation'): false | void;
  // private call(type: 'changed', target: undefined, changed: boolean): false | void;
  // private call(type: 'data', target: string, value: any, caller: any): false | void;
  // private call(type: 'calc', target: string, value: any, caller: any): false | void;
  async call<TYPE extends EventAllTypes>(event: TYPE): Promise<false | void> {
    const { type, field, value, caller } = event;
    // console.log("EditEventBuffer.eventbus.call() - start", type, field, value, caller);
    let cnt = 0;

    for (const listenerTriplet of this.listeners) {
      const [lType, lField, lCallback] = listenerTriplet;
      if (type.startsWith(lType)) {
        if (lField === null || (field || "").startsWith(lField)) {
          lCallback(event)
            .then((value) => { if (value === false) this.removeCallback(lCallback); })
            .catch((error) => { this.removeCallback(lCallback); });
        }
      }
    }

    if (this.parent && this.parent.branch) {
      //(type === 'lock' || type === 'unlock') && 
      // So the branch does need to know all changes, not just locks... Or does it? 
      // console.log("TT7: ", type, (type === 'lock' || type === 'unlock'), Boolean(this.parent), Boolean(this.parent.branch));
      this.parent.branch.vdbEditBranch.dispatch(type, this.parent.$gid, field, value, this);
    }

    // console.log("EditEventBuffer.eventbus.call() ", [type, field], "called type ", cnt, "/", this.listeners.length);
  }

  // on<CB extends ECBLock, ETLock>(type: 'lock', filter: string, cb: ECBLock): () => void;
  // on<CB extends ECBUnlock, ETypeUnlock>(type: 'unlock', filter: string, cb: ECBUnlock): () => void;
  // on<CB extends ECBValidation, ETValidation>(type: 'validation', filter: string, cb: ECBValidation): () => void;
  // on<CB extends ECBChanged, ETChanged>(type: 'changed', filter: string, cb: CB): () => void;
  // on<CB extends ECBData, ETData>(type: 'data', filter: string, cb: CB): () => void;
  // on<CB extends ECBCalc, ETCalc>(type: 'calc', filter: string, cb: CB): () => void;
  // on<CB extends EventCallbacks, TYPE extends EventTypes>(type: TYPE, filter: string, cb: CB): () => void {

  onLock(cb: EventCallback<EventLock>): () => void { return this.on('lock', null, cb); }
  onUnlock(cb: EventCallback<EventUnlock>): () => void { return this.on('unlock', null, cb); }
  onValidation(field: string | null, cb: EventCallback<EventValidation>): () => void { return this.on('validation', field, cb); }
  onChanged(field: string | null, cb: EventCallback<EventChanged>): () => void { throw new Error("editeventbuffer.onChanged doesn't appear to be working"); return this.on('change', field, cb); }
  onCalc(field: string | null, cb: EventCallback<EventCalc>): () => void { return this.on('calc', field, cb); }
  onData(field: string | null, cb: EventCallback<EventData>): () => void { return this.on('data', field, cb); }
  onAll(field: string | null, cb: EventCallback<EventAllTypes>): () => void { return this.on('', field, cb); }

  onCalcSet(field: string | null, set: React.Dispatch<any>): () => void {
    // This caused problems. Need to clear up the data vs calc in all apis
    // if (typeof field === 'string')
    //   if (field.startsWith('_') == false)
    //     field = "_" + field;
    return this.on('calc', field, async (event: EventCalc) => { set(event.value); });
  }

  onDataSet(field: string | null, set: React.Dispatch<any>): () => void {
    return this.on('data', field, async (event: EventData) => { console.log('on data change', field, event.value); set(event.value); });
  }

  // on(type: 'lock', filter: string, cb: ECBLock): () => void;
  // on(type: 'unlock', filter: string, cb: ECBUnlock): () => void;
  // on(type: 'validation', filter: string, cb: ECBValidation): () => void;
  // on(type: 'changed', filter: string, cb: ECBChanged): () => void;
  // on(type: 'calc', filter: string, cb: ECBCalc): () => void;
  // on(type: 'data', filter: string, cb: ECBData): () => void;
  // on<E extends EventTypes, CB extends EventCallbacks>(type: E, filter: string, cb: CB): () => void;
  private on<TYPE extends EventAllTypes>(type: EventTypes, field: string | null, cb: EventCallback<TYPE>): () => void {
    const listenerTriplet: ListenerTriplet = [type, field, cb];
    this.listeners.push(listenerTriplet);

    return () => {
      // console.log("Removing listener of type ");
      this.listeners = this.listeners.filter((i) => listenerTriplet !== i)
      // Below should also be a valid way (in place edit) that doesn't require a ts-ignore
      // let idx = thisTypeCBMap.findIndex(i=>i === listener);
      // if (idx != -1) 
      //   thisTypeCBMap.splice(idx, 1)
    }
  }

  removeCallback(callback: EventCallback<any>) {
    this.listeners = this.listeners.filter((i) => i[2] !== callback)
  }

  onChangeSet(field: string, toSet: React.Dispatch<React.SetStateAction<string | number>>) {
    this.on('change', field, async (e) => {
      toSet(e.value);
    });
  }
};
export default EditEventBuffer;
