import { useEffect, useState } from 'react';
import { DBRowEdit } from 'shared/vdb/DBRowEdit';
import RefID from 'shared/vdb/RefID';
import { BranchEventRebase, VDBEditBranch } from 'shared/vdb/VDBEditBranch';

import { BranchVaultDB, DBQuery, IDBRow, IVaultDB, Schema } from "shared/vdb/VaultDB";
import { GRaw, STR_KEY_MAP } from 'shared/vdb/types';

var deepEqual = require('fast-deep-equal/es6/react');

function updateUSEVDBQuery<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(
  query: DBQuery<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>, setData: React.Dispatch<React.SetStateAction<ROW[] | undefined>>, setError: React.Dispatch<React.SetStateAction<any | undefined>>) {
  query.list().then((rows) => {
    setData(rows);
    setError(undefined);
  },
    (error) => {
      setData(undefined);
      setError(error);
    }
  );
}

function useVDB<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(
  // vdb: IVaultDB<any,any>,
  query: DBQuery<FIELDS, RAW, ROW, SCHEMA, TABLENAMES>
): [data: ROW[] | undefined, error: any] {
  // Used to check if in input changed
  const [compFilter, setcompFilter] = useState();
  const [compTableName, setcompTableName] = useState(query.dbtable.name);

  // Output cache
  const [data, setData] = useState<ROW[] | undefined>();
  const [error, setError] = useState<any | undefined>();

  useEffect(() => {
    if (query.dbtable.src.isBranch() && query.dbtable.src instanceof BranchVaultDB) {
      let vdbbranch = query.dbtable.src;
      console.warn("useVDB listening for rebase");
      return vdbbranch.vdbEditBranch.subscribe('rebase', (event: BranchEventRebase) => {
        let targetgid = event.target;
        let gid = RefID.parseAny(targetgid);
        console.warn("useVDB query UPDATE? ", query.dbtable.schema.key, gid);
        if (gid != null) {
          if (query.dbtable.schema.key == gid.getTable()) {
            console.warn("TRIGGERED useVDB query UPDATE!!!!!!");
            updateUSEVDBQuery(query, setData, setError);
          }
        }
      });
    }
    // Use local cached versions, let deepEqual below verify real change or not
  }, [compFilter, compTableName]);

  // If the table name or filterchanges, trigger an update
  if (deepEqual(compFilter, query.filter) === false || query.dbtable.name !== compTableName) {
    setcompFilter(query.filter);
    setcompTableName(query.dbtable.name);

    updateUSEVDBQuery(query, setData, setError);
  }

  // TODO: Add in listeners and update data on changes
  /*
   * XXXX: This is complicated in that we don't want to refresh on every change coming from our children (forms fields, etc), 
   * but we do want updates from other sources or from the server. Since save updates are being handled already, perhaps we 
   * need a 'rebase' event to know when the DBRow has been updated, instead of data/calc field updates individually.
   */

  // useEffect(()=>{
  //   if (vdb instanceof BranchVaultDB<any,any>) {
  //     let s1 = vdb.vdbEditBranch.subscribe("data", ()=>{})
  //   }
  // },[query.dbtable]
  // );

  return [data, error]
}
export default useVDB;


export function useVDBRowEdit<FIELDS extends STR_KEY_MAP, RAW extends GRaw<FIELDS>, ROW extends IDBRow<FIELDS>, SCHEMA extends Schema<TABLENAMES>, TABLENAMES extends string>(
  vdb: IVaultDB<any, any>,
  gid?: string
): [data: DBRowEdit<STR_KEY_MAP> | undefined, error: any] {

  // Used to check if in input changed
  const [preVDB, setPrevVDB] = useState(vdb);
  const [prevGID, setPrevGID] = useState<string | undefined>();

  // Output values
  const [data, setData] = useState<DBRowEdit<STR_KEY_MAP> | undefined>();
  const [error, setError] = useState<any | undefined>();

  if (prevGID != gid) {
    setPrevGID(gid);

    if (gid === undefined) {
      setData(undefined);
      setError(undefined)
    } else {

      vdb.get(gid).then((row) => {
        setError(undefined);
        if (row != null)
          setData(row.edit());
        else
          setData(undefined);
      }).catch((error) => {
        setData(undefined);
        setError(error);
      });
    }
  }

  return [data, error];
}

