export const globalID = new RegExp(/^@\/?(\$?[a-zA-Z][a-zA-Z0-9-_]+)(\/((?:\$?-?[0-9]+)|(?:[a-zA-Z0-9-_ |.]+)))$/);

/** Use this for use saved reference to another row in the db. VERY limited, only supports parsing one full @/table/$id or @/table/key string */
export default class RefID {
  value: string;
  table: string;
  key?: string;
  id?: number;

  static regex = globalID;

  private constructor(value: string, table: string, second?: string | number) {
    this.value = value;
    this.table = table;

    if (typeof second === 'string')
      this.key = second;
    else if (typeof second === 'number')
      this.id = second;
  }

  getTable() { return this.table; }
  hasKey() { return this.getKey() !== undefined; }
  getKey() { return this.key; }
  hasID() { return this.getID() !== undefined; }
  getID() { return this.id; }

  isTemp() { return this.id !== undefined && this.id < 0; }

  getValue() { return this.value; }
  toString() { return this.value; }

  equals(other?: RefID | null): boolean {
    if (other === undefined || other === null || typeof other !== 'object')
      return false;

    let eqTest1 = (this.value == other.value);
    let eqTest2 = (this.table == other.table && this.key == other.key && this.id == other.id);
    // console.log("Is RefID eq? "+(eqTest1 || eqTest2), this.value, other.value);
    return eqTest1 || eqTest2;
  }

  static parseAny(id: any) {
    return (typeof id === 'string') ? this.parse(id) : (id instanceof RefID) ? this.parse(id.value) : null;
  }
  //TODO: Make this a OrNull version, other side throws descriptive errors
  static parse(idStr: string) {
    if (idStr === null || idStr === undefined || typeof idStr !== 'string' || idStr.length <= 1)
      return null;
    // if (idStr[0] != '@') {
    //   return null;
    // }
    // idStr = idStr.substring(1);
    let parsed = idStr.match(globalID);

    if (parsed == null)
      return null;

    let tablePart = parsed[1];
    let secondaryPart: string | number | undefined = parsed[3];

    if (secondaryPart != undefined && secondaryPart.startsWith('$')) {
      secondaryPart = secondaryPart.substring(1);
      secondaryPart = Number.parseInt(secondaryPart);
      if (Number.isInteger(secondaryPart)) {
        return new RefID(idStr, tablePart, secondaryPart);
      } else {
        return null;
      }
    } else {
      return new RefID(idStr, tablePart, secondaryPart);
    }
  }

}
