import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { DBTable, NetRAWSource } from "../../shared/vdb/VaultDB";
import { SchemaTable, STR_KEY_MAP } from "../../shared/vdb/types";

import { Grid, LinearProgress, Paper } from "@mui/material";
import { DataGrid, GridRowsProp, GridColDef, GridEventListener } from '@mui/x-data-grid';

import { AutoMUIEditLayout } from "../../client/vdb/mui/AutoMUIEditLayout";
import { MUISaveButton, RowText } from "../../client/vdb/mui/MUIEditParts";
import { DBRowEdit } from "../../shared/vdb/DBRowEdit";


import getdb from 'client/hopiqvdb';
const vdb = getdb('EditTest');

export type GenericEditProps<FIELDS extends STR_KEY_MAP, TABLE extends DBTable<FIELDS, any, any, any, any>> = {
  // table: TABLE, 
  row: DBRowEdit<FIELDS>
};

export default function GenericEdit<FIELDS extends STR_KEY_MAP, TABLE extends DBTable<FIELDS, any, any, any, any>>(props: React.PropsWithChildren<GenericEditProps<FIELDS, TABLE>>) {
  const edit = props.row;
  const tableSchema = edit.$schema;

  const isNew = (edit && edit.$id < 0);

  return (
    <>
      <Paper variant="outlined" style={{
        // position: 'sticky', zIndex: 1,
        top: '0px', paddingLeft: "8px", paddingRight: "8px", marginTop: "8px", marginBottom: "8px",
        backgroundColor: '#ececec'
        // backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText
        // 'rgba(200,200,200,.85)'
      }} data-row-gid={edit.$gid} data-row-key={edit.$key}>
        <Grid container spacing={2}>
          {isNew && <Grid item xs={8}><h2>New {tableSchema.name}<RowText row={edit} field="$name" prefix=": " /> </h2> </Grid>}
          {!isNew && <Grid item xs={8}><h2>Edit {tableSchema.name}<RowText row={edit} field="$name" prefix=": " /> (#{props.row.$id})</h2> </Grid>}

          <Grid item xs={4} style={{ textAlign: 'right', paddingRight: '22px', paddingTop: '30px' }}>
            {/* container direction="row" alignItems="center" justify="flex-end" */}
            {edit && <MUISaveButton edit={edit} />}
          </Grid>
        </Grid>
      </Paper >

      {!edit && <LinearProgress />}
      {edit && <AutoMUIEditLayout edit={edit} />}

    </>
  );
}