import { Box, Button, Divider, Drawer, Modal, Stack, Tooltip, Typography, styled } from "@mui/material";
import * as colors from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { TaskDataRef } from "./PlanGraph";
import { Close, Delete, Edit, RestoreFromTrash } from "@mui/icons-material";
import { RowText } from "client/vdb/mui/MUIEditParts";
import PlanGraphBottomContents from "./PlanGraph.BottomContents";
import { brewStatusToExplain } from "shared/DisplayUtils";
import { asArray, toDate, toDayStr } from "shared/vdb/utils";
import { useNavigate } from "react-router-dom";
import { BrewSimpleEditComponent } from "client/components/BrewSimpleEdit";
import { processBrewLoadRecipe } from "shared/HopIQUtils";


const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : colors.grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? colors.grey[300] : colors.grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const drawerBleeding = 52;

export default function PlanGraphBottomDrawer(props: {
  selectedTask: TaskDataRef | null,
  onSelectedTaskDeleteToggle: () => void,
  dataUpdated?: any,
}) {
  // const selectedTask = props.selectedTask;
  // XXX: Use the brew, not the selected task and you won't have a bad selection issue w/ things like start date
  let brew = props.selectedTask?.ref;

  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = useCallback(() => { setDrawerOpen(true) }, []);
  const handleDrawerClose = useCallback(() => { setDrawerOpen(false) }, []);
  const handleDrawerToggle = useCallback(() => { setDrawerOpen((state) => !state) }, []);

  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = useCallback(() => { setModelOpen(true) }, []);
  const handleModelClose = useCallback(() => { setModelOpen(false) }, []);
  const handleModelToggle = useCallback(() => { setModelOpen((state) => !state) }, []);

  // useEffect(() => {
  //   console.log("XXXX bottom drawer, change detected")
  // }, [props.dataUpdated]);

  return (<>

    <Modal
      open={modelOpen}
      onClose={handleModelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflow: 'scroll',
      }}
    >
      <Box sx={{
        position: 'absolute' as 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        width: { sx: '100%', md: '80%' },
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
      }}>
        {(brew !== undefined) && <>
          <Button onClick={handleModelClose} sx={{ float: 'right' }} variant='outlined' startIcon={<Close />}>Close</Button>
          <Button variant="outlined" style={{ marginRight: 8, float: 'right' }}
            onClick={() => {
              if (brew && window.confirm("Replace durations and ingredients from selected recipe?")) {
                processBrewLoadRecipe(brew).then(
                  (good) => { console.log("processBrewLoadRecipe good", good) },
                  (bad) => { console.log("processBrewLoadRecipe bad", bad) }
                );
              }
            }}
          >Load from Recipe</Button>
          <Typography variant="h4" >Quick Edit Brew Session <RowText row={brew} field="$name" prefix=": " /> {` (#${brew.$id})`}</Typography>
          <Divider sx={{ margin: 1 }} />
          <BrewSimpleEditComponent row={brew} />
        </>}
      </Box>
    </Modal>

    <Drawer
      anchor="bottom"
      open={drawerOpen}
      onClose={handleDrawerClose}
      // onOpen={handleDrawerOpen}
      // swipeAreaWidth={drawerBleeding}
      // disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          width: { sx: '100%', md: '80%', lg: '60%' },
          minWidth: '400px',
          maxWidth: '1000px',
          // width: '60vw', 
          // left: '20vw' ,

          // The absolute floating first box below rounds the corners for us
          // paddingTop: '10px',
          // borderTopLeftRadius: '10px',
          // borderTopRightRadius: '10px',
          // height: `calc(50% - ${drawerBleeding}px)`,


          overflow: 'visible',
          // border-top-left-radius: '10px',
          // border-top-right-radius: '10px',
          // padding-top: '5px',

          // This works, but is reset when it's hidden
          // transform: 'translate(50%, 0)'
        }
      }}
      sx={{
        width: '100vw',
        // maxWidth: '600px',
      }}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: props.selectedTask ? -drawerBleeding : 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderTop: drawerOpen ? undefined : 'solid 3px lightgrey',
          borderLeft: drawerOpen ? undefined : 'solid 3px lightgrey',
          borderRight: drawerOpen ? undefined : 'solid 3px lightgrey',
          visibility: 'visible',
          right: 0,
          left: 0,
          height: drawerBleeding,
          transition: 'top 100ms',
          cursor: 'pointer',
          ':hover': { backgroundColor: '#f3f3ff' },
        }}
        onClick={handleDrawerToggle}
      >
        <Puller />
        <Stack direction='row' sx={{ p: 2, justifyContent: 'center', wordWrap: 'none', color: 'black' }}>
          {brew && <>
            Name:
            <Typography sx={{ color: 'text.secondary', overflow: 'hidden' }} noWrap component='span'>&nbsp;
              {/* {brew.$name} */}
              <RowText row={brew} prefix=" " field="_$name" />
            </Typography>

            &nbsp; Status:
            <Typography sx={{ color: 'text.secondary' }} noWrap component='span'>
              &nbsp;{brew.get('status')}
            </Typography>

            &nbsp; Amt:
            <Typography sx={{ color: 'text.secondary' }} noWrap component='span'>&nbsp;{brew.get('amount')} bbl</Typography>

            &nbsp; Start:
            <Typography sx={{ color: 'text.secondary' }} noWrap component='span'>
              &nbsp;{toDayStr(toDate(brew.get('start_brew')))}
            </Typography>

            &nbsp; Duration:
            <Typography sx={{ color: 'text.secondary' }} noWrap >&nbsp;{brew.get('brewduration') + "+" + asArray(brew.get('fermentduration')).join('+') + " Days"}</Typography>
          </>}
        </Stack>
      </StyledBox>
      <StyledBox
        sx={{
          px: 2,
          pb: 2,
          height: '100%',
          overflow: 'auto',
          // width: '50vw',
          borderLeft: drawerOpen ? undefined : 'solid 3px lightgrey',
          borderRight: drawerOpen ? undefined : 'solid 3px lightgrey',
        }}
      >
        <Stack direction='row' spacing={2} sx={{ justifyContent: 'center', wordWrap: 'none' }}>
          <Button variant="outlined" startIcon={<Edit />} onClick={handleModelOpen}>
            {/* ()=>{navigate("./"+brew?.$id)} */}
            Quick Edit
          </Button>

          <Button variant="outlined"
            onClick={props.onSelectedTaskDeleteToggle}
            startIcon={brew && brew.$status === "DELETED" ? <RestoreFromTrash /> : <Delete />}
          >
            {brew && brew.$status === "DELETED" ? "Undelete" : "Delete"}
          </Button>
        </Stack>
        {/* 
         * Strangeness here. On load, only show fixed items that won't change height. 
         * When it's shown (drawerOpen) you can add more, but not after the initial render but before opening.
         */}
        <pre>{props.selectedTask && drawerOpen && <PlanGraphBottomContents selectedTask={props.selectedTask} />}</pre>



      </StyledBox>
    </Drawer>
  </>
  );
}