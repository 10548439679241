import { Container, Grid, Tooltip } from "@mui/material";
import { Box, Button, Drawer, Stack, Typography, styled } from "@mui/material";
import { TaskDataRef } from "./PlanGraph";
import { DBRowEdit } from "shared/vdb/DBRowEdit";
import { Brew_Fields } from "shared/vdb/vdbsharedclass";
import { brewStatusToExplain } from "shared/DisplayUtils";
import { asArray } from "shared/vdb/utils";

const SX_LABEL: React.CSSProperties = { fontWeight: 'bold', paddingRight: '4px', textAlign: 'right' }; // textAlign: 'right',

export default function PlanGraphBottomContents(props: {
  selectedTask?: TaskDataRef | null
}) {
  const edit: DBRowEdit<Brew_Fields> | undefined = props.selectedTask?.ref;



  if (edit) {
    const startDateDisplay = edit.getDisplay('start_brew');

    return (
      <Container>
        <table width="100%">
          <thead>
            <tr><td></td><td></td></tr>
          </thead>
          <tbody>
            <tr>
              <th align="right">Name:</th><td>{edit.get('?$name')} [ID#{edit.$id}]</td>
              <th align="right">Status:</th><td>
                <Tooltip title={brewStatusToExplain(edit.get('status'))} placement="top" arrow>
                  <Typography component="span" style={{ textDecoration: 'blue dotted underline' }}>{edit.get('status')}</Typography>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <th align="right">Start:</th><td>{startDateDisplay}</td>
              <th align="right">Brew:</th><td>{edit.getDisplay('brewduration')}</td>
            </tr>
            {Array(edit.getLength('fermentduration', 'tank')).fill('').map((_, idx) => {
              return (
                <tr>
                  <th align="right">{(idx === 0) ? 'Ferment:' : ''}</th><td>{edit.getDisplay('fermentduration', idx)}</td>
                  <th align="right">{(idx === 0) ? 'Tank:' : ''}</th><td>{edit.getDisplay('tank', idx)}</td>
                </tr>
              );
            })}
            
            INGRESD {edit.getLength('ingredient_item', 'ingredient_type', 'ingredient_amount', 'ingredient_unit')}
            {Array(edit.getLength('ingredient_item', 'ingredient_type', 'ingredient_amount', 'ingredient_unit')).fill('').map((_, idx) => {
              return (
                <tr>
                  <th align="right">{edit.getDisplay('ingredient_type', idx)} &nbsp; </th><td>{edit.getDisplay('ingredient_item', idx)} </td>
                  <td>{edit.getDisplay('ingredient_amount', idx)} {edit.getDisplay('ingredient_unit', idx)}</td>
                </tr>
              );
            })}


          </tbody>
        </table>


      </Container>
    );
  }
  else
    return null;
}