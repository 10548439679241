
import { Button, LinearProgress } from '@mui/material';
import { autoLinkProcess } from "shared/vdb/AutoLinkProcess";

import getdb from 'client/hopiqvdb';
import { Brew_Fields, DBBrew } from 'shared/vdb/vdbsharedclass';
import RefID from 'shared/vdb/RefID';
import { DBRowEdit } from 'shared/vdb/DBRowEdit';
import { processBrewLoadRecipe } from 'shared/HopIQUtils';
import { useState } from 'react';
import { getRowDisplay } from 'shared/vdb/utils';
import { $IRowProcessNAME } from 'shared/vdb/utils';
import { asArray } from 'shared/vdb/utils';
const vdb = getdb("Utils");


export const processAllAutoLinks = async (setWorking: (setWorking: boolean) => void) => {
  setWorking(true);

  let vdblink = vdb;
  let tables = [vdb.Brew, vdb.FermentationTank, vdb.Ingredient, vdb.Recipe];

  console.log("vdblink - tables: ", tables.map((t) => t.name));

  for (let table of tables) {
    for (let [fieldname, fielddef] of Object.entries(table.schema.fields)) {
      if (fielddef.$datatype === 'ID') {
        console.log("vdblink - processing table/field: ", table, fieldname);
        // @ts-ignore
        let autoPromise = autoLinkProcess(vdblink, fieldname, table.schema.key);
        await autoPromise;
        console.log("vdblink - done table/field: ", table, fieldname);
      }
    }
  }
  console.log("vdblink", vdblink);
  await vdblink.save("Auto-link");
  console.log("vdblink - after save");

  setWorking(false);
}

export const processAllBrewsLoadRecipes = async (setWorking: (isWorking: boolean) => void) => {
  setWorking(true);

  let vdblink = vdb;
  let allBrews = await vdb.Brew.query({}).list();
  for (let brew of allBrews) {
    processBrewLoadRecipe(brew.edit());
  }

  console.log("vdblink-brews load recipes", vdblink);
  await vdblink.save("Auto-load recipes");
  console.log("vdblink-brews load recipes - after save");

  setWorking(false);
}


export const processPopulateAllLinkSumms = async (setWorking: (setWorking: boolean) => void) => {
  setWorking(true);

  let vdblink = vdb;
  let tables = [vdb.Brew, vdb.FermentationTank, vdb.Ingredient, vdb.Recipe];

  console.log("vdblink-allLinkSumms - tables: ", tables.map((t) => t.name));

  for (let table of tables) {
    const allRows = (await table.query({}).list()).map((row) => row.edit());
    for (let [fieldname, fielddef] of Object.entries(table.schema.fields)) {
      if (fielddef.$datatype === 'ID') {
        console.log("vdblink-allLinkSumms - processing table/field: ", table, fieldname);

        for (let rowEdit of allRows) {
          let rowValues = asArray(rowEdit.get(fieldname));
          for (let i = 0; i < rowValues.length; i++) {
            let rowValue = rowValues[i];
            let fieldValue = RefID.parseAny(rowValue);
            if (fieldValue != null) {
              let targetRow = await vdb.get(fieldValue.getValue());
              if (targetRow != null) {
                let summ = $IRowProcessNAME(targetRow.$raw);
                if (summ) {
                  rowEdit.set("_" + fieldname, summ, undefined, i);
                  console.log("vdblink-allLinkSumms - SET -", table.name, fieldname, rowEdit.$id, summ);
                }
              }
            }
          }
        }


        console.log("vdblink-allLinkSumms - done table/field: ", table, fieldname);
      }
    }
  }
  console.log("vdblink-allLinkSumms", vdblink);
  let voidOrIDMap = await vdblink.save("vdblink-allLinkSumms", true);
  console.log("vdblink-allLinkSumms - after save", voidOrIDMap);

  setWorking(false);
}


export const processAllCalcs = async (setWorking: (setWorking: boolean) => void) => {
  setWorking(true);

  let vdblink = vdb;
  let tables = [vdb.Brew];//, vdb.FermentationTank, vdb.Ingredient, vdb.Recipe];

  console.log("processAllCalcs - tables: ", tables.map((t) => t.name));

  for (let table of tables) {
    const allRows = (await table.query({}).list()).map((row) => row.edit());
    for (let rowEdit of allRows) {
      console.log("processAllCalcs running async for ", rowEdit.$gid)
      rowEdit.runAsyncProcesses();
      await rowEdit.waitAsyncDone()
      console.log("processAllCalcs running async for ", rowEdit.$gid, "...done!")
    }
  }
  // console.log("vdblink-allLinkSumms", vdblink);
  let voidOrIDMap = await vdblink.save("vdblink-reprocessAll", true);
  // console.log("vdblink-allLinkSumms - after save", voidOrIDMap);

  setWorking(false);
}

export default function Utils() {

  const [working, setWorking] = useState(false);

  return (<>
    {working && <LinearProgress />}
    <p><Button variant='outlined' disabled={working} onClick={() => processAllAutoLinks(setWorking)} >AutoLink ALL type ID fields across database</Button></p>
    <p><Button variant='outlined' disabled={working} onClick={() => processAllBrewsLoadRecipes(setWorking)} >Load all Brews from Recipes</Button></p>
    <p><Button variant='outlined' disabled={working} onClick={() => processPopulateAllLinkSumms(setWorking)} >Load all Link summs</Button></p>

    <p><Button variant='outlined' disabled={working} onClick={() => processAllCalcs(setWorking)} >Reprocess all Brew calcs</Button></p>

  </>);
}

