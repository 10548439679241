import { Options } from './index';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Task } from './Task';

import { SvgIconComponent } from '@mui/icons-material';

const HANDLE_WIDTH = 8;

type TaskFuncProps = {
  // Add callbacks for mouse events, existent of the callback creates and uses feature: resize, progress, etc
  name: string,
  id: string,
  options: Options,
  x: number,
  y: number,
  width: number,
  rowheight?: number,
  progress: number | null, // If null, will display as 100%, no handle to resize 
  className?: string,
  onClick?: React.MouseEventHandler<SVGGElement>,
  selected?: Task<any> | null,
  active: boolean,
  taskClass?: string,
  color?: string,
  statusIcon?: SvgIconComponent,
  canResize: boolean,
};

function getTextColorFromBackgroundHex(hex?: string) {
  if (!hex || hex.startsWith('#') == false || hex.length != 7)
    return undefined;
  var red = parseInt(hex.substring(1, 3), 16); // hexToR
  var green = parseInt(hex.substring(3, 5), 16); // hexToG
  var blue = parseInt(hex.substring(5, 7), 16); // hexToB

  return (red * 0.299 + green * 0.587 + blue * 0.114) > 186 ? "#000000" : "#FFFFFF";
}

export function TaskFunc(props: TaskFuncProps) {

  const [textIsBig, setTextIsBig] = useState<boolean>(true);
  const barLabelRef = useRef<SVGTextElement>(null);
  let timer: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    if (barLabelRef.current) {
      if (timer !== null) {
        clearTimeout(timer);
        timer = null;
      }

      timer = setTimeout(() => {
        let label: SVGGraphicsElement | null | undefined = document.getElementById(props.id)?.querySelector('.bar-label');
        if (label) {
          setTextIsBig(label.getBBox().width > props.width);
        }
      }, 100);
    }

  }, [barLabelRef.current, props.name, props.width]);

  let height = (props.rowheight || 1) * (props.options.bar_height);
  // Add extra padding that would be between two bars.
  height += (props.options.padding * ((props.rowheight || 1) - 1)) / 2;

  const calcProgressTrianglePoints2 = (): string => {
    const endProgressX = props.x + (props.progress || 0);
    const progressY = props.y;
    // const height = props.options.bar_height;

    return [
      endProgressX - 5,
      progressY + height,
      endProgressX + 5,
      progressY + height,
      endProgressX,
      progressY + height - 8.66
    ].toString();
  }

  let barWrapperClassNames = "bar-wrapper";
  if (props.active) barWrapperClassNames += " active";
  if (props.taskClass) barWrapperClassNames += " " + props.taskClass;

  const iconStyle = useMemo(() => {
    let iconColor = getTextColorFromBackgroundHex(props.color);
    return (iconColor) ? { color: iconColor } : { 'mix-blend-mode': 'difference' }
  }, [props.color])
  const textStyle = useMemo(() => {
    let textColor = getTextColorFromBackgroundHex(props.color);
    return (textColor) ? { fill: textColor } : { 'mix-blend-mode': 'difference' }
  }, [props.color])

  return (
    <g className={barWrapperClassNames} id={props.id} data-mt={'bar:' + props.id}>
      {/* bar_group */}
      <g className="bar-group">
        {/* $bar */}
        <rect x={props.x - 1} y={props.y - 1} width={props.width + 2} height={height + 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar-active-border"></rect>

        {/* $bar */}
        <rect x={props.x} y={props.y} width={props.width} height={height} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar grab"></rect>
        {/* $bar_progress */}
        <rect style={{ fill: props.color }} x={props.x} y={props.y} width={props.progress || props.width} height={height} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar-progress grab"></rect>
        <text ref={barLabelRef} style={textStyle} x={(textIsBig) ? (props.x + props.width + 5) : (props.x + props.width / 2)} y={props.y + height / 2} className={(textIsBig) ? "bar-label big" : "bar-label grab"}>{props.name}</text>
        {props.statusIcon && <props.statusIcon width={20} height={20} x={props.x} y={props.y} style={iconStyle} />}
      </g>
      {props.canResize &&
        <g className="handle-group">
          <rect className="handle grab right" x={props.x + props.width - 9} y={props.y + 1} width={HANDLE_WIDTH} height={height - 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius}></rect>
          {/* <rect className="handle left" x={props.x + 1} y={props.y + 1} width={HANDLE_WIDTH} height={props.options.bar_height - 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius}></rect> */}
          {props.progress && <polygon className="handle grab progress" points={calcProgressTrianglePoints2()}></polygon>}
        </g>
      }
    </g >
  );
}
export default TaskFunc;
