import { Container } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import EndBrew from "./BrewEndBrew";
import BrewHome from "./BrewHome";

export default function BrewIndex() {

  return (
    <Routes>
      <Route path="end/:id" element={<EndBrew />} />
      <Route index={true} element={<BrewHome />} />
    </Routes>
  );
}
