import { useEffect, useMemo, useState } from "react";

import { DBRowEdit } from "../../../shared/vdb/DBRowEdit";
import { VDBEditBranch } from "../../../shared/vdb/VDBEditBranch";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { isStrEmpty, STATUS_TO_CSS_CLASS } from "../../../shared/vdb/utils";
import { GRaw, STR_KEY_MAP } from "../../../shared/vdb/types";
import { BranchVaultDB } from "shared/vdb/VaultDB";

let count = 0;

type STATE_FLAGS = {
  disabled: boolean,
  locked: boolean,
  hasChanges: boolean,
  color: "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined,
}
export function MUISaveButton<FIELDS extends STR_KEY_MAP,>(props: {
  edit: DBRowEdit<FIELDS> | BranchVaultDB<any, any>,
  color?: "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined
}) {

  const [stateFlags, setStateFlags] = useState<STATE_FLAGS>({ disabled: false, locked: false, hasChanges: false, color: props.color });

  useEffect(() => {
    // let edit: DBRowEdit<FIELDS> | VDBEditBranch = props.edit;
    /* 
     * TODO: This is a mess. Basically, on hot-reload 'instanceof' doesn't work b/c we have a new implementation of DBRowEdit, where the 
     * save variable is an instance of an old DBRowEdit version. So it treats a DBRowEdit as a BranchVaultDB & errors out when .subscribe doesn't 
     * exist. Would need type guard function to check. Here I used a constant 'type' variable to tell, which has it's own problems and waste.
     * function isDBRowEdit(arg: any): arg is DBRowEdit { return ("type" in props.edit && props.edit.type === "DBRowEdit") }
     */

    let edit: BranchVaultDB<any, any> = (props.edit instanceof DBRowEdit || ("type" in props.edit && props.edit.type === "DBRowEdit")) ? (props.edit as DBRowEdit<FIELDS>).branch : props.edit;

    const callback = async (event: any) => {
      console.log("MUISaveBtn event: ", event.type, event);

      const locked = edit.isLocked();
      const hasError = edit.hasError();
      const hasWarn = edit.hasWarning();
      const hasChange = edit.hasChange();

      const enabled = !locked && !hasError && hasChange;

      setStateFlags({
        disabled: !enabled,
        locked: locked,
        hasChanges: hasChange,
        color: (hasError) ? "error" : (hasWarn) ? 'warning' : props.color,
      });

    };

    if (edit.vdbEditBranch) {
      console.warn("MUISaveButton attempting to subscript to branch: ", edit.vdbEditBranch, "from edit:", edit);
      return edit.vdbEditBranch.subscribe("", callback);
    }
    else {
      console.error("Prev error path1: ", edit);
      console.error("Prev error path2: ", edit.vdbEditBranch);

    }
  }, [props.edit])

  const onSave = useMemo(() => () => {
    let edit: BranchVaultDB<any, any> = (props.edit instanceof DBRowEdit) ? props.edit.branch : props.edit;
    edit.save("Default Save Title from MUISaveButton");
  }, [props.edit])

  count++;

  return (<>
    {/* <Button onClick={()=>{setUpdate((b)=>!b);console.log("refresh - update",props.edit.hasWarning(),props.edit.hasError());}}>Refresh</Button> */}
    <LoadingButton
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="contained"

      onClick={onSave}
      // props.edit.save
      loading={stateFlags.locked}
      disabled={stateFlags.disabled || !stateFlags.hasChanges}
      color={stateFlags.color}
    >
      {(stateFlags.locked) ? "Processing" : (!stateFlags.hasChanges) ? "No Changes" : "Save"}
    </LoadingButton>
  </>
  )
}

export function RowText<FIELDS extends STR_KEY_MAP,>({ row, field, prefix = "" }: { row?: DBRowEdit<FIELDS>, field: keyof GRaw<FIELDS> & string, prefix?: string }) {
  const [classes, setClasses] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    if (row === undefined) {
      setClasses("");
      setText("");
      return;
    }

    const callback = async (ignore: any) => {
      if (row) {
        setClasses(STATUS_TO_CSS_CLASS[row.$status]);
        const display = row.getDisplay(field);
        if (isStrEmpty(display))
          setText("");
        else
          setText(prefix + row.getDisplay(field));
      }
    };

    // Inital load
    callback(null);

    // Setup callbacks for changes
    if (row instanceof DBRowEdit) {
      return row.eventbus.onAll(field, callback)
    } else {
      console.warn("Add change watching to IRow, means VaultDB & DBRow need event similar to DBRowEdit & VDBEditBranch ");
      // return edit.subscribe("", callback);
    }
  }, [row, field])

  return (<span data-vdb-gid={row?.$gid} data-vdb-field={field} className={classes}>{text}</span>)
}