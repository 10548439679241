import uuid from 'uuid-random';
import { ALL_TABLE_TYPES, TYPE2SCHEMA } from "./vdbsharedtype";
import { IRaw, SchemaTable, ChildTable, PrismaBase, GRaw, STATUS, DATA_TYPES, assertUnreachableFieldDataType, STR_KEY_MAP, GRawSystem, CalcFormatter, SchemaField, SCHEMA_META_TYPES, SCHEMA_META_TYPE_VALUES, META_TYPE_DEF, isAsyncFormatter, AsyncFormatter, isCalcFormatter } from "./types";
import { IDBRow, toCalcKey } from './VaultDB';
import Reference from './Reference';
import { DBRowEdit } from './DBRowEdit';

import * as pluralize from 'pluralize';

import DateFnsAdapter from "@date-io/date-fns";
export const dateFns = new DateFnsAdapter();

export const CSS_ST_NEW = "vdb-st-new";
export const CSS_ST_ACTIVE = "vdb-st-active";
export const CSS_ST_DRAFT = "vdb-st-draft";
export const CSS_ST_DELETED = "vdb-st-deleted";
export const CSS_ST_UNKNOWN = "vdb-st-unknown";

export const STATUS_TO_CSS_CLASS = {
  "NEW": CSS_ST_NEW,
  "ACTIVE": CSS_ST_ACTIVE,
  "DRAFT": CSS_ST_DRAFT,
  "DELETED": CSS_ST_DELETED,
  "UNKNOWN": CSS_ST_UNKNOWN,
}

// TODO: Pre-save should use STATUS.NEW? 
// What about using NEW in the client too?
export const PARTIAL_NEW_ACTIVE = {
  $st: STATUS.ACTIVE, $pv: new Date(0), $v: new Date(0)
}

// Start shared IRow implementations
export function $IRowProcessGID(table: string, id: number) { return '@' + table + "/$" + id }
export function $IRowProcessKID(table: string, key: string | undefined, id: number) {
  return '@' + table + "/" + ((key) ? key : '$' + id)
}
export function $IRowProcessSTATUS(status: number | any): "DELETED" | "NEW" | "DRAFT" | "ACTIVE" | "UNKNOWN" {
  const s = status;

  if (typeof s === 'string') {
    if (s === "NEW" || s === "DELETED" || s === "ACTIVE" || s === "DRAFT")
      return s;
  } else if (typeof s === 'number' && Number.isNaN(s) === false) {
    return (s === STATUS.NEW) ? "NEW" : (s === STATUS.DELETED) ? "DELETED" : (s === STATUS.ACTIVE) ? "ACTIVE" : (s === STATUS.DRAFT) ? "DRAFT" : "UNKNOWN";
  }

  return "UNKNOWN";
}
export function statusStringToNumber(status: string): number {
  const s = status;
  if (s === "NEW") return STATUS.NEW;
  else if (s === "DELETED") return STATUS.DELETED;
  else if (s === "ACTIVE") return STATUS.ACTIVE;
  else if (s === "DRAFT") return STATUS.DRAFT;
  else return STATUS.UNKNOWN;
}

export function $IRowProcessNAME(raw: GRaw<any>): string {
  return fnn(raw.$name, raw._$name, raw.name, raw._name, $IRowProcessKID(raw.$table, raw.$key, raw.$id));
}
// End

export function padNum(num: number, size: number) {
  let output = num.toString();
  output = output.padStart(size, "0");
  return output;
}

/** If input is null, return empty string */
export function toDayStr(input: Date | null): string {
  // if (input == null) throw new Error("toDayStr unable to convert null or undefined: " + input);
  if (input == null) return "";
  //toISOString().split("T")[0]
  return `${padNum(input.getFullYear(), 2)}-${padNum(input.getMonth() + 1, 2)}-${padNum(input.getDate(), 2)}`;
}

export function toDate(input: string | Date | any): Date | null {
  return toDateOr(input, null);
}
export function toDateOr<O extends unknown>(input: string | Date | any, orElse: O): Date | O {
  if (input === null || input === undefined)
    return orElse;

  let found: Date | undefined = undefined;

  if (typeof input === 'object') {
    if (input && (input instanceof Date || Object.prototype.toString.call(input) === "[object Date]" && !isNaN(input)))
      found = input as Date;
  } else if (typeof input === 'string') {
    try { found = dateFns.parseISO(input) } catch (ignore) { console.log("toDate: dateFns.parseISO threw", ignore, input) }
    if (found === undefined || found === null || isNaN(found.getTime()))
      try { found = dateFns.parse(input, 'MM/dd/yyyy') } catch (ignore) { console.log("toDate: dateFns.parse(, 'MM/dd/yyyy') threw", ignore, input) }
    if (found === undefined || found === null || isNaN(found.getTime()))
      try { found = dateFns.parse(input, 'yyyy-MM-dd') } catch (ignore) { console.log("toDate: dateFns.parse(, 'yyyy-MM-dd') threw", ignore, input) }
  }

  //.toISOString().split("T")[0]
  return (found != undefined) ? found : orElse;
}


export function toStr<O extends unknown>(input: any, orElse: O): string | O {
  if (input === null || input === undefined)
    return orElse;

  if (typeof input === 'string') {
    return input;
  } else if (typeof input === 'object') {
    return JSON.stringify(input);
  } else if (typeof input === 'number' && Number.isNaN(input) == false) {
    return "" + input;
  }

  return orElse;
}

export function asArray<T extends unknown>(input: T | T[]): Array<T> {
  return (Array.isArray(input)) ? input : (input !== null && input !== undefined) ? [input] : [];
}

export function firstNotUndefined(...args: any | undefined) {
  args = Array.isArray(args) ? args : [];
  for (const arg of args)
    if (arg !== undefined)
      return arg;

  return undefined;
}

export function fnn(...args: any | null | undefined) {
  args = Array.isArray(args) ? args : [];
  for (const arg of args)
    if (arg !== undefined && arg !== null)
      return arg;

  return undefined;
}

export function isEmpty(input: string | any | null | undefined): boolean {
  if (input === null || input === undefined)
    return true;
  else if (typeof input === 'string')
    return (input.trim() === '');
  else if (Array.isArray(input))
    return (input.length === 0);
  else if (typeof input === 'object')
    return isObjEmpty(input);
  else
    return false;
}


/** Return true if a trimmed string != '', undefined or null */
export function isStrEmpty(input: string | null | undefined): boolean {
  if (input === null || input === undefined || typeof input !== 'string')
    return false;
  else
    return (input.trim() === '');
}

export function isAnyEmptyStr(input: string | any | null | undefined): boolean {
  if (input === null || input === undefined)
    return false;
  else if (typeof input === 'string')
    return (input.trim() === '');
  else
    return false;
}


/** Return false if any key exists, whose value != undefined or null */
export function isObjEmpty(obj: { [key: string]: any }): boolean {
  for (const v in obj) {
    if (obj[v] !== undefined && obj[v] !== null)
      return false;
  }
  return true;
}

export const localeCompare = (a: string | number | null | undefined, b: string | number | null | undefined): number => {
  if ((a === null || a === undefined) || (b === null || b === undefined)) {
    if ((a === null || a === undefined) && (b === null || b === undefined))
      return 0;
    else if (b === null || b === undefined)
      return -1;
    else
      return 1;
  }

  let aStr = (typeof a === 'number') ? "" + a : a;
  let bStr = (typeof b === 'number') ? "" + b : b;
  return aStr.localeCompare(bStr);
}



/** Return possibleNumber if its a finite number, or str that parses into one. Else return orElse. */
export function toNum<T extends number | null>(possibleNumber: any, orElse: T): number | T {
  if (possibleNumber === null || possibleNumber === undefined)
    return orElse;
  else if (typeof possibleNumber === 'number' && Number.isFinite(possibleNumber))
    return possibleNumber;
  else if (typeof possibleNumber === 'string') {
    let parsed = Number.parseFloat(possibleNumber);
    if (Number.isFinite(parsed))
      return parsed;
  }

  return orElse;
}

export function formatNumToStr(inNumber: number, posBeforeDecimal: number, posAfterDecimal: number = 0): string {
  const roundingFactor = Math.pow(10, posAfterDecimal);
  let roundedNum = Math.round((inNumber + Number.EPSILON) * roundingFactor) / roundingFactor

  let asStr = "" + roundedNum.toFixed(posAfterDecimal);
  let preLength = (asStr.indexOf('.') != -1) ? asStr.indexOf('.') : asStr.length;
  while (preLength < posBeforeDecimal) {
    preLength++;
    asStr = "0" + asStr;
  }

  return asStr;
}

export function convertPrismaToRaw<R extends GRaw<any>>(prismaObj: PrismaBase): R {
  // console.log("Now parsing into JSON: ", prismaObj.s_json);
  let raw: R = JSON.parse(prismaObj.s_json);
  // These 2 can be generated by the database, so the db record is authoritative. 
  raw.$id = prismaObj.s_id;
  raw.$key = prismaObj.s_key;

  // Might as well copy the rest over, since the db SHOULD be authoritative (?)
  raw.$st = prismaObj.s_st
  raw.$pv = prismaObj.s_pv
  raw.$v = prismaObj.s_v

  // We are now using _$name & $name, the db doesn't know which it came from, so we can't just set it back
  // if (prismaObj.s_name)
  //   raw.$name = prismaObj.s_name

  if (prismaObj.s_path)
    raw.$path = prismaObj.s_path

  // Add fullpath for indexing? Later?
  raw.$fullpath = (raw['_$path']) ? (Array.isArray(raw['_$path'])) ? [...raw['_$path'], raw.$path] : [raw['_$path'], raw.$path] : [raw.$path];

  return raw;
}

export type SCHEMA_REF = { vdb: SchemaTable, prisma?: string, _prismaCompileReferenceCheck?: any };

export function fieldNameToDisplay(tableName: string = "") {
  // First letter CAP, replace all '-' & '_' with a ' ' space 
  let toConvert = tableName.substring(0, 1).toUpperCase() + tableName.substring(1).replace(/[_-]/g, " ");
  // Convert the first letter after a space to CAP
  toConvert = toConvert.replace(/([ ][a-z])/g, (x) => { return x.toUpperCase(); });
  return toConvert;
};

export function prismaNameToTable(prismaName: string = "") { return prismaName.substring(0, 1).toUpperCase() + prismaName.substring(1) };
export function tableNameToPrisma(tableName: string = "") { return tableName.substring(0, 1).toLowerCase() + tableName.substring(1) };

export function convertRawToPrisma<R>(raw: IRaw): { prisma: R, schemaRef: SCHEMA_REF } {
  // If key is falsy (null, undefined, empty str) then generate random one
  if (!raw.$key) {
    raw.$key = "$AUTO-" + raw.$table + "-" + uuid();
  }

  let prisma: PrismaBase = {
    s_id: raw.$id,
    s_st: raw.$st,
    s_pv: raw.$pv,
    s_v: raw.$v,
    s_key: raw.$key,
    s_name: (raw.$name !== undefined) ? raw.$name : (raw['_$name'] !== undefined) ? raw['_$name'] : null,
    s_path: (raw.$path !== undefined) ? raw.$path : null,
    s_json: JSON.stringify(raw)
  };
  let tableKey = raw.$table;
  if (tableKey in TYPE2SCHEMA) {
    let schemaRef = TYPE2SCHEMA[tableKey as ALL_TABLE_TYPES];
    if (schemaRef) {
      // Need to transfer over all prisma indexed types
      for (let [fieldname, field] of Object.entries(schemaRef.vdb.fields)) {
        // If this field should be indexed, attempt to convert & assign
        if (field.index) {
          // @ts-ignore
          prisma[fieldname] = verifyTypeOrUndefined(field.$datatype, raw[fieldname])
        }
      }

      return { prisma: prisma as R, schemaRef: schemaRef };
    }
  }

  throw new Error("Unable to resolve raw.$table to table type: " + tableKey);
}


export function verifyTypeOrUndefined(datatype: DATA_TYPES, value: any): any | undefined {
  if (value === null && value === undefined)
    return undefined;

  switch (datatype) {
    case DATA_TYPES.STR:
    case 'STR':
      return (typeof value === 'string') ? value : undefined;
    case DATA_TYPES.NUM:
    case 'NUM':
      return (typeof value === 'number') ? value : undefined;
    case DATA_TYPES.BOOL:
    case 'BOOL':
      return (typeof value === 'boolean') ? value : undefined;
    case DATA_TYPES.TEXT:
    case 'TEXT':
      return (typeof value === 'string') ? value : undefined;
    case DATA_TYPES.ID:
    case 'ID':
      return (typeof value === 'string') ? value : undefined;
    case DATA_TYPES.DATE:
    case 'DATE':
      return (typeof value === 'number') ? new Date(value) : (typeof value === 'string') ? new Date(value) : undefined;
    case DATA_TYPES.LINKIN:
    case 'LINKIN':
      return undefined;
    case DATA_TYPES.EMBED_ARRAY:
    case 'EMBED_ARRAY':
      return undefined;
    default:
      // If there is a ts error on the next line, you aren't covering all fieldDataTypes
      assertUnreachableFieldDataType(datatype, value);
  }

}


// export function convertRawEditsToPrisma<R>(edits: any, toEditPrisma: PrismaBase): any {
//   // Pull out entries to convert (if present)
//   const { $id, $st, $pv, $v, $key, $name, $path, ...rest } = edits;

//   s_id: number | null,
//   s_st: number
//   s_pv: Date
//   s_v: Date
//   s_key: string
//   s_name: string | null
//   s_path: string | null
//   s_json: string

//   let prisma: PrismaBase = {
//     s_id: $id,
//     s_st: $st,
//     s_pv: $pv,
//     s_v: $v,
//     s_key: $key,
//     s_name: ($name !== undefined) ? $name : null,
//     s_path: ($path !== undefined) ? $path : null,
//     s_json: JSON.stringify(raw)
//   };
//   let tableKey = raw.$table;
//   if (tableKey in TYPE2SCHEMA) {
//     let schemaRef = TYPE2SCHEMA[tableKey as ALL_TABLE_TYPES];
//     if (schemaRef)
//       return { prisma: prisma as R, schemaRef: schemaRef };
//   }

//   throw new Error("Unable to resolve raw.$table to table type: " + tableKey);
// }

export function getRowDisplay<FIELDS extends STR_KEY_MAP>(row: IDBRow<FIELDS>, fieldName: keyof FIELDS | keyof GRawSystem, arrayPos: number): string {
  const data = row.get(fieldName, arrayPos);
  const calc = row.get(toCalcKey(fieldName), arrayPos);
  const fieldSchema: SchemaField | undefined = row.$schema.fields[fieldName];

  if (data instanceof Date) {
    return dateFns.format(data, "keyboardDate");// data.toISOString();
  } else if (calc instanceof Date) {
    return dateFns.format(calc, "keyboardDate");// calc.toISOString();
  }

  // If this is a reference, output the CALC value
  if (typeof data === 'string' && Reference.parse(data) !== null) {
    if (typeof calc === 'string') {
      if (isStrEmpty(calc) === false)
        return calc;
    } else if (calc != null) {
      return "" + calc;
    }
  }

  if (fieldSchema) {
    // If this is a DATE type, try to format output via flexible parsing of 'toDate'
    if (fieldSchema.$datatype === 'DATE') {
      if (isAnyEmptyStr(data) === false) {
        const asDate = toDateOr(data, null);
        if (asDate)
          return dateFns.format(asDate, "fullDate");
      } else if (isAnyEmptyStr(calc) === false) {
        const asDate = toDateOr(calc, null);
        if (asDate)
          return dateFns.format(asDate, "fullDate");
      }
    }
  }

  // If we have a unit, attempt to get data or calc as a number, 
  let toOutput = (data != null) ? data : (calc != null) ? calc : "";
  if (Array.isArray(toOutput)) toOutput = JSON.stringify(toOutput);
  let unit = (fieldSchema !== undefined && (fieldSchema as any).unit) ? (fieldSchema as any).unit : null;

  if (unit !== null) {
    let asNumber = (typeof toOutput === 'number') ? toOutput : (typeof toOutput === 'string') ? Number.parseFloat(toOutput) : Number.NaN;

    if (Number.isFinite(asNumber)) {
      if (asNumber > 1 || asNumber < -1)
        unit = pluralize.plural(unit);
      else
        unit = pluralize.singular(unit);
    }
  }

  return (unit !== null) ? toOutput + " " + unit : toOutput;
}

export function rowValidation<FIELDS extends STR_KEY_MAP>(row: IDBRow<FIELDS>): { rowErrors: { [name in keyof FIELDS]?: string }, rowWarnings: { [name in keyof FIELDS]?: string } } {
  const schema: SchemaTable | ChildTable = row.$schema;
  let output: { rowErrors: { [name in keyof FIELDS]?: string }, rowWarnings: { [name in keyof FIELDS]?: string } } = { rowErrors: {}, rowWarnings: {} };

  if ('parent' in schema) {
    const ctSchema = schema as ChildTable;
    if (isStrEmpty(row.$path)) {
      // @ts-ignore
      output.rowErrors['$path'] = "Table $path is empty, must be 'child' of parent table: " + ctSchema['parent'];
    } else if (row.$path?.startsWith("@/" + ctSchema.parent) === false) {
      // @ts-ignore
      output.rowErrors['$path'] = "Table $path is 'child' of wrong table, should be child of: @/" + ctSchema['parent'] + " ";
    }
  }

  return output;
}

export function processFieldCalc(rowEdit: DBRowEdit<any>, fieldName: string, field: SchemaField | null, calc: CalcFormatter) {
  try {
    let depValues = calc.deps.map((dep) => {
      const parsed = Reference.parse(dep);
      if (parsed != null) {
        try {
          return parsed.resolveLocal(rowEdit);
        } catch (ignoreError) {
          return null;
        }
      } else {
        // If parsed returned null, it wasn't a reference, so return value
        return dep;
      }
    });

    if (calc.format)
      console.log("REdit.processFieldCalc", rowEdit.$gid, fieldName, calc.deps, depValues, calc.format(depValues), rowEdit);

    if (calc.format)
      return calc.format(depValues);
    else
      return firstNotUndefined(depValues);
  } catch (error) {
    console.log("processFieldCalc threw error on ", rowEdit.$gid, "field", fieldName, 'error: ', JSON.stringify(error));
    return undefined;
  }
}

// Take metadefinitions (arrays of @field or const values, return first not null)
function processMetaDefArray(rowEdit: DBRowEdit<any>, values: any, arrayPos?: number) {
  let vals: any[] = (Array.isArray(values)) ? values : [values];



  // ok, what matters w / arrayPos here doesn't matter about the value...
  // it's whether it was set in the schema as an array

  // If the schema says no array, then arrayPos is IGNORED..
  // Eitehr here or the calling method needs to figure that out



  for (const r of vals) {
    let out = null;
    // Parse & resolve reference, or simply pass the value
    const refOutput = Reference.parseAnyOrNull(r);
    if (refOutput != null) {
      const fieldName = refOutput.getField();
      const fieldSchemaArrayValue = (fieldName != undefined) ? rowEdit.$schema?.fields[fieldName]?.$array : undefined;
      const fieldIsArray = (fieldSchemaArrayValue != undefined && fieldSchemaArrayValue > 0);

      out = refOutput.resolveLocal(rowEdit, (fieldIsArray) ? arrayPos : undefined);
    } else if (typeof r === 'string' && r.startsWith("\@")) {
      out = r.substring(1);
    }
    else {
      // Else not a reference, so simply pass the value
      out = r;
    }

    if (out != null)
      return out;
  }
}
// Same as above, except call .resolveLocalMaxArrayPos
function processMetaDefArrayFindMax(rowEdit: DBRowEdit<any>, values: any): number {
  let vals: any[] = (Array.isArray(values)) ? values : [values];

  for (const r of vals) {
    let out = null;
    // Parse & resolve reference, or simply pass the value
    const refOutput = Reference.parseAnyOrNull(r);
    if (refOutput != null) {
      return refOutput.resolveLocalMaxArrayPos(rowEdit);
    } else if (typeof r === 'string' && r.startsWith("\@")) {
      out = r.substring(1);
    }
    else {
      // Else not a reference, so simply pass the value
      out = r;
    }

    if (out != null)
      return 1;
  }

  return 0;
}

export async function processMetaType(rowEdit: DBRowEdit<any>, metaName: string, metaDef: Record<string, SCHEMA_META_TYPE_VALUES> | AsyncFormatter<any>): Promise<Record<string, any>> {
  let metaOutput: Promise<Record<string, any>>[] = [];

  if (isCalcFormatter(metaDef)) {
    // Special DisplayFormatter case
    let output = (processFieldCalc(rowEdit, metaName, null, metaDef));
    console.log("processMetaDefArray DisplayFormatter:", [metaName, output]);
    // if (metaName == '$name') debugger;
    return output;
  }


  let metaDefinitions = (isAsyncFormatter(metaDef)) ? metaDef.deps : [metaDef];

  // We need to find the max array for any referenced value
  let maxArrayPos = 1;
  for (const [key, val] of Object.entries(metaDefinitions)) {
    maxArrayPos = Math.max(maxArrayPos, processMetaDefArrayFindMax(rowEdit, val));
  }

  for (let currentPos = 0; currentPos < maxArrayPos; currentPos++) {
    let metaRowOut: Record<string, any> = {};
    for (const [key, val] of Object.entries(metaDefinitions)) {
      // Handles field references, or hardcoded values
      metaRowOut[key] = processMetaDefArray(rowEdit, val, currentPos);
      console.log("processMetaDefArray [key,currentPos,in,out]:", [key, currentPos, val, metaRowOut[key]]);
    }

    if (isAsyncFormatter(metaDef))
      metaOutput.push(metaDef.process(metaName, currentPos, metaRowOut));
    else
      metaOutput.push(Promise.resolve(metaRowOut));
  } // for maxArrayPos loop

  // Extra process step needed if 'asyncformatter'
  return Promise.all(metaOutput);
}

export function processFullPath(rowEdit: IDBRow<any>) {
  let parent = rowEdit.$path;
  let ppath = rowEdit.get("_$path");

  if (Array.isArray(ppath))
    ppath = ppath.map((v) => (typeof v === 'string') ? v : "" + v);
  else if (typeof ppath !== 'string')
    ppath = "" + ppath;

  let path: string[] = [];
  if (ppath !== undefined && ppath !== null)
    path.push(ppath);
  if (parent !== undefined && parent !== null)
    path.push(parent);
  return path;
}

export function getArrayPos(input: unknown, arrayPos: number = -1): any | any[] | null {
  // if (arrayPos === Number.MIN_SAFE_INTEGER) {
  //   return (Array.isArray(input)) ? input : [input];
  // }
  // else 
  if (arrayPos < 0)
    return input;
  else if (Array.isArray(input)) {
    if (arrayPos < input.length)
      return input[arrayPos];
    else
      return null;
  } else {
    if (arrayPos === 0)
      return input;
    else
      return null;
  }
}

export function ensureArray(input: any): any[] {
  return (input === undefined || input === null) ? [] : (Array.isArray(input)) ? input : [input];
}
/** Edits in place */
export function trimArray<T>(input: T[]): T[] {
  while (input.length > 0 && (input[0] === undefined || input[0] === null))
    input.shift();
  while (input.length > 0 && (input[input.length - 1] === undefined || input[input.length - 1] === null))
    input.pop();
  return input;
}

/** IMPORTANT: Returns NEW modify array */
export function setArrayPos(inArg: undefined | any | any[], value: any, arrayPos: number = -1): any | any[] {
  let input = inArg;

  if (input === undefined || input === null)
    input = [];

  if (arrayPos < 0) {
    return value;
  } else if (arrayPos === 0) {
    // check inArg here in case it's undefined, which would make input []
    if (Array.isArray(inArg)) {
      // This check is probably unneeded due to other checks... left in just in case since Array.from on ArrayLike can screw up the output
      let out = (Array.isArray(input)) ? Array.from(input) : [input];
      out[0] = value;
      return out;
    } else {
      return value;
    }
  } else {
    let out = (Array.isArray(input)) ? Array.from(input) : [input];
    if (Array.isArray(out) == false) {
      out = [out];
    }

    if (arrayPos < out.length) {
      out[arrayPos] = value;
    } else {
      while (arrayPos < out.length) {
        out.push(null);
      }
      out.push(value);
    }
    return out
  }
}

export function removeArrayPos(inArg: undefined | any | any[], arrayPos: number = -1): any[] {
  let inputAsArray = (inArg === undefined || inArg === null) ? [] : (Array.isArray(inArg)) ? Array.from(inArg) : [inArg];

  if (arrayPos < 0) {
    return inputAsArray;
  } else {
    if (arrayPos < inputAsArray.length)
      inputAsArray.splice(arrayPos, 1);
    return inputAsArray;
  }
}

export function getArrayLength(input: any | any[]): number {
  if (input === null || input === undefined)
    return 0;
  else if (Array.isArray(input))
    return input.length;

  return 1;
}