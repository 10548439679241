// Setup VaultDB config
import { HopIQBranchVaultDB, HopIQRootVaultDB } from "../shared/vdb/vdbsharedclass";
import { NetRAWSource } from "../shared/vdb/VaultDB";

let isRunningInNode = (typeof process !== 'undefined') && (process.release.name === 'node');

let netRawSource = new NetRAWSource(() => {
  if (window) window.location.assign('/login?r='+window.location.pathname);
});
netRawSource.serverPrefix = (isRunningInNode) ? "http://localhost:3001/" : "";

export const hopiqvdb = new HopIQRootVaultDB(netRawSource);
if (typeof window !== 'undefined') { (window as any)['hopiqroot'] = hopiqvdb; }

export function getdb(): HopIQRootVaultDB
export function getdb(branchName: string, isTemp?: boolean): HopIQBranchVaultDB
export function getdb(branchName?: string, isTemp?: boolean) {
  if (branchName)
    return hopiqvdb.branch(branchName, isTemp);
  else
    return hopiqvdb;
}
// console.log('hopiqvdb run');
export default getdb;
