import { Alert, AlertTitle } from "@mui/material";

export default function ErrorAlert(props: { errors: undefined | any | any[] }) {
  if (props.errors === null || props.errors === undefined || (Array.isArray(props.errors) && props.errors.length === 0))
    return null;

  const firstError = (Array.isArray(props.errors)) ? props.errors.find((v) => v != null) : props.errors;
  if (firstError == null)
    return null;

  let errorString = "";
  if (firstError instanceof Error)
    return (
      <Alert severity="error">
        <AlertTitle>Exception: {firstError.name}</AlertTitle>
        {firstError.message}
      </Alert>
    );
  else
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {JSON.stringify(firstError)}
      </Alert>
    );
}