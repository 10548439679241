import { DBRowEdit } from "./vdb/DBRowEdit";
import RefID from "./vdb/RefID";
import { Brew_Fields } from "./vdb/vdbsharedclass";

export const processBrewLoadRecipe = async (brew: DBRowEdit<Brew_Fields>) => {
  let vdb = brew.$src;

  let recipeID = RefID.parseAny(brew.get('recipe'));
  if (recipeID == null)
    throw new Error("Recipe is not ID: " + JSON.stringify(brew.get('recipe')));

  let recipe = await vdb.get(recipeID.toString());
  if (recipe == null)
    throw new Error("Unable to load Recipe from db using ID: " + recipeID.toString());

  brew.set('brewduration', recipe.get('brewduration'));
  brew.set('fermentduration', recipe.get('fermentduration'));

  brew.set('ingredient_item', recipe.get('ingredient_item'));
  brew.set('ingredient_type', recipe.get('ingredient_type'));
  brew.set('ingredient_amount', recipe.get('ingredient_amount'));
  brew.set('ingredient_unit', recipe.get('ingredient_unit'));
}
