import { SetStateAction, useMemo } from "react";
import { Dispatch } from "react";
import { useCallback, useState } from "react";

/** useState, but also stores & initializes from window.sessionStorage. defaultValue parameter only used if sessionStorage is empty */
export default function useSessState(key: string, defaultValue: string): [string | null, Dispatch<SetStateAction<string | null>>] {
  const [value, setValue] = useState(window.sessionStorage.getItem(key) !== null ? window.sessionStorage.getItem(key) : defaultValue);

  const wrappedSetValue: React.Dispatch<React.SetStateAction<string | null>> = useMemo(() =>
    (value: string | null | ((prevState: string | null) => string | null)) => {
      if (typeof value === 'function') {
        setValue((prevValue) => {
          let newValueFromFunc = value(prevValue);
          if (typeof newValueFromFunc === 'string')
            window.sessionStorage.setItem(key, newValueFromFunc);
          else
            window.sessionStorage.removeItem(key);
          return newValueFromFunc;
        });

      }
      else if (typeof value === 'string') {
        window.sessionStorage.setItem(key, value);
      }
      else {
        window.sessionStorage.removeItem(key);
      }
    }, []);

  return [value, wrappedSetValue]
}
