
export default function flexdebounce<ARGS extends any[], R>(func: (...args: ARGS) => R, wait = 166) {
  let timeout: NodeJS.Timeout;
  let savedArgs: ARGS | null = null;

  function flexDebounce(...args: ARGS) {
    savedArgs = args;

    const later = () => {
      func.apply(null, args);
      savedArgs = null;
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }
  flexDebounce.isQueued = () => {
    return (savedArgs !== null);
  };
  flexDebounce.now = () => {
    if (savedArgs) {
      clearTimeout(timeout);
      func.apply(null, savedArgs);
      savedArgs = null;
    }
  };
  flexDebounce.clear = () => {
    clearTimeout(timeout);
    savedArgs = null;
  };

  return flexDebounce;
}

