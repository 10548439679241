import { typeOptions } from '@testing-library/user-event/dist/type/typeImplementation';
import { DBRowEdit } from './DBRowEdit';
import { EventAll, EventCalc, EventChanged, EventData, EventLock, EventRebase, EventTypes, EventUnlock, EventValidation, EventWithBranch, ListenerTriplet } from '../../client/vdb/mui/EditEventBuffer';

export type BranchEventLock = EventWithBranch<EventLock>;
export type BranchEventUnlock = EventWithBranch<EventUnlock>;
export type BranchEventValidation = EventWithBranch<EventValidation>;
export type BranchEventChanged = EventWithBranch<EventChanged>;
export type BranchEventData = EventWithBranch<EventData>;
export type BranchEventCalc = EventWithBranch<EventCalc>;
export type BranchEventRebase = EventWithBranch<EventRebase>;
export type BranchEventAll = EventWithBranch<EventAll>;
export type BranchEventAllTypes = (BranchEventAll | BranchEventLock | BranchEventUnlock | BranchEventValidation | BranchEventChanged | BranchEventData | BranchEventCalc | BranchEventRebase);
export type BranchEventCallback<EventType extends BranchEventAllTypes> = (event: EventType) => Promise<false | void> | void;

/** Contians the eventbus & edits for a BranchVaultDB. Should probably be merged at some point... */
export class VDBEditBranch {
  /** Extra 'global' listeners, all events will be passed to their DBRowEdit impl */
  listeners: [type: EventTypes, callback: BranchEventCallback<any>][] = [];
  edits: { [fullID: string]: DBRowEdit<any>; } = {};
  name: string;
  unusedParentEventBus: VDBEditBranch | null = null;

  constructor(name: string) { this.name = name; }

  hasChange(includeCalc = true) { return Object.values(this.edits).some((edit) => edit.hasChange(includeCalc)); }
  clearAllEdits() { console.log('clearAllEdits()', this); Object.values(this.edits).forEach(edit => edit.clearAllEdits()) }
  /** Number of contained rows that are 'hasChanged()' aka: have edits made in them */
  countChanged(includeCalc = true) { return Object.values(this.edits).filter((edit) => edit.hasChange(includeCalc)).length; }
  isLocked() { return Object.values(this.edits).some((edit) => edit.isLocked()); }
  isValdating() { return Object.values(this.edits).some((edit) => edit.isValdating()); }
  hasError() { return Object.values(this.edits).some((edit) => { edit.hasError() }); }
  hasWarning() { return Object.values(this.edits).some((edit) => edit.hasWarning()); }

  subscribe(type: EventTypes, callback: BranchEventCallback<any>) {
    this.listeners.push([type, callback]);
    return () => { this.listeners = this.listeners.filter(i => i[1] !== callback); }
  }

  dispatch(type: EventTypes, target?: string, field?: string, value?: any, caller?: any): false | void {
    // If a dispatch to parent returns to us, ignore
    if (caller === this) return;

    // { type: '', branch?: string, target?: string, field?: string, value?: any | boolean, caller?: any };

    const event: EventWithBranch<any> = { type, branch: this.name, target, field, value, caller };

    // Process listeners, startsWith used so all type '' matches all
    for (const [cbType, cbCallback] of this.listeners)
      if (type.startsWith(cbType))
        cbCallback(event);

    // If this has a specific dbrow target, pass to child edit
    // if (target)
    //   for (const [id, edit] of Object.entries(this.edits))
    //     if (id.startsWith(target))
    //       edit.eventbus.dispatch(caller, false, type, target, field, value);

    // If we have a parent, push up the chain.
    if (this.unusedParentEventBus)
      this.unusedParentEventBus.dispatch(type, target, field, value, this);
  }

}
