import { BranchVaultDB, DBTable } from "../../shared/vdb/VaultDB";
import * as schema from "../../shared/vdb/config/vdb_schema";

import GenericEdit, { GenericEditProps } from "./GenericEdit";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DBRowEdit } from "../../shared/vdb/DBRowEdit";

import { STR_KEY_MAP } from "shared/vdb/types";
import { BrewSimpleEdit } from "./BrewSimpleEdit";

type ImplGenericEdit = <FIELDS extends STR_KEY_MAP, TABLE extends DBTable<FIELDS, any, any, any, any>>(props: React.PropsWithChildren<GenericEditProps<FIELDS, TABLE>>) => JSX.Element;

export const GENERIC_EDITS: { [tablekey: string]: ImplGenericEdit } = {
}
GENERIC_EDITS[schema.Brew.key] = BrewSimpleEdit;

export default function ConfigGenericEdit<FIELDS extends STR_KEY_MAP, TABLE extends DBTable<FIELDS, any, any, any, any>>(props: React.PropsWithChildren<{ vdb: BranchVaultDB<any, any>, table: TABLE }>) {
  const tableKey = props.table.schema.key;
  const params = useParams();

  const [preventDoubleNew, setPreventDoubleNew] = useState(false);

  const [edit, setEdit] = useState<DBRowEdit<FIELDS> | undefined>();
  const [error, setError] = useState("");

  // console.log("ConfigGenericEdit", props, params.id, edit, error);

  useEffect(() => {
    let id = (typeof params.id === 'string') ? Number.parseInt(params.id) : Number.NaN;
    if (Number.isInteger(id)) {
      setPreventDoubleNew(false);
      props.table.get(id)
        .then((dbRow) => {
          if (dbRow)
            setEdit(dbRow.edit())
          else
            setEdit(undefined);
        })
        .catch((e) => { console.error('ConfigEdits - error loading id', tableKey, params.id, e); setError('' + e); });
    } else if (typeof params.id === 'string' && params.id.toLowerCase() === 'new') {
      if (preventDoubleNew == false) {
        setPreventDoubleNew(true);
        let newEdit = props.table.new().edit();
        setEdit(newEdit);
      }
    } else {
      setError("Unable to find given id: " + params.id);
    }
  }, [params.id])

  if (error) {
    return <>ERROR: {error}</>;
  } else if (edit === undefined) {
    return <>Loading...</>;
  } else if (tableKey && GENERIC_EDITS[tableKey]) {
    const Impl = GENERIC_EDITS[tableKey];
    return <Impl<FIELDS, TABLE> {...props} row={edit} />
  }
  else
    return <GenericEdit<FIELDS, TABLE> {...props} row={edit} />;
}
