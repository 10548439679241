import React, { useEffect, useMemo, useRef, useState } from "react";
import date_utils from "./date_utils";
import { Options, TaskData, ViewModeType, GroupData } from "./index";
import { CTask, ITask, Task } from "./Task";
import './taskchart.scss';
import { CalcTool } from "./CalcTool";

function arrSet<T>(arr: (T | null)[], pos: number, value: T) {
  while (arr.length <= pos)
    arr.push(null);
  arr[pos] = value;
}
function arrSetRange<T>(arr: (T | null)[], pos: number, posEnd: number, value: T) {
  if (pos > posEnd) throw new Error("arrSetRange - SNH, start >= end: " + pos + " / " + posEnd);

  while (arr.length <= pos)
    arr.push(null);
  arr[pos] = value;
  for (let i = pos; i < posEnd; i++) {
    arr[i] = value;
  }
}
function arrGet<T>(arr: (T | null)[], pos: number): T | null {
  if (arr.length >= pos)
    return null;
  else
    return arr[pos];
}
function arrNextNull(arr: (any)[]): number {
  let pos = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === null)
      return i;
  }
  return arr.length;
}

interface IOptions extends Options {

}


interface IMouseHandler {
  handle(e: React.MouseEvent, snapX: number, snapY: number, group?: Group, col?: Date, rerender?: () => void): boolean;
}

class DragToMoveWindow implements IMouseHandler {
  startx; starty; winstartx; winstarty; end;

  constructor(spawnEvent: React.MouseEvent, end: (v: null) => void) {
    this.winstartx = window.scrollX;
    this.winstarty = window.scrollY;
    this.startx = spawnEvent.clientX;
    this.starty = spawnEvent.clientY;
    this.end = end;
  }

  handle(e: React.MouseEvent, snapX: number, snapY: number, group?: Group, col?: Date): boolean {
    if (e === undefined || e === null) return false;
    /*
     * If no mouse buttons are pressed, probably means the user went outside the window & released the mouse buttons. 
     * Treat as a 'cancel'.
     */
    if (e.buttons === 0) { console.log("NO BUTTONS PRESSED"); this.end(null); }

    if (e.type === 'mouseup') {// || e.type === 'mouseleave') {
      console.log('mouseup: ', e.type);
      this.end(null);
      return true;
    }
    else if (e.type === 'mousemove') {

      // Scroll window by diff
      // console.log('mousemove', { top: e.clientX - this.x, left: e.clientY - this.y, behavior: 'smooth' });
      // window.scrollBy({ top: e.clientY + this.y, left: e.clientX + this.x, behavior: 'smooth' });
      let dx = e.clientX - this.startx;
      let dy = e.clientY - this.starty;
      window.scrollTo(this.winstartx - dx, this.winstarty - dy);

      // console.log("scroll DIFF ",  (e.clientX - this.x), (e.clientY - this.y));
      // console.log("scrollTo ", this.winX + (e.clientX - this.x), this.winY + (e.clientY - this.y));
      // window.scrollTo(this.winX + (e.clientX - this.x), this.winY + (e.clientY - this.y));

      // this.startx = e.clientX;
      // this.starty = e.clientY;
      e.stopPropagation();
      return true;
    }
    return false;
  }
}

/**
 * Accepts a ifClick & ifDrag. If it determines this is a click event, it calls ifClick methods. 
 * If it determines it's a drag, sets 'target' so all future events are directly passed through to the ifDrag mouse handler.
 */
class ClickOrDragDetect implements IMouseHandler {

  static MOVE_PX = 5;

  end: (v: null) => void;
  target: IMouseHandler | null = null;
  ifClick;
  ifDrag;

  x_on_start;
  y_on_start;

  constructor(spawnEvent: React.MouseEvent, ifClick: () => void, ifDrag: IMouseHandler, end: (v: null) => void) {
    console.log("ClickOrDragDetect: new");
    this.end = end;
    this.ifClick = ifClick;
    this.ifDrag = ifDrag;

    this.x_on_start = spawnEvent.screenX;
    this.y_on_start = spawnEvent.screenY;
  }


  handle(e: React.MouseEvent, snapX: number, snapY: number, group?: Group, col?: Date, rerender?: () => void): boolean {
    if (e === undefined || e === null) return false;
    if (this.target != null) return this.target.handle(e, snapX, snapY, group, col, rerender);

    let dx = e.screenX - this.x_on_start;
    let dy = e.screenY - this.y_on_start;
    let deltaIsMove = (Math.abs(dx) >= ClickOrDragDetect.MOVE_PX || Math.abs(dy) >= ClickOrDragDetect.MOVE_PX);

    if (e.type === 'mouseup') {

      // If small move, it's a click
      if (deltaIsMove == false) {
        console.log("ClickOrDragDetect: mouseup - it's a click");
        this.ifClick();
      } else {
        console.log("ClickOrDragDetect: mouseup - not a click - END");
      }
      this.end(null);
      return true;
    }

    // Drag in progress
    if (e.type === 'mousemove') {
      if (deltaIsMove) {
        console.log("ClickOrDragDetect: mousemove - it's a valid move", [dx, dy]);
        this.target = this.ifDrag;
        return true;
      } else {
        console.log("ClickOrDragDetect: mousemove - not big enough move", [dx, dy]);
      }
    }

    return false;
  }
}

class DragTask<TD extends TaskData> implements IMouseHandler {
  end: () => void;
  moveTarget: 'right' | 'progress' | 'bar' | null;
  task: ITask<TD>;
  x_on_start;
  y_on_start;
  originalValue: [number, any] = [0, null];

  constructor(spawnEvent: React.MouseEvent, task: ITask<TD>, target: 'right' | 'progress' | 'bar', endFunc: (v: null) => void) {
    this.end = () => {
      this.moveTarget = null;
      document.removeEventListener("keydown", this.cancelMoveOnEscapeEventHandler, false);
      endFunc(null);
      // if (task.data.onDataChange !== undefined) {
      //   task.data.onDataChange(task,)
      // }
    };
    this.moveTarget = target;
    this.task = task;

    let targetElem = (spawnEvent.target as Element);
    let targetClassList = targetElem.classList;

    if (target === 'right') {
      this.originalValue = [task.width, null];
    } else if (target === 'progress') {
      this.originalValue = [task.progress, null];
    } else if (target === 'bar') {
      this.originalValue = [task.x, task.group];
    }

    this.x_on_start = spawnEvent.nativeEvent.offsetX;
    this.y_on_start = spawnEvent.nativeEvent.offsetY;
    // Cancel change in case ESC 
    document.addEventListener("keydown", this.cancelMoveOnEscapeEventHandler, false);
  }

  cancelMoveOnEscapeEventHandler = (e: KeyboardEvent) => {
    if (e.key === "Escape") { this.undoMoveAndEnd(); }
  }

  undoMoveAndEnd = () => {
    if (this.moveTarget === 'right') {
      this.task.width = this.originalValue[0];
    } else if (this.moveTarget === 'progress') {
      this.task.progress = this.originalValue[0];
    } else if (this.moveTarget === 'bar') {
      this.task.x = this.originalValue[0];
      this.task.group = this.originalValue[1];
    }

    this.end();
  }

  handle(e: React.MouseEvent, snapX: number, snapY: number, group?: Group, col?: Date, rerender?: () => void): boolean {
    if (e === undefined || e === null || this.moveTarget === null) return false;

    // End of drag
    if (e.type === 'mouseup') {
      let [computed_start_date, computed_end_date] = this.task.positionToDates();
      this.task.data.start = computed_start_date;
      this.task.data.end = computed_end_date;
      // console.log("mouseup", this.start, this.end, computed_start_date, computed_end_date);

      this.task.display = null;
      this.end();
      if (rerender) rerender();
      return true;
    }

    // Drag in progress
    if (e.type === 'mousemove') {
      let dx = e.nativeEvent.offsetX - this.x_on_start;
      let dy = e.nativeEvent.offsetY - this.y_on_start;
      console.log("e.nativeEvent.offsetX", dx);

      if (this.moveTarget === 'right') {
        let newX = this.originalValue[0] + dx;
        // if (newX < this._options.column_width) newX = this._options.column_width;
        this.task.width = this.task.get_snap_position(newX);
        if (rerender) rerender();
      }
      else if (this.moveTarget === 'progress') {
        let newX = this.originalValue[0] + dx;
        if (newX < 0) newX = 0;
        if (newX > this.task.width) newX = this.task.width;

        let asPrecent = newX / this.task.width;
        let rounded = Math.round(asPrecent * 100);
        this.task.progress = this.task.width * (rounded / 100);
        this.task.data.progress = rounded;
        console.log("progress", newX, this.task.width, this.task.progress, asPrecent, rounded, this.task.progress);
        this.task.display = "Progress: " + rounded + "%";
        // this.dataToPosition();
        if (rerender) rerender();
      }
      else if (this.moveTarget === 'bar') {
        if (this.task.data.canMoveStart) {
          let newX = this.originalValue[0] + dx;
          this.task.x = this.task.get_snap_position(newX);
          console.log("newX, _x", newX, this.task.x);
        }

        if (group != null && this.task.data.canMoveGroup !== false) {
          this.task.group = group;
          // this.task.dataToPosition();
        }
        console.log(this.task.positionToDates());
        if (rerender) rerender();
      }
      return true;
    }

    return false;
  }

}

export class Group {
  options: Options;
  data: GroupData;

  idx: number;

  prerows: number = 0; // Spacing in rows for all groups above this group
  rows: number = 1;
  starty: number = 0;
  height: number = 20 + 18;
  endy: number = 0;

  constructor(options: Options, idx: number, id: string, name?: string) {
    this.idx = idx;
    this.options = options;
    this.height = options.bar_height + options.padding;

    this.data = {
      id: id,
      name: name || id
    } as GroupData;
  }

  setRows(prevrow: Group | null, rows: number) {
    this.rows = rows;
    this.starty = (prevrow) ? prevrow.starty + prevrow.height : 0;//this.prerows * (this.options.bar_height + this.options.padding);
    this.height = (this.options.padding / 2) + this.rows * (this.options.bar_height + this.options.padding / 2);
    this.endy = this.starty + this.height;
  }

  getSubY(subrow: number, pos: 'top' | 'mid' | 'bot' = 'top') {
    return 0 +
      // Change position if not on top
      ((pos === 'mid') ? this.options.bar_height / 2 : (pos === 'bot') ? this.options.bar_height : 0) +
      // Calc start top of row
      this.starty + this.options.padding / 2 +
      // Next add spacing for subrows
      Math.min(this.rows - 1, subrow) * (this.options.bar_height + (this.options.padding / 2));
    // Rows is number of rows in group, subrow is 0 indexed sub position in row
  }

  renderLeft1(options: Options) {
    const starty = this.starty;
    const height = this.height;
    const endy = this.endy;

    return (
      <rect data-misc={'she:' + starty + ',' + height + ',' + endy} key={"rowbg" + this.idx} x={0} y={starty} width={200} height={height} className="grid-row-header" data-id={this.data.id} data-name={this.data.name} />
    );
  }


  renderLeft2(options: Options) {
    const starty = this.starty;
    const height = this.height;
    const endy = this.endy;

    return (<React.Fragment key={this.data.id}>
      <line key={"rowline" + this.data.id} x1={0} x2={200} y1={starty} y2={starty} className="row-line" />
      <line key={"rowendline" + this.data.id} x1={200} x2={200} y1={starty} y2={endy} className="row-line-sep" />
      <text key={"rowtext" + this.data.id} x={10} y={starty + options.bar_height} className="row-text" fontSize={16} dominantBaseline="middle" >{this.data.name}</text>
    </React.Fragment>);
  }

  renderGrid1(options: Options, row_width: number) {
    const starty = this.starty;
    const height = this.height;
    const endy = this.endy;

    {/* Row Backgrounds */ }
    return (
      <rect data-misc={'she:' + starty + ',' + height + ',' + endy} key={"rowbg" + this.data.id} x={0} y={starty} width={row_width} height={height} className="grid-row" />
    );

  }

  renderGrid2(options: Options, row_width: number) {
    const starty = this.starty;
    const height = this.height;
    const endy = this.endy;

    {/* Lines */ }
    return (
      <line key={"rowline" + this.data.id} x1={0} x2={row_width} y1={starty} y2={starty} className="row-line" />
    );
  }
}


function nowPlus(viewMode: ViewModeType, amount: number): Date {
  let cur_date = new Date();
  cur_date.setUTCHours(0, 0, 0, 0);

  if (viewMode == 'Year') {
    cur_date = date_utils.add(cur_date, amount, 'year');
  } else if (viewMode == 'Month') {
    cur_date = date_utils.add(cur_date, amount, 'month');
  } else {
    cur_date = date_utils.add(cur_date, amount, 'hour');
  }
  return cur_date;
}


type Props<TD extends TaskData> = {
  title?: string;
  onDataSelect?: (selected: TD | null) => void;
  onDataChange?: (changed: ITask<TD>, allTasks: ITask<TD>[]) => void;
  groups: GroupData[];
  tasks: TD[];
  options: Partial<Options>;
  headerComponent?: React.ReactElement;
};

export default function TaskChart<TD extends TaskData>(props: Props<TD>) {
  const default_options: Options = {
    header_height: 50,
    column_width: 30,
    bar_height: 20,
    bar_corner_radius: 3,
    arrow_curve: 5,
    padding: 18,
    view_mode: 'Day',
    step: 24,
    popup_trigger: 'click',
  };

  const taskchartContainer = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState(default_options);
  const [tasks, setTasks] = useState<ITask<TD>[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupByPos, setGroupByPos] = useState<Group[]>([]);
  const [totalRows, setTotalRows] = useState(0);

  const [start_date, setStartDate] = useState<Date>(nowPlus('Month', -1));
  const [end_date, setEndDate] = useState<Date>(nowPlus('Month', 1));
  const [grid_width, setgrid_width] = useState<number>(0);
  const [grid_height, setgrid_height] = useState<number>(0);

  const [dates, setdates] = useState<Date[]>([]);

  const [calcTool,] = useState(new CalcTool(default_options, nowPlus('Month', -1)));
  const svgRef = useRef<SVGSVGElement>(null);
  const [ignoreEvents, setIgnoreEvents] = useState(false);

  const [selected, setSelected] = useState<ITask<TD> | null>(null);
  // selectedRef is always current selected value, even across contexts / func component executions
  const selectedRef = useRef<ITask<TD> | null>(null);
  // useEffect(() => { selectedRef.current = selected; }, [selected])

  type MOUSE_DRAG_HANDLER = (e: React.MouseEvent, snapX: number, snapY: number, group?: Group, col?: Date) => void;
  const [mouseDragHandler, setMouseDragHandler] = useState<null | IMouseHandler>(null);


  const rerenderTasks = () => {
    console.log('ChartTasks.rerenderTasks()');

    // if (props.onDataChange && selected)
    //   props.onDataChange(selected.data);

    setTasks((tasks) => [...tasks.sort((a, b) => (a.x + a.width) - (b.x + b.width))]);
    processGroupRows();
  }

  /*
   * Process new/changed props: options, tasks, groups
   */
  useEffect(() => {
    console.log('ChartTasks.useEffect() - on input change - props.options, props.groups, props.tasks.length: ', props.tasks.length);

    const resolvedOptions: Options = Object.assign({}, default_options, props.options);
    setOptions(resolvedOptions);

    // Update calcTool options on options change
    calcTool.options = resolvedOptions;

    // Verify task/group ids and unique & all exist
    const prefixFindDuplicates = (v: string, idx: number, arr: string[]) => arr.indexOf(v, idx + 1) !== -1;

    const taskIDs = props.tasks.map(t => t.id);
    const groupIDs = props.groups.map(g => g.id);
    let allDependencyIDs: string[] = props.tasks.flatMap(t => t.dependencies).filter(t => t !== undefined && t !== null) as string[];

    let duplicateTaskIDs = taskIDs.filter(prefixFindDuplicates);
    let duplicateGroupIDs = groupIDs.filter(prefixFindDuplicates);
    let missingDepIDTargets = allDependencyIDs.filter(dID => taskIDs.indexOf(dID) === -1);

    if (duplicateTaskIDs.length !== 0)
      throw new Error("Duplicate Task.id's found: " + duplicateTaskIDs);
    if (duplicateGroupIDs.length !== 0)
      throw new Error("Duplicate Group.id's found: " + duplicateGroupIDs);
    if (missingDepIDTargets.length !== 0)
      throw new Error("Task.deps contains values not in Task.id: " + missingDepIDTargets);

    // Find the chart start/end, default to +-1 month from today
    let [new_start_date, new_end_date] = props.tasks
      .map((t) => [t.start instanceof Date ? t.start : new Date(t.start), t.end instanceof Date ? t.end : new Date(t.end)])
      .reduce((prev, curr) => {
        let first = (prev[0] < curr[0]) ? prev[0] : curr[0];
        let last = (prev[1] > curr[1]) ? prev[1] : curr[1];
        return [first, last];
      }, [nowPlus('Month', -1), nowPlus('Month', 1)])

    new_start_date = date_utils.add(new_start_date, -7, 'day');
    new_end_date = date_utils.add(new_end_date, 1, 'month');

    setStartDate(new_start_date);
    setEndDate(new_end_date);

    // let start = dates.reduce((prev, curr) => (prev.getTime() < curr.getTime()) ? prev : curr, new Date());
    // let end = dates.reduce((prev, curr) => (prev.getTime() > curr.getTime()) ? prev : curr, new Date());
    // setStartDate(start);
    // setEndDate(end);
    // console.log("CHART TASK - ", new_start_date.toDateString(), new_end_date.toDateString());
    // console.log("CHART TASK - ", props.tasks.map((t) => t.start.toDateString()));

    // Create groups from input, set tasks._group to group obj
    let groups: Group[] = props.groups.map((groupdata, idx) => new Group(options, idx, groupdata.id, groupdata.name));
    // for (let task of convertedTasks) {
    //   let group = groups.find(g => g.data.id === task.task.groupID);
    //   if (!group) throw new Error("For Task id:" + task.id + " unable to find group id: " + task.task.groupID);
    //   task._group = group;
    // }

    // Convert input data into Tasks
    let i = 0;
    let convertedTasks = props.tasks.map((data) => {
      let group = groups.find(g => g.data.id === data.groupID);
      if (!group) throw new Error("For Task id:" + data.id + " unable to find group id: " + data.groupID);

      const task = new Task(data, group, i++, resolvedOptions, calcTool, start_date, undefined);//, selectTaskCallBack)

      // On reloading of tasks, if we have a previous selected Task, keep that in the new 'converted' objects
      if (selected) {
        if (selected.gid == task.gid)
          task.active = true;
        if (selected.id == task.id)
          task.active = true;
      }

      return task;
    });

    for (let t of convertedTasks) {
      for (let depid of t.data.dependencies || []) {
        let depTask = convertedTasks.find(ft => ft.id === depid);
        if (!depTask) throw new Error("For Task id:" + t.id + " cannot find dep task id: " + depid);
        t.deps.push(depTask);
      }
    }

    // Organize groups, tasks, adj needed sizes of groups
    let [groupByPos, lGroups, lTasks] = processGroupRows(groups, convertedTasks);

    setTasks(convertedTasks);
    setGroups(groups);
    setGroupByPos(groupByPos);
  }, [props.options, props.tasks, props.groups]);


  const mouseEventHandler = (task: ITask<TD> | null, target: 'bar' | 'resize' | 'progress' | 'background', eventType: string, e: React.MouseEvent<Element, MouseEvent>) => {
    if (ignoreEvents) return;

    if (e.target instanceof Element) {
      let dataMTElem = e.target.closest('[data-mt]');
      let isHandle = e.target.classList.contains('grab');
      // console.log("Closest data-mt:", e.nativeEvent.offsetX, e.nativeEvent.offsetY, e.target.closest('[data-mt]'), e.target.classList);

      // console.log("Closest x snap:", e.nativeEvent.offsetX, computeSnapX(e.nativeEvent.offsetX),
      //   computeSnapX(e.nativeEvent.offsetX) / options.column_width,
      //   dates[computeSnapX(e.nativeEvent.offsetX) / options.column_width]
      // );
      // console.log("Closest y snap:", e.nativeEvent.offsetY, computeSnapY(e.nativeEvent.offsetY),
      //   computeSnapY(e.nativeEvent.offsetY) / (options.bar_height + options.padding),
      // );

      let currentDate = dates[(calcTool.computeSnapX(e.nativeEvent.offsetX, false) / options.column_width)];
      let d = (currentDate) ? currentDate.getDate() : '-';

      // console.log("Closest x snap:",
      //   computeSnapX(e.nativeEvent.offsetX, false) / options.column_width,
      //   d
      // );

      let task: ITask<TD> | null = null;
      if (dataMTElem?.getAttribute('data-mt')?.startsWith('bar:')) {
        let taskId = dataMTElem?.getAttribute('data-mt')?.substring(4);
        task = tasks.find((t) => t.id === taskId) || null;
      }

      let isTopArea = (dataMTElem?.closest('.taskchart-top') != null);

      let closestYSnap = calcTool.computeSnapY(e.nativeEvent.offsetY, false);
      let closestYrowPos = calcTool.computeSnapY(e.nativeEvent.offsetY, false) / (options.bar_height + options.padding);
      let closestXcolPos = calcTool.computeSnapX(e.nativeEvent.offsetX) / options.column_width;
      // let group = groups.find((g) => (closestYrowPos >= g.prerows) && closestYrowPos < g.prerows + g.rows);
      let group = groups.find((g) => e.nativeEvent.offsetY >= g.starty && e.nativeEvent.offsetY <= g.endy);

      // console.log("Closest y snap:", closestYrowPos, isTopArea, group?.name, group?.prerows, group?.rows);

      if (e.type === "mousedown") {
        console.log("<< ", "m.type", e.type, "selected: ", selected?.data.name, "task: ", task?.data.name, "group", group?.data.name);


        let validTaskEditTarget = true;
        let grabbed: 'right' | 'progress' | 'bar' | null = null;
        if (selected != null && task != null && (selected == task || selected.gid == task?.gid)) {
          grabbed = (e.target.classList.contains('right')) ? 'right' : (e.target.classList.contains('progress')) ? 'progress' : (e.target.classList.contains('bar') || e.target.classList.contains('bar-progress') || e.target.classList.contains('bar-label')) ? 'bar' : null;
          if (grabbed == 'bar' && (task.data.canMoveStart === false && task.data.canMoveGroup === false)) validTaskEditTarget = false
          // if (grabbed == 'progress' && task.task.ca === false) validEdit = false
          if (grabbed == 'right' && task.data.canResize === false) validTaskEditTarget = false
        }


        if (selected != null && task != null && (selected == task || selected.gid == task?.gid) && isHandle && validTaskEditTarget) {
          console.log(">> selected task:", selected.data.name, "selected == task: ", (selected == task), "selected.gid == task.gid: ", (selected.gid == task?.gid), "isHandle", isHandle);
          // XXX 
          const endDragTask = () => {
            // Clear drag handler so this dragtask no longer listens for events
            setMouseDragHandler(null);
            // Update changed task back to DBRow

            // This updates the TaskData (as well as returning the computer information)
            // const [start, end, diffInHours] = selected.positionToDates();
            if (props.onDataChange && task != null)
              props.onDataChange(task, tasks);
          }

          let handleClick = () => { };
          if (grabbed != null) {
            console.log('mouseEventHandler - grabbed valid. ClassList/grabbed ', e.target.classList, task);
            let handleDrag = new DragTask(e, task, grabbed, endDragTask);
            setMouseDragHandler(new ClickOrDragDetect(e, handleClick, handleDrag, endDragTask));

            // Prevent selection of text
            e.preventDefault();
          } else {
            console.log('mouseEventHandler - didnt grab valid. ClassList/grabbed ', e.target.classList);
          }
        } else {
          console.log(">> else ... dragToMove");
          let handler = new DragToMoveWindow(e, setMouseDragHandler);
          setMouseDragHandler(new ClickOrDragDetect(e, () => { selectTaskCallBack(task) }, handler, setMouseDragHandler));
          // setMouseDragHandler(handler);

          // Prevent selection of text
          e.preventDefault();
        }
      }
      // Not mouse down, so moves and mouseup
      else {
        if (mouseDragHandler != null) {
          // console.log("Going to call w/ e", e, mouseDragHandler);
          mouseDragHandler.handle(e, closestXcolPos, closestYrowPos, group, currentDate, rerenderTasks);

          // Call this is you want every single pixel move event called up the chain
          // if (props.onDataChange && selected)
          //   props.onDataChange(selected, tasks);

          // Prevent selection of text
          e.preventDefault();
        }
      }

    }

    // let continueProcessing = true;

    // if (selectedRef.current != null) {
    //   continueProcessing = !selectedRef.current.mouseEvent(e);

    //   if (!continueProcessing) {
    //     console.log("Task.mouseEvent - handled by task", e.type);
    //     rerenderTasks();
    //   }
    // }

    // if (continueProcessing && e.type === 'click') {
    //   selectTaskCallBack(null);
    // }
  };


  const selectTaskCallBack = (selectedTask: ITask<TD> | null) => {
    // console.log('ChartTasks.selectTaskCallBack() - ', selectedTask?.data.name, selectedTask?.active);

    // console.log("selectTaskCallBack", selectedTask?.task.name, selectedTask?._active, selected);

    setTasks((tasks) => {
      for (let task of tasks) {
        // If selected has a GID, match based on that
        if (selectedTask !== null && selectedTask.gid !== undefined) {
          task.active = (selectedTask.gid == task.gid || selectedTask.id == task.id);
        } else {
          task.active = (task == selectedTask);
        }
      }
      return [...tasks];
    });
    // console.log("selectTaskCallBack-2", selectedTask?.data.name, selectedTask?.active, selected);
    setSelected(selectedTask);
    selectedRef.current = selectedTask;

    setIgnoreEvents(true);
    if (props.onDataSelect) props.onDataSelect(selectedTask?.data || null);

    setTimeout(() => {
      setIgnoreEvents(false);
      // console.log("selectTaskCallBack-delay", selectedTask?.data.name, selectedTask?.active, selected);
    }, 100);

  };

  // useEffect(() => {
  //   for (let task of tasks) {
  //     task.setEventHandler(eventHandler);
  //   }
  // }, [tasks]);


  const processGroupRows = (lGroups?: Group[], lTasks?: ITask<TD>[]): [groupByPos: Group[], lGroups?: Group[], lTasks?: ITask<TD>[]] => {
    console.log('ChartTasks.processGroupRows() - ', lGroups, lTasks);

    if (lGroups === undefined) lGroups = groups;
    if (lTasks === undefined) lTasks = tasks;

    // Find the height of each group
    for (let group of lGroups) {
      let max = 0;
      let gTasks = lTasks.filter(t => t.data.groupID === group.data.id);//.sort((a, b) => b._x - a._x);
      let starts = [...gTasks].sort((a, b) => a.x - b.x);
      let ends = [...gTasks].sort((a, b) => (a.x + a.width - 1) - (b.x + b.width - 1));

      // let starts = [...gTasks].sort((a, b) => a.task.start.getTime() - b.task.start.getTime());
      // let ends = [...gTasks].sort((a, b) => a.task.end.getTime() - b.task.end.getTime());

      let positionArray: (ITask<TD> | null)[] = [];

      while (starts.length > 0 && ends.length > 0) {
        let nextStart = (starts.length > 0) ? starts[0].data.start.getTime() : null;
        let nextEnd = (ends.length > 0) ? ends[0].data.end.getTime() : null;

        // Next event in time, whichever end or start is first
        if (nextEnd && (nextStart == null || nextEnd <= nextStart)) {
          let t = ends.shift()!;

          // currPos[t!.groupPos] = null;

          // arrSet(positionArray, t.groupPos, null);
          // let rowHeight = (t.data.rowsHeight !== undefined) ? t.data.rowsHeight : 1;
          let extraRowHeight = (t.data.rowsHeight !== undefined && t.data.rowsHeight > 0) ? t.data.rowsHeight - 1 : 0;
          // console.log("processGroupRows - end ", t.data.name, "@", new Date(nextEnd).getDay(), "PRE", positionArray);

          // Need extraRowHeight+1 to account for the way arrSetRange handles ranges
          arrSetRange(positionArray, t.groupPos, t.groupPos + extraRowHeight+1, null);
          console.log("processGroupRows - end ", t.data.name, "@", new Date(nextEnd).getMonth()+1 + '/' + new Date(nextEnd).getDay(), t.groupPos, t.groupPos + extraRowHeight, positionArray);
        } else if (nextStart) {
          let t: ITask<TD> = starts.shift()!;

          let nextNullPos = arrNextNull(positionArray);
          // arrSet(positionArray, nextNullPos, t);
          // Extra over 1
          let extraRowHeight = (t.data.rowsHeight !== undefined && t.data.rowsHeight > 0) ? t.data.rowsHeight - 1 : 0;
          // console.log("processGroupRows - start ", t.data.name, "@", new Date(nextStart).getDay(), "PRE", positionArray);

          // Need extraRowHeight+1 to account for the way arrSetRange handles ranges
          arrSetRange(positionArray, nextNullPos, nextNullPos + extraRowHeight+1, t);
          console.log("processGroupRows - start ", t.data.name, "@", new Date(nextStart).getMonth()+1 + '/' + new Date(nextStart).getDay(), nextNullPos, nextNullPos + extraRowHeight, positionArray);

          t.groupPos = nextNullPos;
          // console.log("groupPos: ", t.groupPos);

          // rowHeight is at least 1, and nextNullPos already captured 1 height.
          max = Math.max(max, nextNullPos + extraRowHeight);
        }

      } // while all task dates, both start and end

      group.rows = max + 1;
    } // for all groups

    // Set spacing of groups based on their order
    let groupByPos = [];
    let totalRows = 0;
    let prevGroupRows = 0;
    let prevGroup = null;
    for (let g of lGroups) {
      g.prerows = prevGroupRows;
      prevGroupRows += g.rows;
      totalRows += g.rows;

      g.setRows(prevGroup, g.rows);

      // Load groupByPos lookup array
      for (let i = 0; i < g.rows; i++)
        groupByPos.push(g);

      prevGroup = g;
    }

    setTotalRows(totalRows);
    const grid_height = ((options.bar_height + options.padding) * totalRows) + options.header_height + options.padding;
    setgrid_height(grid_height);

    return [groupByPos, lGroups, lTasks];
  } // End processGroupRows

  useEffect(() => {
    console.log('ChartTasks.useEffect() - on opt/dates change - options, start_date, end_date');

    let dates: Date[] = [];
    let cur_date = null;

    while (
      cur_date === null ||
      cur_date < end_date!
      // ||
      // Below is new code from the TS version ... could this have been removed?
      // dates!.length * options!.column_width + 150 < $containerRef.current!.offsetWidth
      // XXX: Commented out since it constructs based on svg width
    ) {
      if (!cur_date) {
        cur_date = date_utils.clone(start_date!);
      } else {
        if (options.view_mode == 'Year') {
          cur_date = date_utils.add(cur_date, 1, 'year');
        } else if (options.view_mode == 'Month') {
          cur_date = date_utils.add(cur_date, 1, 'month');
        } else {
          cur_date = date_utils.add(cur_date, options.step, 'hour');
        }
      }
      dates!.push(cur_date);
    }
    setdates(dates);

    // Count out the dates for header rendering
    const grid_width = dates.length * options.column_width;
    setgrid_width(grid_width);

    // Update tasks to know when first date is for positioning
    tasks.forEach((task) => task.setChartStart(start_date));

    // Update calcTool start_date on start_date change
    calcTool.start_date = start_date;
  }, [options, start_date, end_date]);

  function isView(modes: ViewModeType | ViewModeType[]) {
    if (typeof modes === 'string') {
      return options.view_mode === modes;
    }
    else if (Array.isArray(modes)) {
      return modes.some(mode => options.view_mode === mode);
    }
    else
      return false;
  }


  const make_dates = () => {
    let dateLayer: JSX.Element[] = [];

    let i = 0;
    for (let date of get_dates_to_draw()) {
      dateLayer.push(<text key={i++} x={date.lower_x} y={date.lower_y} className='lower-text'>{date.lower_text}</text>);

      if (date.upper_text) {
        dateLayer.push(<text key={i++} x={date.upper_x} y={date.upper_y} className='upper-text'>{date.upper_text}</text>);
        // TODO: Check if the text is longer than the box, remove 
      }
    }
    return dateLayer;
  }

  const get_dates_to_draw = () => {
    let last_date: Date | null = null;
    const dates2 = dates!.map((date, i) => {
      const d = get_date_info(date, last_date!, i);
      last_date = date;
      return d;
    });
    return dates2;
  }

  const get_date_info = (date: Date, last_date: Date, i: number) => {
    if (!last_date) {
      last_date = date_utils.add(date, 1, 'year');
    }
    const date_text = {
      'Quarter Day_lower': date_utils.format(date, 'HH'),
      'Half Day_lower': date_utils.format(date, 'HH'),
      Day_lower: date.getDate() !== last_date.getDate() ? date_utils.format(date, 'D') : '',
      Week_lower: date.getMonth() !== last_date.getMonth() ? date_utils.format(date, 'D MMM') : date_utils.format(date, 'D'),
      Month_lower: date_utils.format(date, 'MMMM'),
      Year_lower: date_utils.format(date, 'YYYY'),
      'Quarter Day_upper': date.getDate() !== last_date.getDate() ? date_utils.format(date, 'D MMM') : '',
      'Half Day_upper': date.getDate() !== last_date.getDate() ? date.getMonth() !== last_date.getMonth() ? date_utils.format(date, 'D MMM') : date_utils.format(date, 'D') : '',
      Day_upper: date.getMonth() !== last_date.getMonth() ? date_utils.format(date, 'MMMM') : '',
      Week_upper: date.getMonth() !== last_date.getMonth() ? date_utils.format(date, 'MMMM') : '',
      Month_upper: date.getFullYear() !== last_date.getFullYear() ? date_utils.format(date, 'YYYY') : '',
      Year_upper: date.getFullYear() !== last_date.getFullYear() ? date_utils.format(date, 'YYYY') : ''
    };

    const base_pos = {
      x: i * options.column_width,
      lower_y: options.header_height,
      upper_y: options.header_height - 25
    };

    const x_pos = {
      'Quarter Day_lower': options.column_width * 4 / 2,
      'Quarter Day_upper': 0,
      'Half Day_lower': options.column_width * 2 / 2,
      'Half Day_upper': 0,
      Day_lower: options.column_width / 2,
      Day_upper: options.column_width * 30 / 2,
      Week_lower: 0,
      Week_upper: options.column_width * 4 / 2,
      Month_lower: options.column_width / 2,
      Month_upper: options.column_width * 12 / 2,
      Year_lower: options.column_width / 2,
      Year_upper: options.column_width * 30 / 2
    };

    return {
      // @ts-ignore
      upper_text: date_text[options.view_mode + '_upper'],
      // @ts-ignore
      lower_text: date_text[options.view_mode + '_lower'],
      // @ts-ignore
      upper_x: base_pos.x + x_pos[options.view_mode + '_upper'],
      upper_y: base_pos.upper_y,
      // @ts-ignore
      lower_x: base_pos.x + x_pos[options.view_mode + '_lower'],
      lower_y: base_pos.lower_y
    };
  }















  // make_grid_ticks() {
  //   let tick_x = 0;
  //   let tick_y = this.options!.header_height + this.options!.padding / 2;
  //   let tick_height =
  //     (this.options!.bar_height + this.options!.padding) *
  //     this.tasks!.length;

  //   for (let date of this.dates!) {
  //     let tick_class = 'tick';
  //     // thick tick for monday
  //     if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
  //       tick_class += ' thick';
  //     }
  //     // thick tick for first week
  //     if (
  //       this.view_is(VIEW_MODE.WEEK) &&
  //       date.getDate() >= 1 &&
  //       date.getDate() < 8
  //     ) {
  //       tick_class += ' thick';
  //     }
  //     // thick ticks for quarters
  //     if (this.view_is(VIEW_MODE.MONTH) && (date.getMonth() + 1) % 3 === 0) {
  //       tick_class += ' thick';
  //     }

  //     createSVG('path', {
  //       d: `M ${tick_x} ${tick_y} v ${tick_height}`,
  //       class: tick_class,
  //       append_to: this.layers!.grid
  //     });

  //     if (this.view_is(VIEW_MODE.MONTH)) {
  //       tick_x +=
  //         date_utils.get_days_in_month(date) *
  //         this.options!.column_width /
  //         30;
  //     } else {
  //       tick_x += this.options!.column_width;
  //     }
  //   }
  // }

  // make_grid_highlights() {
  //   // highlight today's date
  //   if (this.view_is(VIEW_MODE.DAY)) {
  //     const x =
  //       date_utils.diff(date_utils.today(), this.gantt_start, 'hour') /
  //       this.options!.step *
  //       this.options!.column_width;
  //     const y = 0;

  //     const width = this.options!.column_width;
  //     const height =
  //       (this.options!.bar_height + this.options!.padding) *
  //       this.tasks!.length +
  //       this.options!.header_height +
  //       this.options!.padding / 2;

  //     createSVG('rect', {
  //       x,
  //       y,
  //       width,
  //       height,
  //       class: 'today-highlight',
  //       append_to: this.layers!.grid
  //     });
  //   }
  // }








  const set_scroll_position_today = () => {
    if (taskchartContainer && taskchartContainer.current) {
      let todayY = date_utils.diff(date_utils.today(), start_date, 'hour') / options.step * options.column_width - options.column_width / 3;
      taskchartContainer.current.scrollLeft = todayY;
    }
  }


  const rowPosCalc = {
    groupNum: 0,
    startY: 0,
    height: (options.bar_height + options.padding) * ((groups[0]) ? groups[0].rows : 1),

    setPos(num: number) {
      if (num == 0) {
        this.startY = 0;
        this.height = (options.bar_height + options.padding) * ((groups[0]) ? groups[0].rows : 1);
      } else {
        while (this.groupNum < num) {
          this.startY = this.startY + this.height;
          this.height = (options.bar_height + options.padding) * ((groups[this.groupNum]) ? groups[this.groupNum].rows : 1);
        }
      }
    }

  }

  const calcRowStartY = (rowIdx: number, groups: Group[]) => (groups[rowIdx].prerows * (options.bar_height + options.padding));
  const calcRowHeight = (rowIdx: number, groups: Group[]) => (groups[rowIdx].rows * (options.bar_height + options.padding));

  const row_width = dates.length * options.column_width;

  // This may cause the func to use old state values
  // const mouseEventBackgroundHandler = useMemo(() =>
  //   (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => mouseEventHandler(null, 'background', event.type, event)
  //   , []);

  return (<>

    {props.headerComponent &&
      <div className="taskchart-container" style={{ width: 200 + dates.length * options.column_width, overflow: 'initial' }}>
        <div style={{ width: '99vw', position: 'sticky', left: '0px' }} >
          {props.headerComponent}
        </div>
      </div>
    }
    <div className="taskchart-container" ref={taskchartContainer}
      style={{
        overflow: 'initial', height: grid_height + options.padding,
        width: dates.length * options.column_width,
        display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'
      }}
      onMouseLeave={(event) => mouseEventHandler(null, 'background', event.type, event)}
    >

      {/* Start left upper corner & left row area */}
      <div style={{ alignContent: 'normal', position: 'sticky', left: 0, zIndex: 1 }}>
        {/* Left Top Corner */}
        <div className="leftCorner" style={{ position: 'sticky', top: 0, width: 200, height: options.header_height + 10, padding: 10, border: '1px solid #e0e0e0', overflow: 'hidden', backgroundColor: 'white', boxSizing: 'border-box' }}>
          <h3>grid icons</h3>
        </div>
        {/* Left Group Area */}
        <svg className="taskchart taskchart-left" height={grid_height} width={200} style={{}} data-mt={'chart:left'} onMouseMove={(event) => mouseEventHandler(null, 'background', event.type, event)}>
          <rect x="0" y="0" key="grid-header" width={200} height={grid_height} className="grid-header"></rect>
          <g className="rows">
            {groups.map((g, idx, arr) => g.renderLeft1(options))}
            {groups.map((g, idx, arr) => g.renderLeft2(options))}
          </g>
          <g className="rowsold">
          </g>
        </svg>
        {/* End Left Group Area */}
      </div>

      <div>

        {/* Start Header/Date Area */}
        <svg className="taskchart taskchart-top" height={options.header_height + (options.padding + 2) / 2} width={grid_width} style={{ display: 'block', position: 'sticky', top: 0 }} data-mt={'chart:top'}
          onMouseMove={(event) => mouseEventHandler(null, 'background', event.type, event)}
        >
          <rect x="0" y="0" key="grid-header" width={grid_width} height={options.header_height + 10} className="grid-header"></rect>
          <g className="date">
            {make_dates()}
          </g>
          {/* End Header/Date Area */}
        </svg>

        {/* Start main area of chart */}
        <svg className="taskchart taskchart-grid" ref={svgRef} height={grid_height} //+ options.padding // +100 
          // width={'100%'}    
          width={dates.length * options.column_width}
          style={{ display: 'block', top: 0, left: 0 }}
          onClick={(event) => mouseEventHandler(null, 'background', event.type, event)}
          onMouseDown={(event) => mouseEventHandler(null, 'background', event.type, event)}
          onMouseMove={(event) => mouseEventHandler(null, 'background', event.type, event)}
          onMouseUp={(event) => mouseEventHandler(null, 'background', event.type, event)}
          data-mt={'chart:grid'}
        >


          <rect x="0" y="0" key="grid-background" width={grid_width} height={grid_height} className="grid-background"></rect>
          <g className="grid">
            {/* Rows */}
            {/* {tasks.map((t, idx) => {
            rows_y += options.bar_height + options.padding;
            return (<rect key={"rowbg" + idx} x={0} y={options.header_height + options.padding / 2 + idx * (options.bar_height + options.padding)} width={row_width} height={row_height} className="grid-row" />);
          })} */}

            {/* Row Backgrounds */}.
            {groups.map((g, idx, arr) => g.renderGrid1(options, grid_width))}
            {groups.map((g, idx, arr) => g.renderGrid2(options, grid_width))}

            {/* {groups.map((g, idx, arr) => {
              return (<rect key={"rowbgold" + idx} x={0} y={calcRowStartY(idx, arr)} width={row_width} height={calcRowHeight(idx, arr)} className="grid-row" />);
            })} */}

            {/* Lines */}
            {/* {groups.map((g, idx, arr) => {
              let lineTop = calcRowStartY(idx, arr);
              return (<line key={"rowline" + idx} x1={0} x2={row_width} y1={lineTop} y2={lineTop} className="row-line" />);
            })} */}
            {/* Today */}
            {(options.view_mode == 'Day') &&
              <rect className='today-highlight'
                x={date_utils.diff(date_utils.today(), start_date, 'hour') / options.step * options.column_width - options.column_width / 3} y={0}
                width={options.column_width} height={grid_height}
              />}
            {/* No idea why the /3 above. Re-address after seperating out the top & left headers */}
          </g>

          <g className="arrow">
            {tasks.map(task =>
              task.renderArrows(selected)
            )}
          </g>
          <g className="progress"></g>
          <g className="bar" style={{ transition: "all .4s ease" }}>
            {tasks.map(task =>
              task.renderBars(selected)
              // <TaskFunc key={"task-" + task.id}
              //   name={task.active ? "ACTIVE" : task.name} id={task.id} options={task._options}
              //   x={task._x}
              //   y={task._y} width={task._width} height={task._height} progress={task._progress}
              //   onClick={(e)=>{eventHandler(task, e.type, e)}}
              // />
            )}
          </g>


          <g className="arrow isActive">
            {tasks.filter((t) => t.active).map(task =>
              task.renderArrows(selected)
            )}
          </g>
          <g className="progress"></g>
          <g className="bar" style={{ transition: "all .4s ease" }}>
            {tasks.filter((t) => t.active).map(task =>
              task.renderBars(selected)
            )}
          </g>


          <g className="details"></g>
        </svg>
      </div>

      <div className="popup-wrapper">{selected?.data.name}</div>
    </div>
  </>
  );
}